import React, { useEffect, useState } from "react";
import NavTabs from "./components/NavTabs";
import { Button } from "reactstrap";
import PictureForm from "./components/PictureForm";
import { useLocation } from "react-router-dom";

const TabForm = () => {
  const [activeForm, setActiveForm] = useState("DATA_FORM");
  const location = useLocation();
  const [queryParams, setQueryParams] = useState({});
  const [hashParams, setHashParams] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = {};
    for (let [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }
    setQueryParams(paramsObject);
    if(paramsObject.hide === 'true' && paramsObject.siteAdminLogin === 'true' ){
      setActiveForm("DATA_FORM")

    }else if(paramsObject.hide === 'true' && paramsObject.siteManagerLogin === 'true'){
      setActiveForm("PICTURE_FORM")
    }

    const hash = location.hash;
    if (hash) {
      setHashParams(hash.substring(1));

    }
  }, [location]);

  const renderForm = () => {
    if (activeForm === "DATA_FORM") {
      return <NavTabs />;
    } else if (activeForm === "PICTURE_FORM") {
      return <PictureForm />
    }
  };

  return (
    <div className="">
      <div className="d-flex justify-content-between mb-4">
        <div className="">

          {queryParams.hide === 'true' ? queryParams.siteAdminLogin === 'true' ? <Button
            className={`px-5 me-5 add-form-button ${activeForm === 'DATA_FORM' ? 'active' : ''}`}
            onClick={() => setActiveForm("DATA_FORM")}
          >
            <i className="bi bi-plus-lg me-2"></i>
            DATA FORM
          </Button> : <></> : <Button
            className={`px-5 me-5 add-form-button ${activeForm === 'DATA_FORM' ? 'active' : ''}`}
            onClick={() => setActiveForm("DATA_FORM")}
          >
            <i className="bi bi-plus-lg me-2"></i>
            DATA FORM
          </Button>}
          {queryParams.hide === 'true' ? queryParams.siteManagerLogin === 'true' ? <Button
            className={`px-5 me-5 add-form-button ${activeForm === 'PICTURE_FORM' ? 'active' : ''}`}
            onClick={() => setActiveForm("PICTURE_FORM")}
          >
            <i className="bi bi-plus-lg me-2"></i>
            PICTURE FORM
          </Button> : <></> : <Button
            className={`px-5 me-5 add-form-button ${activeForm === 'PICTURE_FORM' ? 'active' : ''}`}
            onClick={() => setActiveForm("PICTURE_FORM")}
          >
            <i className="bi bi-plus-lg me-2"></i>
            PICTURE FORM
          </Button>}
        </div>
     
      </div>
      {renderForm()}
    </div>
  );
};

export default TabForm;
