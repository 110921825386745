import React, { useState } from 'react';
import CompanyDetail from './CompanyDetail.js';
import CompanyContact from './CompanyContact.js';

const AddCompany = () => {
  const [currentPage, setCurrentPage] = useState('detail');
  const [answer, setAnswer] = useState({
    araId: "",
    name: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zipcode: "",
    officeType: 'Head Office',
    userData: [{
      fname: "",
      lname: "",
      phone: "",
      designation: "",
      email: "",
      role: ""
    }]
  });

  const switchToContact = () => setCurrentPage('contact');
  const switchToDetail = () => setCurrentPage('detail');

  return (
    <div className="AddSite">
      {currentPage === 'detail' ? (
        <CompanyDetail
          answer={answer}
          setAnswer={setAnswer}
          onSwitchToContact={switchToContact}
        />
      ) : (
        <CompanyContact
          answer={answer}
          setAnswer={setAnswer}
          onSwitchToDetail={switchToDetail}
        />
      )}
    </div>
  );
};

export default AddCompany;
