import React from "react";
import "./SiteContact.css";
import deleteIcon from "../img/deleteIcon.png";
import exportIcon from "../img/exportIcon.png";
import config from "../../../../../config/config.json";
import { apiCall } from '../../../../../services/ApiCall.js';
import { Navigate, useNavigate } from "react-router-dom";

function SiteContact({answer, setAnswer, onSwitchToDetail }) {
  const navigate = useNavigate();
  const changeFname = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      userData: {
        ...prevAnswer.userData,
        fname: e.target.value,
      },
    }));
  };

  const changeLname = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      userData: {
        ...prevAnswer.userData,
        lname: e.target.value,
      },
    }));
  };
  const changeEmail = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      userData: {
        ...prevAnswer.userData,
        email: e.target.value,
      },
    }));
  };
  const changePhone = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      userData: {
        ...prevAnswer.userData,
        phone: e.target.value,
      },
    }));
  };
  const changeDesignation = (event) => {
    const newDesignation = event.target.value;
    setAnswer(prevAnswer => ({
      ...prevAnswer,
      designation: newDesignation
    }));
  };
 
  
  const addNewCompanyAndContact = async (e) => {
    e.preventDefault();
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}addNewCompanyAndContact`,
      {},
      {...answer},
      "POST"
    );
    if (isSuccess) {
      // setCaseList(data?.data?.reverse());
      navigate("/company-list")

    }
  };

  return (
    <div className="add-site">
      <div className="header">
        <h2>Add Company</h2>
        <button className="export-btn">
          <img className="me-1 " src={exportIcon} alt=" " />
          Export
        </button>
      </div>
      <div className="tabs">
        <button className="tab" onClick={onSwitchToDetail}>
          Company Detail
        </button>
        <button className="tab active">Main Contact</button>
      </div>
      <div className="site-detail-h">
        <div className="site-detail-left">Company contact</div>
        <div className="site-detail-right">
          <button className="me-4 ps-2 pe-2">+ Add </button>
          <img src={deleteIcon} alt="" />{" "}
        </div>
      </div>
      <div className="site-contact">
        <form onSubmit={(e)=>addNewCompanyAndContact(e)}>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="ara-id">First Name</span>
              <input onChange={changeFname} type="text" id="ara-id" />
            </div>
            <div className="form-group">
              <span htmlFor="company">Last Name</span>
              <input onChange={changeLname} type="text" id="company" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <span htmlFor="address">Email</span>
              <input onChange={changeEmail} type="text" id="address" />
            </div>
            <div className="form-group ">
              <span htmlFor="address-2">Phone</span>
              <input
                onChange={changePhone}
                type="text"
                id="address-2"
                className="col-lg-2"
              />
            </div>
            <div className="form-group">
              <div className="form-group-col  dropdown-container">
                <span htmlFor="ara-id">Designation</span>
                <select
                  onChange={changeDesignation}
                  className="form-control col-lg-6"
                  value={answer.designation}
                >
                  <option value="">Select Designation</option>
                  <option value="2">Owner</option>
                  <option value="2">District Manager</option>
                  <option value="2">Compliance Manager</option>
                  <option value="2">General Manager </option>
                </select>
              </div>
            </div>
          </div>
          {/* <div className="form-row">
            <div className="form-group row">             
             <div className='form-group-col col-lg-6 dropdown-container'>
             <span htmlFor="ara-id">Designation</span>
             <select  className="form-control col-lg-6"  >
  <option value="">Select Designation</option>
  
</select>
             </div>
             
            </div>            
          </div> */}

          <div className="form-group-foot">
            <span className="text-start">
              <button
                type="submit"
                className="previous-btn"
                onClick={onSwitchToDetail}
              >
                Previous
              </button>
            </span>
            <span className="text-end">
              <button type="submit" className="next-btn ps-4 pe-4">
                + SAVE
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SiteContact;
