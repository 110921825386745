import React, { useState } from 'react';
import './Permission.css';
import img1 from './img/img1.png';
import img2 from './img/img2.png';
import PermissionsModal from './PermissionsModal';
import AddDesignationForm from './AddDesignationForm';

const Permission = () => {
  const [permissions, setPermissions] = useState([
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Super Admin' },
    { id: 3, name: 'Managing Director' },
    { id: 4, name: 'HR Department Head' },
    { id: 5, name: 'Manager - Finance' },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newPermissionName, setNewPermissionName] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);

  const handlePermissionsClick = (permission) => {
    setSelectedPermission(permission);
    setShowModal(true);
  };

  const handleEditPermission = (permission) => {
    setSelectedPermission(permission);
    setNewPermissionName(permission.name);
    setIsEditing(true);
    setShowAddForm(true);
  };

  const handleSaveEdit = (updatedName) => {
    setPermissions(permissions.map(permission =>
      permission.id === selectedPermission.id
        ? { ...permission, name: updatedName }
        : permission
    ));
    setIsEditing(false);
    setSelectedPermission(null);
    setNewPermissionName('');
    setShowAddForm(false);
  };

  const handleDeletePermission = (permissionId) => {
    setPermissions(permissions.filter(permission => permission.id !== permissionId));
  };

  const handleAddDesignation = (designationName) => {
    if (isEditing) {
      handleSaveEdit(designationName);
    } else {
      const newPermission = {
        id: permissions.length + 1,
        name: designationName,
      };
      setPermissions([...permissions, newPermission]);
      setShowAddForm(false);
    }
  };

  const handleAddButtonClick = () => {
    setShowAddForm(true);
    setIsEditing(false);
    setNewPermissionName(''); // Reset input for new designation
  };

  return (
    <>
      <button className="add-designation-btn" onClick={handleAddButtonClick}>
        Add Designation
      </button>

      <div className="table-container">
        <div className='boxing'>
          <table>
            <thead>
              <tr style={{borderBottom:'1px solid #ccc'}}>
                <th style={{ width: '8%' }}>#</th>
                <th style={{ width: '60%' }}>Designation Name</th>
                <th style={{ width: '35%' ,textAlign:'center' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {permissions.map((permission) => (
                <tr key={permission.id} style={{borderBottom:'1px solid #ccc'}}>
                  <td>{permission.id}</td>
                  <td >{permission.name}</td>
                  <td style={{textAlign:'right'}}>
                    <img
                      src={img1}
                      alt='edit'
                      onClick={() => handleEditPermission(permission)}
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    />
                    <img
                      src={img2}
                      alt='delete'
                      onClick={() => handleDeletePermission(permission.id)}
                      style={{ cursor: 'pointer' }}
                    />
                    <button
                      className='Permissions'
                      onClick={() => handlePermissionsClick(permission)}
                    >
                      Permissions
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showAddForm && (
        <AddDesignationForm
          onAddDesignation={handleAddDesignation}
          onClose={() => setShowAddForm(false)}
          editMode={isEditing}
          designationName={newPermissionName}
          setDesignationName={setNewPermissionName}
        />
      )}

      {showModal && (
        <PermissionsModal
          onClose={() => setShowModal(false)}
          designationName={selectedPermission.name}
        />
      )}
    </>
  );
};

export default Permission;
