// import React from 'react';

// const ViewRequest = () => {
//   return (
//     <div className="view-request-container">
//       <h2>View Request Page</h2>
//       <p>Details of the request will be shown here...</p>
    
//     </div>
//   );
// };

// export default ViewRequest;

// bhgnjukim 

import React, { useState, useEffect } from 'react';
import './main.css';
import AddSite from './AddSite';

import viewIcon from '../img/viewIcon.png';
import editIcon from '../img/editIcon.png';
import archiveIcon from '../img/archiveIcon.png';
import filterIcon from '../img/filtericon.png';

const ViewRequest = () => {
  const [page, setPage] = useState('main');
  const [data, setData] = useState([
    { id: 'po12op12355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', RecheckCycle: '12 months', status: 'New Request' },
    { id: 'mnjk34545588', company: '1st Choice Recovery Llc', address: '31531 Justice Farm Rd.', city: 'South Carolina', state: 'South Carolina', RecheckCycle: '12 months', status: 'In-Progress' },
    { id: 'irexjay', company: '59 Second Recovery LLC', address: '31531 Justice Farm Rd.', city: 'Arizona', state: 'Washington', RecheckCycle: '12 months', status: 'To-Be-Approved' },
    { id: 'AVzxQZI', company: 'A & A Towing and Recovery MO', address: '31531 Justice Farm Rd.', city: 'Arizona', state: 'Minnesota', RecheckCycle: '12 months', status: 'Approved' },
    { id: 'AVzxQZI', company: 'A & A Towing and Recovery MO', address: '31531 Justice Farm Rd.', city: 'Arizona', state: 'Minnesota', RecheckCycle: '12 months', status: 'New Request' },
     { id: 'AVzxQZI', company: 'A & A Towing and Recovery MO', address: '31531 Justice Farm Rd.', city: 'Arizona', state: 'Minnesota', RecheckCycle: '12 months', status: 'Approved' },
    // Add more rows here if needed...
  ]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const handleAddSite = () => {
    setPage('addSite');
  };


  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...data].sort((a, b) => {
      if (key === '#') {
        return direction === 'ascending' ? a.sequence - b.sequence : b.sequence - a.sequence;
      } else {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
    });

    setSortConfig({ key, direction });
    setData(sortedData);
  };

  // Adding sequence numbers dynamically
  const numberedData = data.map((row, index) => ({ ...row, sequence: index + 1 }));

  // Search Filter
  const filteredData = numberedData.filter(row => {
    return Object.values(row).some(value =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Pagination
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleEntriesChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page whenever entries per page change
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderSortIcons = (key) => {
    if (sortConfig.key !== key) {
      return '▲▼';
    }
    return sortConfig.direction === 'ascending' ? '▲' : '▼';
  };

  if (page === 'addSite') {
    return <AddSite />;
  } 

  return (
    <div className="container">
     
      <div className="controls row">
        <div className="show-entries col-lg-2">
          <label>Show </label>
          <select value={entriesPerPage} className='form-control' onChange={handleEntriesChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
          <label> entries</label>
        </div>
        <div className="search-box col-lg-8 text-centre  ">
          <input
            type="text"
            className='form-control'
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className='col-lg-2 text-end'>
        <img className='me-4' src={filterIcon} alt=''/>
        <button className="add-site-button btn btn-primary" onClick={handleAddSite}>Add Site</button>
        </div>
      </div>


     <table className="company-table mt-5">
       <div className='company-table-boxing'>
       <thead>
          <tr>
            <th style={{ width: '5%' }} ># </th>
            <th style={{ width: '12%' }} onClick={() => sortData('id')}>ARA ID {renderSortIcons('id')}</th>
            <th style={{ width: '15%' }} onClick={() => sortData('company')}>Company {renderSortIcons('company')}</th>
            <th style={{ width: '15%' }} onClick={() => sortData('address')}>Address {renderSortIcons('address')}</th>
            <th style={{ width: '11%' }} onClick={() => sortData('city')}>City {renderSortIcons('city')}</th>
            <th style={{ width: '12%' }} onClick={() => sortData('state')}>State {renderSortIcons('state')}</th>
            {/* <th style={{ width: '14%' }} onClick={() => sortData('Recheck Cycle')}>Recheck Cycle {renderSortIcons('Recheck Cycle')}</th> */}
            <th style={{ width: '14%' }} onClick={() => sortData('status')}>Status {renderSortIcons('status')}</th>
            <th style={{ width: '21%' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries.map((row, index) => (
            <tr key={index}>
              <td>{indexOfFirstEntry + index + 1}</td>
              <td>{row.id}</td>
              <td>{row.company}</td>
              <td>{row.address}</td>
              <td>{row.city}</td>
              <td>{row.state}</td>
              {/* <td>{row.RecheckCycle}</td> */}
              <td><span className={`status ${row.status.toLowerCase().replace(' ', '-')}`}>{row.status}</span></td>
              <td className='Action'>
                <span className='viewIcon me-2'><img  src={viewIcon} alt=''/></span>
                <span className='editIcon me-2'>  <img src={editIcon} alt=''/></span>
                <span className='archiveIcon me-2'><img src={archiveIcon} alt=''/></span>
                </td>
            </tr>
          ))}
        </tbody>
       </div>
      </table>
    

      <div className="pagination">
        <ul>
          <li onClick={handlePreviousPage} className={currentPage === 1 ? 'disabled' : ''}>Previous</li>
          {[...Array(totalPages)].map((_, i) => (
            <li
              key={i}
              className={currentPage === i + 1 ? 'active' : ''}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </li>
          ))}
          <li onClick={handleNextPage} className={currentPage === totalPages ? 'disabled' : ''}>Next</li>
        </ul>
      </div>
    </div>
  );
};

export default ViewRequest;
