import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./company.css";
import logo from "./imagecompany/logo.png";
import deletelogo from "./imagecompany/deletelogo.png";

export default function RequestDetails() {
  const [formData, setFormData] = useState({
    requestDate: "",
    requestedBy: "",
    expirationDate: "",
    site: "",
    mainContact: "",
    mainEmail: "",
    mainPhone: "",
    status: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Add logic to save or submit the form data
  };

  const handleExport = () => {
    // Add logic to export the form data
    console.log("Exporting data...");
  };

  return (
    <Container className="my-4 Container ">
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h3 >Request Details</h3>
        <Button variant="outline-primary" onClick={handleExport}>
         <img src={logo}/> 
          Export
        </Button>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="border p-3 rounded" style={{ backgroundColor: "rgba(226, 237, 249, 0.5)" }}>
        <div className="headtitle"><h5 className="mb-3 pt-3 ps-2">Request Details <img className="pe-2 "  src={deletelogo} /></h5>
        </div>  
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formRequestDate">
                <Form.Label><div className="Formlabel">Request Date</div></Form.Label>
                <Form.Control
                  type="date"
                  name="requestDate"
                  value={formData.requestDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formRequestedBy">
                <Form.Label><div className="Formlabel">Requested By</div></Form.Label>
                <Form.Control
                  type="text"
                  name="requestedBy"
                  value={formData.requestedBy}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formExpirationDate">
                <Form.Label><div className="Formlabel">Expiration Date</div></Form.Label>
                <Form.Control
                  type="date"
                  name="expirationDate"
                  value={formData.expirationDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formSite">
                <Form.Label><div className="Formlabel">Site</div></Form.Label>
                <Form.Control
                  type="text"
                  name="site"
                  value={formData.site}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formMainContact">
                <Form.Label><div className="Formlabel">Main Contact</div></Form.Label>
                <Form.Control
                  type="text"
                  name="mainContact"
                  value={formData.mainContact}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formMainEmail">
                <Form.Label><div className="Formlabel">Main Email</div></Form.Label>
                <Form.Control
                  type="email"
                  name="mainEmail"
                  value={formData.mainEmail}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formMainPhone">
                <Form.Label><div className="Formlabel">Main Phone</div></Form.Label>
                <Form.Control
                  type="text"
                  name="mainPhone"
                  value={formData.mainPhone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formStatus">
                <Form.Label><div className="Formlabel">Status</div></Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <option>Select Status</option>
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <div className="text-center">
            <Button type="submit" variant="primary">
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
}
