import React from "react";
import Header from "./Header.js";
import ProgressCards from "./ProgressCards.js";
import CompaniesOverview from "./CompaniesOverview.js";
import RequestsStatusPie from "./RequestsStatusPie.js";

import CircularChartComponent from './CircularChartComponent';
import BarChartComponent from './BarChartComponent';
import WeeklyStatusChart from './WeeklyStatusChart.js';
import YearlyOverviewChart from './YearlyOverviewChart.js';
import RevenueOverview from "./RevenueOverview.js"; 
import RevenueGenerated from './RevenueGenerated';
import RevenueChart from "./RevenueChart.js";
import RevenureForcastTable from './RevenueForcastTable.js'

import "./style.css";
import RevenueForecastChart from "./RevenueForcast.js";
import RequestStatusChart from "./RequestStatusChartFile.js";

const Dashboard = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8 dashboard-file">
      <Header />

      <ProgressCards />

      <div className="row">
        <div className="col-lg-6 mb-6">
          {/* <div className="shadow-custom card-hover transition-custom"> */}
           
            <CompaniesOverview />
          {/* </div> */}
        </div>
        <div className="col-lg-6">
        {/* <div className="shadow-custom card-hover transition-custom">
          <RequestsStatusPie />
          </div> */}
        </div>
      </div>
    

<div className="shadow-custom card-hover transition-custom">
         <div className="row mt-4">
<div className="col-lg-6">
<CircularChartComponent />

</div>
<div className="col-lg-6">
      {/* <BarChartComponent /> */}
 < RequestStatusChart/>
      </div>
      </div>
    </div>
    <div className="shadow-custom card-hover transition-custom">

    {/* <div className="mt-4">
      <WeeklyStatusChart />
    </div> */}
</div>


{/* <div className="row mt-4">
      <YearlyOverviewChart />
      
    </div>
    <div className="row mt-4">
     <div className="col-lg-6">
     <RevenueOverview />
     </div>
     <div className="col-lg-6">
      <RevenueGenerated/>
     </div>
    </div>
 

<div className="row">
<RevenueChart/>
</div>

<div className="row">
<RevenueForecastChart/>
</div>

<div className="row">
  <RevenureForcastTable/>
</div> */}

</div>

    
  );
};

export default Dashboard;
