import React, { useEffect, useState } from 'react';
import './main.css';
import AddSite from './AddSite';
import ViewRequest from './ViewRequest';
import ViewPage from './ViewPage';
import EditCompany from './EditCompany.js';
import viewIcon from '../img/viewIcon.png';
import { useNavigate } from 'react-router-dom';
import archiveIcon from '../img/unArchiveIcon.png';
import Loader from './Loader.js';
import config from "../../../../../config/config.json";
import { Modal, Button } from 'react-bootstrap';
import { apiCall } from '../../../../../services/ApiCall.js';


const Main = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState('main');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [editData, setEditData] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const handleActionIconClick = (id) => {
    setDeleteId(id);
    setShowArchiveModal(true);
  };
  const handleAction = () => {
    actionComapny(deleteId);
    setShowArchiveModal(false);
  };




  const handleViewRequest = () => {
    setPage('viewRequest');
  };

  const handleView = (rowData) => {
    setViewData(rowData);
    setPage('view');
  };

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...data].sort((a, b) => {
      if (key === 'sequence') {
        return direction === 'ascending' ? a.sequence - b.sequence : b.sequence - a.sequence;
      } else {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
    });

    setSortConfig({ key, direction });
    setData(sortedData);
  };

  const numberedData = data.map((row, index) => ({ ...row, sequence: index + 1 }));

  const filteredData = numberedData.filter(row => {
    return ['id', 'name', 'street', 'city', 'state', 'zipcode'].some(key =>
      row[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.key === 'sequence') {
      return sortConfig.direction === 'ascending' ? a.sequence - b.sequence : b.sequence - a.sequence;
    } else {
      return 0;
    }
  });
  const currentEntries = sortedData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEntriesChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderSortIcons = (key) => {
    if (sortConfig.key !== key) {
      return '▲▼';
    }
    return sortConfig.direction === 'ascending' ? '▲' : '▼';
  };

  const getArchiveCompanyList = async () => {
    setLoading(true); 
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getArchiveCompanyList`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setData(data?.data?.reverse());
    }
    setLoading(false); 
  };


  const actionComapny = async (id) => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}unArchiveCompany`,
      {},
      { companyId: id },
      "POST"
    );
    if (isSuccess) {
      getArchiveCompanyList();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getArchiveCompanyList();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const renderPagination = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(filteredData.length / entriesPerPage);

    let startPage = currentPage - 1;
    let endPage = currentPage + 1;

    if (startPage < 1) {
      startPage = 1;
      endPage = 3;
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - 2 > 0 ? totalPages - 2 : 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`pagination-item ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }

    return (
      <ul className="pagination">
        <li
          className={`pagination-item ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={handlePreviousPage}
        >
          Previous
        </li>
        {pageNumbers}
        <li
          className={`pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={handleNextPage}
        >
          Next
        </li>
      </ul>
    );
  };

  if (page === 'addSite') {
    return <AddSite />;
  } else if (page === 'viewRequest') {
    return <ViewRequest />;
  } else if (page === 'view' && viewData) {
    return <ViewPage {...viewData} />;
  }
  else if (page === 'edit' && editData) {
    return <EditCompany companyData={editData} />;
  }

  return (
    <div className="container mt-1">
      <div className="controls row">
        <div className="show-entries col-lg-2">
          <label>Show </label>
          <select value={entriesPerPage} className="form-control" onChange={handleEntriesChange}>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={500}>500</option>
          </select>
          <label> entries</label>
        </div>
        <div className="search-box col-lg-8 text-center">
          <input
            type="text"
            className="form-control"
            placeholder="Search by ID, Name, Street, City, State, or Zipcode..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

      </div>

      <table className="company-table mt-5" style={{ fontSize: '14px' }}>
        <thead>
          <tr>
            <th style={{ width: '8%' }} onClick={() => sortData('sequence')}> ID {renderSortIcons('sequence')}</th>
            <th style={{ width: '12%' }} onClick={() => sortData('name')}>Name {renderSortIcons('name')}</th>
            <th style={{ width: '10%' }} onClick={() => sortData('street')}>Street {renderSortIcons('street')}</th>
            <th style={{ width: '10%' }} onClick={() => sortData('street2')}>Street 2 {renderSortIcons('street2')}</th>
            <th style={{ width: '10%' }} onClick={() => sortData('city')}>City {renderSortIcons('city')}</th>
            <th style={{ width: '10%' }} onClick={() => sortData('state')}>State {renderSortIcons('state')}</th>
            <th style={{ width: '10%' }} onClick={() => sortData('zipcode')}>Zipcode {renderSortIcons('zipcode')}</th>
            <th style={{ width: '20%' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries.map((row, index) => (
            <tr key={index}>
              <td>{row.id}</td>
              <td>{row.name}</td>
              <td>{row.street}</td>
              <td>{row.street2}</td>
              <td>{row.city}</td>
              <td>{row.state}</td>
              <td>{row.zipcode}</td>
              <td>
                <img src={viewIcon} className="img-fluid view-icon me-1" alt="View" title='view' onClick={() => handleView(row)} />
                <img src={archiveIcon} className="img-fluid archive-icon me-1" alt="Archive" title='archive' onClick={() => handleActionIconClick(row.id, 'ARCHIVED')} />
                <button className="view-request-button btn btn-link p-2 ms-1" style={{ border: '1px solid #0050A4', textDecoration: 'none', backgroundColor: '#0050A4', color: 'white', fontSize: '12px' }} onClick={handleViewRequest}>View Site</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


      {totalPages > 1 && renderPagination()}
      <Modal show={showArchiveModal} onHide={() => setShowArchiveModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Un Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Un Archive this entry?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowArchiveModal(false)}>
            No
          </Button>
          <Button variant="danger" onClick={handleAction}>
            Yes, Un Archive
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Main;
