import React, { useEffect } from "react";
import "./SiteDetail.css";
import deleteIcon from "../img/deleteIcon.png";
import exportIcon from "../img/exportIcon.png";


function SiteDetail({ onSwitchToContact, setAnswer }) {
  const changeCity = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        city: e.target.value,
      };
    });
  };
  const changeName = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        name: e.target.value,
      };
    });
  };
  const changeStreet = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        street: e.target.value,
      };
    });
  };
  const changeStreet2 = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        street2: e.target.value,
      };
    });
  };
  const changeState = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        state: e.target.value,
      };
    });
  };
  const changeZipcode = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        zipcode: e.target.value,
      };
    });
  };
  const changeOfficeType = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        officeType: e.target.value,
      };
    });
  };







  
  return (
    <div className="add-site">
      <div className="header">
        <h2>Add Company</h2>
        <button className="export-btn m-1">
          <img className="me-1 " src={exportIcon} alt=" " /> Export
        </button>
      </div>
      <div className="tabs" style={{ border: "none", background: "white" }}>
        <button className="tab active">Company Detail</button>
        <button className="tab" onClick={onSwitchToContact}>
          Main Contact
        </button>
      </div>
      <div className="site-detail-h">
        <div className="site-detail-left ">Company Detail</div>
        <div className="site-detail-right h-75">
          {" "}
          <img src={deleteIcon} alt="" className="h-75" />{" "}
        </div>
      </div>

      <div className="site-detail">
        <form>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="ara-id">ARA ID</span>
              <input type="text" id="ara-id" />
            </div>
            <div className="form-group">
              <span htmlFor="company">Company Name</span>
              <input onChange={changeName} type="text" id="company" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="ara-id">Street </span>
              <input onChange={changeStreet} type="text" id="ara-id" />
            </div>
            <div className="form-group">
              <span htmlFor="company">Street 2</span>
              <input onChange={changeStreet2} type="text" id="company" />
            </div>
          </div>
          {/* <div className="form-row">
            <div className="form-group">
              <span htmlFor="address">Address</span>
              <input type="text" id="address" />
            </div>
            <div className="form-group">
              <span htmlFor="address-2">Address 2</span>
              <input type="text" id="address-2" />
            </div>
            <div className="form-group">
              <span htmlFor="state">State</span>
              <input type="text" id="state" />
            </div>
          </div> */}
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="city">City</span>
              <input onChange={changeCity} type="text" id="city" />
            </div>
            <div className="form-group">
              <span htmlFor="state-2">State</span>
              <input onChange={changeState} type="text" id="state-2" />
            </div>
            <div className="form-group">
              <span htmlFor="zipcode">Zipcode</span>
              <input onChange={changeZipcode} type="text" id="zipcode" />
            </div>
            <div className="form-group">
              <span htmlFor="recheck-cycle">Type</span>
              {/* <input onChange={changeOfficeType} type="text" id="recheck-cycle" /> */}
              <select
                  onChange={changeOfficeType}
                  className="form-control col-lg-6"
                  // value= {answers?.officeType}
                >
                  <option value="Head Office">Head Office</option>
                  
                </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="ara-id">Lender Name </span>
              <input type="text" id="ara-id" />
            </div>
            <div className="form-group">
              <span htmlFor="company">Lender Email</span>
              <input type="text" id="company" />
            </div>
          </div>
          <div className="text-end">
            <button className="next-btn" onClick={onSwitchToContact}>
              {" "}
              Next{" "}
            </button>
          </div>
          {/* <div className='form-group text-end'>
          <button type="submit" className="next-btn ">Next</button>
          </div> */}
        </form>
      </div>
    </div>
  );
}

export default SiteDetail;
