import React from 'react';
import './SiteDetail.css';
import deleteIcon from '../img/deleteIcon.png';
import exportIcon from'../img/exportIcon.png';
function SiteDetail({ onSwitchToContact, setAnswer }) {

  const changeAraId = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        araId: e.target.value,
      };
    });
  };
  const changeCompany = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        company: e.target.value,
      };
    });
  };
  const changeAddress = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        address: e.target.value,
      };
    });
  };
  const changeAddress2 = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        address2: e.target.value,
      };
    });
  };
  const changeState = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        state: e.target.value,
      };
    });
  };
  const changeCity = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        city: e.target.value,
      };
    });
  };
  const changeZipcode = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        zipcode: e.target.value,
      };
    });
  };
  const changeRecheckcycle = (e) => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        recheckcycle: e.target.value,
      };
    });
  };




  return (
    <div className="add-site">
      <div className="header">
        <h2>Add Site</h2>
        <button className="export-btn m-1">
          <img className='me-1 ' src={exportIcon} alt=' '/>  Export</button>
      </div>
      <div className="tabs">
        <button className="tab active">Site Detail</button>
        <button className="tab" onClick={onSwitchToContact}>Site Contact</button>
      </div>
      <div className='site-detail-h'>
        <div className='site-detail-left'>Site Detail</div>
        <div className='site-detail-right'>  <img src={deleteIcon} alt=''/> </div>
        </div>
      
      <div className="site-detail">

        <form>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="ara-id">ARA ID</span>
              <input onChange={changeAraId} type="text" id="ara-id" />
            </div>
            <div className="form-group">
              <span htmlFor="company">Company</span>
              <input onChange={changeCompany} type="text" id="company" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="address">Address</span>
              <input onChange={changeAddress} type="text" id="address" />
            </div>
            <div className="form-group">
              <span htmlFor="address-2">Address 2</span>
              <input onChange={changeAddress2} type="text" id="address-2" />
            </div>
            <div className="form-group">
              <span htmlFor="state">State</span>
              <input onChange={changeState} type="text" id="state" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="city">City</span>
              <input onChange={changeCity} type="text" id="city" />
            </div>
            {/* <div className="form-group">
              <span htmlFor="state-2">State</span>
              <input type="text" id="state-2" />
            </div> */}
            <div className="form-group">
              <span htmlFor="zipcode">Zipcode</span>
              <input onChange={changeZipcode} type="text" id="zipcode" />
            </div>
            <div className="form-group">
              <span htmlFor="recheck-cycle">Recheck Cycle</span>
              <input onChange={changeRecheckcycle } type="text" id="recheck-cycle" />
            </div>
          </div>
          <div  className=' text-end'>
          <button className="next-btn ps-4 pe-4" onClick={onSwitchToContact}>Next</button>

          </div>
          {/* <div className='form-group text-end'>
          <button type="submit" className="next-btn ps-4 pe-4">Next</button>
          </div> */}
        </form>
      </div>
    </div>
  );
}

export default SiteDetail;