import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { apiCall } from "../../services/ApiCall.js"; 
import config from "../../config/config.json";

const RequestStatusChart = () => {
  const [selectedCategory, setSelectedCategory] = useState('New Request');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString()); 
  const [requestData, setRequestData] = useState({
    'New Request': Array(12).fill(0),
    'In-Progress Request': Array(12).fill(0),
    'To-Be-Approved Request': Array(12).fill(0),
    'Approved Request': Array(12).fill(0),
  });

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 5 }, (_, index) => currentYear - index).map(year => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  const fetchData = async (year) => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getRequestGraphData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      const requestCounts = {
        'New Request': Array(12).fill(0),
        'In-Progress Request': Array(12).fill(0),
        'To-Be-Approved Request': Array(12).fill(0),
        'Approved Request': Array(12).fill(0),
      };

      const startYear = year; 

      data?.data.forEach((request) => {
        const { status, updatedAt } = request;
        const requestDate = new Date(updatedAt);
        const requestYear = requestDate.getFullYear();
        const requestMonth = requestDate.getMonth();

        if (requestYear.toString() == startYear) {
          switch (status) {
            case '0':
            requestCounts['New Request'][requestMonth] += 1;
            break;
            case '1':
            requestCounts['In-Progress Request'][requestMonth] += 1;
            break;
            case '4':
              requestCounts['Approved Request'][requestMonth] += 1;
              break;
            case '2': 
              requestCounts['To-Be-Approved Request'][requestMonth] += 1;
              break;
            default:
              break;
          }
        }
      });


      setRequestData(requestCounts);
    }
  };

  useEffect(() => {
    fetchData(selectedYear);
  }, [selectedYear]);

  const options = {
    chart: {
      type: 'bar',
      height: 10,
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        horizontal: false,
      },
    },
    xaxis: {
      categories: [
        'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
        'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC',
      ],
    },
    fill: {
      opacityFrom: 0.4,
      opacityTo: 0.5,
    },
    dataLabels: {
      enabled: false,
    },
  };

  const series = [
    {
      name: selectedCategory,
      data: requestData[selectedCategory],
    },
  ];

  return (
    <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '12px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ color: 'black', fontSize: '24px', fontWeight: 'bold' }}>Requests Status</h2>
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          style={{ padding: '1px', fontSize: '16px', color: '#0050A4' }}
        >
          {generateYearOptions()}
        </select>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '40px', marginBottom: '20px', fontSize: '12px' }}>
        {['New Request', 'In-Progress Request', 'To-Be-Approved Request', 'Approved Request'].map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            style={{
              color: selectedCategory === category ? '#002A7E' : 'rgba(71, 97, 149, 0.5)',
              border: 'none',
              fontWeight: 'bold',
              backgroundColor: 'white',
              cursor: 'pointer',
            }}
          >
            {category}
          </button>
        ))}
      </div>
      <Chart options={options} series={series} type="bar" height={205} />
    </div>
  );
};

export default RequestStatusChart;
