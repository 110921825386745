import React, { useEffect, useState } from "react";
import exportIcon from "../img/exportIcon.png";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import "./sitehistory.css";
import { useNavigate } from "react-router-dom";

const SiteHistory = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const getSitesHistoryList = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesHistoryList`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setData(data?.data?.reverse());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getSitesHistoryList();
  }, []);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12 || 12; // Convert to 12-hour format
    hours = String(hours).padStart(2, '0'); // Pad hours if needed
  
    return `${month}-${day}-${year} ${hours}:${minutes} ${ampm}`;
  };
  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredData = data.filter((item) => {
    const companyName = item.companyName || "";
    const araId = item.araId || "";
    const activityBy = item.name || ""; // Correctly references item.name
    const address = item.address || "";
    const activity = item.activity || "";
    const createdAt = item.createdAt || "";
    const fullAddress = `${item?.address} ${item?.city} ${item?.state}`.toLowerCase();

    return (
      companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      araId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      activityBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
      address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      activity.toLowerCase().includes(searchTerm.toLowerCase()) ||
      createdAt.toLowerCase().includes(searchTerm.toLowerCase()) ||
      fullAddress.includes(searchTerm.toLowerCase()) 
    );
  });

  console.log("lk", filteredData)

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ fontSize: "32px", color: "rgba(0, 80, 164, 1)" }}>
          Site History
        </h2>
     
      </div>
      <div>
        {/* Search Box */}
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            width: "100%",
            padding: "8px",
            marginTop: "0.5em",
            color: "rgb(0, 80, 164)",
            marginBottom: "20px",
            borderRadius: "10px",
            border: "1px solid #ccc",
            fontSize: "16px",
          }}
        />
      </div>
      <div
        style={{
          borderRadius: "10px",
          width: "100%",
          boxSizing: "border-box",
          marginTop: "0.5em",
        }}
      >
        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              backgroundColor: "white",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "18%",
                    padding: "16px 15px",
                    textAlign: "left",
                    fontSize: "14px",
                    color: "white",
                    backgroundColor: "#0050A4",
                    fontWeight: "bold",
                    position: "sticky",
                    top: "0",
                  }}
                >
                  Company
                </th>
                <th
                  style={{
                    width: "18%",
                    padding: "12px 15px",
                    textAlign: "left",
                    fontSize: "14px",
                    color: "white",
                    backgroundColor: "#0050A4",
                    fontWeight: "bold",
                    position: "sticky",
                    top: "0",
                  }}
                >
                  ARA ID
                </th>
                <th
                  style={{
                    width: "18%",
                    padding: "12px 15px",
                    textAlign: "left",
                    fontSize: "14px",
                    color: "white",
                    backgroundColor: "#0050A4",
                    fontWeight: "bold",
                    position: "sticky",
                    top: "0",
                  }}
                >
                  Activity By
                </th>
                <th
                  style={{
                    width: "18%",
                    padding: "12px 15px",
                    textAlign: "left",
                    fontSize: "14px",
                    color: "white",
                    backgroundColor: "#0050A4",
                    fontWeight: "bold",
                    position: "sticky",
                    top: "0",
                  }}
                >
                  Address
                </th>
                <th
                  style={{
                    width: "18%",
                    padding: "12px 15px",
                    textAlign: "left",
                    fontSize: "14px",
                    color: "white",
                    backgroundColor: "#0050A4",
                    fontWeight: "bold",
                    position: "sticky",
                    top: "0",
                  }}
                >
                  Activity
                </th>
                <th
                  style={{
                    width: "20%",
                    padding: "12px 15px",
                    textAlign: "left",
                    fontSize: "14px",
                    color: "white",
                    backgroundColor: "#0050A4",
                    fontWeight: "bold",
                    position: "sticky",
                    top: "0",
                  }}
                >
                  Date & Time
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: "white",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      fontSize: "14px",
                      color: "#0050A4",
                    }}
                  >
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="company"
                        value={editData.company}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item?.companyName
                    )}
                  </td>
                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      fontSize: "14px",
                      color: "#0050A4",
                    }}
                  >
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="araId"
                        value={editData.araId}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item.araId
                    )}
                  </td>
                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      fontSize: "14px",
                      color: "#0050A4",
                    }}
                  >
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="activityBy"
                        value={editData.activityBy}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item?.name
                    )}
                  </td>

                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      fontSize: "14px",
                      color: "#0050A4",
                    }}
                  >
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="activityBy"
                        value={editData.activityBy}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item?.address + ' ' + item?.city+ ' ' + item?.state
                    )}
                  </td>

                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      fontSize: "14px",
                      color: "#0050A4",
                    }}
                  >
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="activity"
                        value={editData.activity}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      item?.activity
                    )}
                  </td>
                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      fontSize: "14px",
                      color: "#0050A4",
                    }}
                  >
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="dateTime"
                        value={editData.dateTime}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      formatDateTime(item?.createdAt)
                    )}
                  </td>
                  {/* <td style={{ padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: '#0050A4' }}>
                    {editIndex === index ? (
                      <button className=' btn btn-primary' onClick={handleSaveClick} style={{ backgroundColor: '#0050A4', border: 'none', cursor: 'pointer' }}>
                        Save
                      </button>
                    ) : (
                      <img
                        src={editIcon}
                        alt='edit icon'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEditClick(index)}
                      />
                    )}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SiteHistory;
