import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiCall } from "../../services/ApiCall.js";
import config from "../../config/config.json";
import * as XLSX from 'xlsx';
import './styles.css';

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [invoiceId, setInvoiceId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const hash = window.location.hash;
    const regex = /\/invoice\/(\d+)/;
    const match = hash.match(regex);

    if (match) {
      setInvoiceId(match[1]);
    }
  }, []);

  const getAllInvoice = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getRequestsList`,
      {},
      { invoiceId, requestId: null },
      "GET"
    );
    if (isSuccess) {
      setData(data?.data?.reverse());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      if (invoiceId) getAllInvoice();
    }
  }, [invoiceId]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data
    .slice() // Create a shallow copy to avoid mutating the original data
    .sort((a, b) => new Date(b.approvedDate) - new Date(a.approvedDate)) // Sort by approvedDate in descending order
    .filter((invoice) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        `${invoice?.id}`.toLowerCase().includes(searchLower) ||
        `${invoice?.araId}`.toLowerCase().includes(searchLower) ||
        `${invoice?.approvedDate}`.toLowerCase().includes(searchLower) ||
        `${invoice?.companyName}`.toLowerCase().includes(searchLower) ||
        `${invoice?.siteAddress} ${invoice?.siteCity} ${invoice?.siteState}`.toLowerCase().includes(searchLower) ||
        `${invoice?.name}`.toLowerCase().includes(searchLower) ||
        `${invoice?.email}`.toLowerCase().includes(searchLower) ||
        `${invoice?.phone}`.toLowerCase().includes(searchLower)
      );
    });

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredData.map(invoice => ({
        'MSI ID': invoice?.id,
        "ARA ID": invoice?.araId,
        "Approved Date": invoice?.approvedDate ? new Date(invoice.approvedDate).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }).replace(/\//g, '-') : '',
        Company: invoice?.companyName,
        Site: `${invoice?.siteAddress}, ${invoice?.siteCity}, ${invoice?.siteState}`,
        "Main Contact": invoice?.name,
        "Main Contact Email": invoice?.email,
        "Main Contact Phone": invoice?.phone,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
    XLSX.writeFile(workbook, "Invoices.xlsx");
  };

  return (
    <div className="container">
  <div class="invoice-header">
  <h2>Invoice Details</h2>
  <button onClick={exportToExcel} className="export-button">Export to Excel</button>
  <input
    type="text"
    placeholder="Search by Company or Site"
    value={searchQuery}
    onChange={handleSearchChange}
    className="search-bar"
  />
</div>


      <table className="invoice-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>ARA ID</th>
            <th>Approved Date</th>
            <th>Company</th>
            <th>Site</th>
            <th>Main Contact</th>
            <th>Main Contact Email</th>
            <th>Main Contact Phone</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map((invoice) => (
              <tr key={invoice?.id}>
                <td>{invoice?.id}</td>
                <td>{invoice?.araId}</td>
                <td>
                  {invoice?.approvedDate
                    ? new Date(invoice.approvedDate).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                      }).replace(/\//g, '-')
                    : ''}
                </td>
                <td>{invoice?.companyName}</td>
                <td>{`${invoice?.siteAddress}, ${invoice?.siteCity}, ${invoice?.siteState}`}</td>
                <td>{invoice?.name}</td>
                <td>{invoice?.email}</td>
                <td>{invoice?.phone}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">No invoices found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceDetails;
