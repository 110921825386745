import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

function DynamicBreadcrumb() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumb className="bdc">
      {/* Uncomment this if you want to add a "Home" link */}
      {/* <BreadcrumbItem>
        <Link to="/">Home</Link>
      </BreadcrumbItem> */}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return (
          <BreadcrumbItem key={name} active={isLast}>
            {isLast ? (
              <>
                <i className="bi bi-caret-left-fill" style={{color: `#0050A4`}}></i> {/* Icon */}
                <span className="text-capitalize" style={{color: `#0050A4`}}>{name}</span> {/* Text for the last item */}
              </>
            ) : (
              <>
                <i className="bi bi-caret-left-fill" style={{color: `#0050A4`}}></i> {/* Icon */}
                <Link to={routeTo} className="text-capitalize" style={{color: `#0050A4`}}>
                  {name === "dashboardfile" ? "Dashboard" : name}
                </Link> {/* Link for non-last items */}
              </>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
}

export default DynamicBreadcrumb;
