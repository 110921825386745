import { text } from "@fortawesome/fontawesome-svg-core";
import React, { useState } from "react";
import exportIcon from "./img/exportIcon.png";
// import "./App.css";

function App() {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div style={styles.appContainer}>
        <div style={{display:'flex', justifyContent:'space-between', marginBottom:'20px' }}>
        <div style={styles.header}>Add Request</div>
        <div> <button style={{padding:'5px 12px',fontSize:'16px', color:'#0050A4' , backgroundColor:'white', border:'1px solid #0050A4' }}> <img src={exportIcon} alt=""/> Export</button> </div>
        </div>
 
<div className="mb-4">
   
<h3 style={{color:'white', fontSize:'20px', padding:'15px'}}>Request Information</h3>
<div style={styles.requestInfo}>
            
            <label className="mb-2">Scheduled For</label>
            <div>
            <input type="date" style={styles.datePicker} />
  
            </div>
          </div>
</div>

<h3 style={styles.locationHeader}>Location</h3>

      <div style={styles.requestBox} className="mb-4">
        
       
        <div style={styles.locationBox}>
         
          <div style={styles.locationRow}>
            <div style={styles.locationColumn}>
              <label>Company</label>
              <select style={styles.input}>
                <option value="">Select Company</option>
              </select>
            </div>
            <div style={styles.locationColumn}>
              <label>Site</label>
              <select style={styles.input}>
                <option value="">Select Site</option>
              </select>
            </div>
            <div style={styles.locationColumn}>
              <label>Select Main Contact</label>
              <select style={styles.input}>
                <option value="">Select Contact</option>
              </select>
            </div>
          </div>
          <div style={styles.locationRow} className="mt-4">
            <div style={styles.locationColumn}>
              <label>Name</label>
              <input type="text" style={styles.input} />
            </div>
            <div style={styles.locationColumn}>
              <label>Email Id</label>
              <input type="email" style={styles.input} />
            </div>
            <div style={styles.locationColumn}>
              <label>Phone Number</label>
              <input type="tel" style={styles.input} />
            </div>
          </div>
        </div>
 
 

      
    
      </div>

      <div style={{display:'flex', justifyContent:'space-between',padding:'5px'}}>
 <div style={styles.notificationToggle}>
        <div
            onClick={handleToggle}
            style={{
              ...styles.toggleSwitch,
              backgroundColor: toggle ? "#4caf50" : "#A40031",
              
            }}
          >
            <div
              style={{
                ...styles.toggleHandle,
                left: toggle ? "27.5px" : "2.5px",
              }}
            ></div>
          </div>
          <label className="ms-2" style={{color:'#0050A4'}}>Don't Send Email Notification</label>
        
        </div>



<div>
<button style={styles.saveButton}>+ Save</button>
    </div>        
    
 </div>
    </div>
  );
}

const styles = {
  appContainer: {
    fontFamily: "Arial, sans-serif",
    // maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
  },
  header: {
    color: "#0050A4",
    fontSize:'32px'
  },
  requestBox: {
    border: "1px solid #e6e6e6",
    padding: "20px",
    color:'#0050A4',
    backgroundColor: "white",
    borderRadius: '2px', // Rounded corners
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)', // Subtle shadow
  },
  requestInfo: {
    // margin:'10px',
    padding:'25px',
    color:'#0050A4',
    // border:'1px solid black',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box ',

    marginBottom: "20px",

    borderRadius: '2px', // Rounded corners
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)', // Subtle shadow
  },
  datePicker: {
    padding: "8px",
    color:'#0050A4',
    border:'1px solid #0050A4',
    width: "40%",
    boxSizing: "border-box",
  },
  locationBox: {
    marginBottom: "20px",
    color:'#0050A4',
  },
  locationHeader: {
    color: "white",
    fontSize:'20px',
    padding:'15px',
    marginBottom: "5px",
    display: "block",
  },
  locationRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    color:'#0050A4',
  },
  locationColumn: {
    width: "30%",
  },
  input: {
    width: "100%",
    color:'#0050A4',
    padding: "5px",
    boxSizing: "border-box",
    border: "1px solid #0050A4",
    borderRadius: "3px",
  },
  notificationToggle: {
    display: "flex",
    alignItems: "center",
    justifyContent:'flex-start',
    marginBottom: "20px",
  },
  toggleSwitch: {
    width: "50px",
    height: "25px",
    borderRadius: "25px",
    cursor: "pointer",
    position: "relative",
    transition: "background-color 0.3s",
  },
  toggleHandle: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    top: "2.5px",
    transition: "left 0.3s",
  },
  saveButton: {
    backgroundColor: "#0033a0",
    color: "white",
    border: "none",
    padding: "5px 22px",
    cursor: "pointer",
    borderRadius: "3px",
    fontSize: "20px",
  },
};

export default App;
