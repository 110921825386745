import React, { useState } from 'react';
import exportIcon from '../img/exportIcon.png';
import deleteIcon from '../img/deleteIcon.png';
import MainContact from './MainContact.js';  // Import the new component

const EditCompany = ({ companyData }) => {
  const [formData, setFormData] = useState(companyData);
  const [currentPage, setCurrentPage] = useState('editCompany');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Updated company data:', formData);
  };

  const handleNext = () => {
    setCurrentPage('mainContact');
  };

  const handlePrevious = () => {
    setCurrentPage('editCompany');
  };

  if (currentPage === 'mainContact') {
    return <MainContact onPrevious={handlePrevious} companyData={formData} />;
  }

  const styles = {
    container: {
      padding: '20px',
      position: 'relative',
    },
    title: {
      fontSize: '32px',
      color: '#0050A4',
      marginBottom: '20px',
    },
    exportButton: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      padding: '5px 20px',
      backgroundColor: '#fff',
      fontSize: '16px',
      border: '1px solid #0050A4',
      borderRadius: '5px',
      color: '#0050A4',
      cursor: 'pointer',
    },
    companyDetails: {
      backgroundColor: '#E2EDF9',
      padding: '20px',
      marginTop: '0px',
      borderRadius: '5px',
    },
    companyDetailsTitle: {
      backgroundColor: '#0050A4',
      fontSize: '20px',
      color: 'white',

      // padding:'20px',
      marginBottom: '15px',
    },
    formGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '20px',  // Gap between columns
      gridRowGap: '20px',  // Gap between rows
      marginTop: '0px',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      marginBottom: '5px',
      color: '#0050A4',
    },
    input: {
      padding: '10px',
      border: '1px solid #0050A4',
      borderRadius: '4px',
      color: '#0050A4',
    },
    nextButton: {
      marginTop: '20px',
      padding: '10px 22px',
      backgroundColor: '#0050A4',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      //  position: 'relative',
      // float: 'right',


    },
    fullcontainer: {
      backgroundColor: '#E2EDF9',
    },
    rowGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '20px',  // Gap between columns
      gridRowGap: '20px',  // Gap between rows
    },
    lenderRowGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '20px',  // Gap between columns
      gridRowGap: '20px',  // Gap between rows
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Edit</h1>
      <button style={styles.exportButton}><span><img src={exportIcon} className='me-1' /> </span> Export</button>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#0050A4', paddingTop: '10px' }} className='mt-5 mb-0'>
          <div style={styles.companyDetailsTitle} className='ps-3 pt-1' >Company Details</div>
          <div style={{ padding: '0px', marginBottom: '15px', }} className='pe-2'> <img src={deleteIcon} /> </div>
        </div>
        <div className='fullcontainer'>
          <div style={styles.companyDetails}>
            <div style={styles.formGrid}>
              <div style={styles.formGroup}>
                <label htmlFor="araId" style={styles.label}> ID</label>
                <input
                  type="text"
                  id="araId"
                  name="araId"
                  value={formData?.id}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="name" style={styles.label}>Company Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData?.name}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="street" style={styles.label}>Street</label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  value={formData?.street}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="street2" style={styles.label}>Street 2</label>
                <input
                  type="text"
                  id="street2"
                  name="street2"
                  value={formData?.street2}
                  onChange={handleInputChange}
                  style={styles?.input}
                />
              </div>
            </div>
            <div style={styles.rowGrid} className='mt-4'>
              <div style={styles.formGroup}>
                <label htmlFor="city" style={styles.label}>City</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData?.city}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="state" style={styles.label}>State</label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={formData?.state}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="zipcode" style={styles.label}>Zipcode</label>
                <input
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  value={formData?.zipcode}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="type" style={styles.label}>Type</label>
                <input
                  type="text"
                  id="type"
                  name="type"
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>
            </div>
            <div style={styles.lenderRowGrid} className='mt-4'>
              <div style={styles.formGroup}>
                <label htmlFor="lenderName" style={styles.label}>Lender Name</label>
                <input
                  type="text"
                  id="lenderName"
                  name="lenderName"
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="lenderEmail" style={styles.label}>Lender Email</label>
                <input
                  type="email"
                  id="lenderEmail"
                  name="lenderEmail"
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>
            </div>
            <div className='text-end'>

              <button type="button" onClick={handleNext} style={styles.nextButton}>Next</button>
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default EditCompany;
