// PermissionsModal.jsx
import React, { useState } from 'react';
import './PermissionsModal.css';

const PermissionsModal = ({ onClose, designationName }) => {
  const [permissions, setPermissions] = useState({
    all: false,
    inProgressList: false,
    viewForm: false,
    viewRequest: false,
    goToInspection: false,
    cancelInspection: false,
    reassignRequest: false
  });

  const handleCheckboxChange = (permission) => {
    setPermissions(prev => ({
      ...prev,
      [permission]: !prev[permission]
    }));
  };

  return (
    <div className="permissions-modal-overlay">
      <div className="permissions-modal">
        <h2>{designationName} Permissions</h2>
        <div className="permissions-grid">
          {Object.entries(permissions).map(([key, value]) => (
            <div key={key} className="permission-item">
              <input
                type="checkbox"
                id={key}
                checked={value}
                onChange={() => handleCheckboxChange(key)}
              />
              <label htmlFor={key}>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
            </div>
          ))}
        </div>
        <div className="modal-actions">
          <button onClick={onClose}>Close</button>
          <button className="save-button" style={{backgroundColor:'203AAB'}}>Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default PermissionsModal;