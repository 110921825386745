import React, { useEffect, useState } from 'react';
import { apiCall } from '../../../services/ApiCall';
import config from '../../../config/config.json';
import { useNavigate } from 'react-router-dom';

const MainContact = ({ onPrevious, siteData, setShowDetails }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);

  const getSitesContact = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesContact`,
      {},
      { siteId: siteData.id },
      "GET"
    );
    if (isSuccess) {
      setFormData(data?.data?.sitesUserData || []);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getSitesContact();
  }, [siteData.id]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    const updatedFormData = formData.map((contact, i) =>
      i == index ? { ...contact, [name]: value } : contact

    );
    // console.log(formData[index],"dfghjkfdty",updatedFormData[index])
    setFormData(updatedFormData);
  };


  const handleSave = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}updateSitesData`,
      {},
      { siteId: siteData.id, ...siteData ,userData: formData},
      "POST"
    );
    if (isSuccess) {
      setShowDetails(false)
    }
  };

  const handleDeleteContact = async (userId) => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}deleteSitesAndContact`,
      {},
      { siteId: siteData.id, userId },
      "POST"
    );
    if (isSuccess) {
      getSitesContact()
    }
  };

  return (
    <>
      <div style={{ padding: '20px' }}>
        <div style={styles.header}>
          <span>Edit Site Contact</span>

        </div>
        <div style={styles.container}>
          <div style={styles.form}>
            {formData.map((contact, index) => (
              <div key={index} className='m-3 p-4' style={{ border: '2px solid #969da7' }}>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <div style={{ flex: 1 }}>
                    <div style={styles.formGroup}>
                      <label style={styles.label}>First Name</label>
                      <input
                        style={styles.input}
                        type="text"
                        name="fname"
                        value={contact.fname}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={styles.formGroup}>
                      <label style={styles.label}>Last Name</label>
                      <input
                        style={styles.input}
                        type="text"
                        name="lname"
                        value={contact.lname}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <div style={{ flex: 1 }}>
                    <div style={styles.formGroup}>
                      <label style={styles.label}>Email</label>
                      <input
                        style={styles.input}
                        type="email"
                        name="email"
                        value={contact.email}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={styles.formGroup}>
                      <label style={styles.label}>Phone</label>
                      <input
                        style={styles.input}
                        type="tel"
                        name="phone"
                        value={contact.phone}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={styles.formGroup}>
                      <label style={styles.label}>Designation</label>
                      <select
                        style={styles.select}
                        name="designation"
                        value={contact.designation}
                        onChange={(e) => handleInputChange(e, index)}
                      >
                        <option value="">Select Designation</option>
                        <option value="Site Admin">Site Admin</option>
                        <option value="Site Manager">Site Manager</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button onClick={() => handleDeleteContact(contact.id)} style={{
                  color: 'red', border: 'none', background: 'transparent', cursor: 'pointer', border: '1px solid red', borderRadius: '4px', padding: '5px',
                }}>
                  Delete
                </button>

              </div>
            ))}
            <div style={styles.buttonContainer}>
              <button style={styles.button} onClick={onPrevious}>
                Previous
              </button>
              <button style={styles.button} onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContact;
const styles = {
  container: {
    backgroundColor: '#f0f8ff',
  },
  title: {
    fontSize: '32px',
    color: '#0050A4',
    marginBottom: '20px',
  },
  header: {
    backgroundColor: '#0050A4',
    color: 'white',
    padding: '10px',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    backgroundColor: '#E2EDF9',
    padding: '20px',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#0050A4',
  },
  input: {
    width: '100%',
    padding: '8px',
    border: '1px solid #0050A4',
    color: '#0050A4',
    borderRadius: '4px',
  },
  select: {
    width: '100%',
    padding: '8px',
    border: '1px solid #0050A4',
    color: '#0050A4',
    borderRadius: '4px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#0050A4',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  addButton: {
    padding: '2px 10px',
    backgroundColor: 'white',
    color: '#1C1C1C',
    border: '1px solid white',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  deleteIcon: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
};