import React, { useEffect, useState } from 'react';
import config from "../../../config/config.json"
import { Navigate } from 'react-router-dom';
import { apiCall } from '../../../services/ApiCall';

const ViewSiteDetailsPage = ({ status, viewData }) => {
  const [siteData, setSiteData] = useState({});
  const [siteUserData, setSiteUserData] = useState([]);

  const getCompanyContact = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesContact`,
      {},
      { siteId: viewData.id },
      "GET"
    );
    if (isSuccess) {
      setSiteUserData(data?.data?.sitesUserData);
      setSiteData(data?.data?.sitesData);
      console.log(data?.data?.sitesData)
    }
    else {
      console.log("error")
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      Navigate("/");
    } else {
      getCompanyContact();
    }
  }, []);

  return (
    <div style={{ margin: '20px', borderRadius: '8px', backgroundColor: 'white' }}>
      <div style={{ marginTop: '20px', backgroundColor: '#0050A4', padding: '15px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ margin: 0, fontSize: '20px', fontWeight: '400' }}>Site Details</span>
      </div>
      <div style={{ padding: '20px', backgroundColor: '#e9edf5', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px', color: '#0050A4' }}>
          <div style={{ flex: '0 1 18%' }}>
            <strong>ARA ID</strong><br />
            {siteData?.araId}
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Name</strong><br />
            {siteData?.companyName}
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Street</strong><br />
            {siteData?.address}

          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Street 2</strong><br />
            {siteData?.street2}

          </div>

        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px', color: '#0050A4' }} className='pt-4'>
          <div style={{ flex: '0 1 18%' }}>
            <strong>City</strong><br />
            {siteData?.city}
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>State</strong><br />
            {siteData?.state}
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Zipcode</strong><br />
            {siteData?.zipcode}
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Status</strong><br />
            <span style={{ color: status === 'Active' ? '#48AB57' : '#48AB57', padding: '5px 10px', borderRadius: '10px' }}>
              {siteData?.status === 0 ? 'Active' : 'Inactive'}
              <span>   </span>
            </span>
          </div>
          <div style={{ flex: '0 1 18%' }}>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '20px', backgroundColor: '#0050A4', padding: '15px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', color: 'white', display: 'flex', justifyContent: 'space-between' }} className='mt-5'>
        <span style={{ margin: 0, fontSize: '20px', fontWeight: '400' }}>Site Contact</span>
      </div>

      <div style={{ padding: '20px 20px 4px 20px', backgroundColor: '#e9edf5', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
        {siteUserData && siteUserData.length > 0 ? (
          siteUserData.map(user => (
            <div key={user.id} style={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '15px',
              marginBottom: '15px',
              backgroundColor: '#fff'
            }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '10px', color: '#0050A4' }}>
                <div style={{ flex: '0 1 18%' }}>
                  <strong>Name</strong><br />
                  <span>{user.name}</span>
                </div>
                <div style={{ flex: '0 1 18%' }}>
                  <strong>Phone</strong><br />
                  <span>{user.phone}</span>
                </div>
                <div style={{ flex: '0 1 18%' }}>
                  <strong>DESIGNATION</strong><br />
                  <span>{user.designation}</span>
                </div>
                <div style={{ flex: '0 1 18%' }}>
                  <strong>EMAIL</strong><br />
                  <span>{user.email}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ textAlign: 'center', color: '#0050A4' }}>No contact added</div>
        )}
      </div>

    </div>
  );
};

export default ViewSiteDetailsPage;
