import React, { useState } from 'react';
import './main.css';
import AddSite from './AddSite';
import { useNavigate } from 'react-router-dom';

import viewIcon from '../img/viewIcon.png';
import editIcon from '../img/editIcon.png';
import archiveIcon from '../img/archiveIcon.png';
import filterIcon from '../img/filtericon.png';

const ViewRequest = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState('main');
  const [data, setData] = useState([
    { id: 'po12op12355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-2VP-1', city: 'Arizona', state: 'Arizona', RecheckCycle: '1589888', status: 'New Request' },
    { id: 'mnjk34545588', company: '1st Choice Recovery Llc', address: '31531 Justice Pakela St Farm Rd.', city: 'South Carolina', state: 'South Carolina', RecheckCycle: '1589888', status: 'In-Progress' },
    { id: 'irexjay', company: '59 Second Recovery LLC', address: '31531 Pakela St Justice Farm Rd.', city: 'Arizona', state: 'Washington', RecheckCycle: '1589888', status: 'To-Be-Approved' },
    { id: 'AVzxQYI', company: 'A & A Towing and Recovery MO', address: '31531 Pakela St Justice Farm Rd.', city: 'Arizona', state: 'Minnesota', RecheckCycle: '1589888', status: 'Approved' },
    { id: 'AVzxQTI', company: 'A & A Towing and Recovery MO', address: '31531 Pakela St Justice Farm Rd.', city: 'Arizona', state: 'Minnesota', RecheckCycle: '1589888', status: 'New Request' },
    { id: 'AVzxQWI', company: 'A & A Towing and Recovery MO', address: '31531 Pakela St Justice Farm Rd.', city: 'Arizona', state: 'Minnesota', RecheckCycle: '1589888', status: 'Approved' },
  ]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const handleAddSite = () => {
    setPage('addSite');
  };

  const handleViewClick = (id) => {
    // navigate(`/view-request`);
  };

  const handleViewIconClick = (id) => {
    navigate(`/view`);  // Navigate to a view page for the specific id
  };

  const handleArchiveClick = (id) => {
    setData(data.filter(row => row.id !== id));
  };

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...data].sort((a, b) => {
      if (key === '#') {
        return direction === 'ascending' ? a.sequence - b.sequence : b.sequence - a.sequence;
      } else {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
    });

    setSortConfig({ key, direction });
    setData(sortedData);
  };

  const numberedData = data.map((row, index) => ({ ...row, sequence: index + 1 }));

  const filteredData = numberedData.filter(row => {
    return Object.values(row).some(value =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleEntriesChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderSortIcons = (key) => {
    if (sortConfig.key !== key) {
      return '▲▼';
    }
    return sortConfig.direction === 'ascending' ? '▲' : '▼';
  };

  if (page === 'addSite') {
    return <AddSite />;
  }

  return (
    <div className="container mt-1">
      <div className="controls row">
        <div className="show-entries col-lg-2">
          <label>Show </label>
          <select value={entriesPerPage} className="form-control" onChange={handleEntriesChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
          <label> entries</label>
        </div>
        <div className="search-box col-lg-8 text-center">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="col-lg-2 text-end">
          <img className="me-4" src={filterIcon} alt="Filter" />
          <button className="add-site-button btn btn-primary" onClick={handleAddSite} style={{backgroundColor:'#F7921E',color:'white',fontSize:'12px',padding:'8px 12px',border:'none'}} >+ Add Site</button>
        </div>
      </div>

      <table className="company-table mt-5" style={{fontSize:'14px'}}>
      <div className='company-table-boxing'>
        <thead>
          <tr>
            <th style={{ width: '5%' }}>#</th>
            <th style={{ width: '15%' }} onClick={() => sortData('id')}>ARA ID {renderSortIcons('id')}</th>
            <th style={{ width: '18%' }} onClick={() => sortData('address')}>Address {renderSortIcons('address')}</th>
            <th style={{ width: '12%' }} onClick={() => sortData('city')}>City {renderSortIcons('city')}</th>
            <th style={{ width: '15%' }} onClick={() => sortData('state')}>State {renderSortIcons('state')}</th>
            <th style={{ width: '12%' }} onClick={() => sortData('Zipcode')}>Zipcode {renderSortIcons('Zipcode')}</th>
            <th style={{ width: '20%' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries.map((row, index) => (
            <tr key={index}>
              <td>{indexOfFirstEntry + index + 1}</td>
              <td>{row.id}</td>
              <td>{row.address}</td>
              <td>{row.city}</td>
              <td>{row.state}</td>
              <td>{row.RecheckCycle}</td>
              <td className="Action">
               
                <span className="viewIcon me-2" onClick={() => handleViewIconClick(row.id)} >
                  <img src={viewIcon} alt="View"  />
                </span>
          
                <span className="editIcon me-2" >
                  <img src={editIcon} alt="Edit" />
                </span>
               
                <span className="archiveIcon me-3" onClick={() => handleArchiveClick(row.id)}>
                  <img src={archiveIcon} alt="Archive" />
                </span>
                <button
                  onClick={() => handleViewClick(row.id)}
                  style={{ fontSize: '12px', color: 'white', backgroundColor: '#0050A4', padding: '8px 12px', borderRadius: '8px' , border:'none'}}
                >
                  View Request
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        </div>
      </table>

      <div className="pagination">
        <ul>
          <li onClick={handlePreviousPage} className={currentPage === 1 ? 'disabled' : ''}>Previous</li>
          {[...Array(totalPages)].map((_, i) => (
            <li
              key={i}
              className={currentPage === i + 1 ? 'active' : ''}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </li>
          ))}
          <li onClick={handleNextPage} className={currentPage === totalPages ? 'disabled' : ''}>Next</li>
        </ul>
      </div>
    </div>
  );
};

export default ViewRequest;
