import React, { useEffect, useState } from "react";
import "./style.css";
import ChangePasswordModal from "./ChangePasswordModal";
import { apiCall } from "../../services/ApiCall";
import config from "../../config/config.json";
import { MdEdit } from "react-icons/md";
import { FaSave } from "react-icons/fa";

function Profile() {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [profileData, setProfileData] = useState('');
  const [editingField, setEditingField] = useState(null);

  const handleChangePassword = () => {
    setShowPasswordModal(true);
  };

  const handleCloseModal = () => {
    setShowPasswordModal(false);
  };

  const handleEditClick = (field) => {
    setEditingField(field);
  };

  const handleSaveClick = () => {
    // Logic to save the edited data
    setEditingField(null);
  };

  const getProfileData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getProfileData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      setProfileData(data.data);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-image"></div>
        <div className="profile-info">
          <h2>{profileData?.name}</h2>
          <div className="namep">My Site Inspection</div>
          <p>{profileData?.email}</p>
        </div>
        <button className="change-password" onClick={handleChangePassword}>
          Change Password
        </button>
      </div>
      <form className="profile-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <div className="input-container">
            <input
              type="text"
              id="name"
              value={profileData?.name}
              readOnly={editingField !== "name"}
              onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
            />
            {editingField === "name" ? (
              <FaSave className="icon" onClick={handleSaveClick} />
            ) : (
              <MdEdit className="icon" onClick={() => handleEditClick("name")} />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <div className="input-container">
            <input
              type="email"
              id="email"
              value={profileData?.email}
              readOnly={editingField !== "email"}
              onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
            />
            {editingField === "email" ? (
              <FaSave className="icon" onClick={handleSaveClick} />
            ) : (
              <MdEdit className="icon" onClick={() => handleEditClick("email")} />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <div className="input-container">
            <input
              type="tel"
              id="phone"
              value={profileData?.phone}
              readOnly={editingField !== "phone"}
              onChange={(e) => setProfileData({ ...profileData, phone: e.target.value })}
            />
            {editingField === "phone" ? (
              <FaSave className="icon" onClick={handleSaveClick} />
            ) : (
              <MdEdit className="icon" onClick={() => handleEditClick("phone")} />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="company">Company Name</label>
          <div className="input-container">
            <input
              type="text"
              id="company"
              placeholder="My Site Inspection"
              readOnly={editingField !== "company"}
              onChange={(e) => setProfileData({ ...profileData, company: e.target.value })}
            />
            {editingField === "company" ? (
              <FaSave className="icon" onClick={handleSaveClick} />
            ) : (
              <MdEdit className="icon" style={{color:'#0050A4'}} onClick={() => handleEditClick("company")} />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="address">Company Headquarters Address</label>
          <div className="input-container">
            <input
              type="text"
              id="address"
              placeholder="Enter Address"
              readOnly={editingField !== "address"}
              onChange={(e) => setProfileData({ ...profileData, address: e.target.value })}
            />
            {editingField === "address" ? (
              <FaSave className="icon" onClick={handleSaveClick} />
            ) : (
              <MdEdit className="icon" onClick={() => handleEditClick("address")} />
            )}
          </div>
        </div>
      </form>
      {showPasswordModal && <ChangePasswordModal onClose={handleCloseModal} />}
    </div>
  );
}

export default Profile;

















/*  
import React, { useEffect, useState } from "react";
import "./style.css";
import ChangePasswordModal from "./ChangePasswordModal";
import { apiCall } from "../../services/ApiCall";
import config from "../../config/config.json";
import { MdEdit } from "react-icons/md";
import { FaSave } from "react-icons/fa";

function Profile() {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [profileData, setProfileData] = useState('');
  const [editingField, setEditingField] = useState(null);

  const handleChangePassword = () => {
    setShowPasswordModal(true);
  };

  const handleCloseModal = () => {
    setShowPasswordModal(false);
  };

  const handleEditClick = (field) => {
    setEditingField(field);
  };

  const handleInputChange = (field, value) => {
    setProfileData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSaveClick = async () => {
    // Save the updated data to the backend
    const { isSuccess } = await apiCall(
      `${config.POST_LOGIN_API_URL}updateProfileData`,
      profileData,
      {},
      "POST"
    );

    if (isSuccess) {
      setEditingField(null);
    } else {
      alert("Failed to save the changes. Please try again.");
    }
  };

  const getProfileData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getProfileData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      setProfileData(data.data);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-image"></div>
        <div className="profile-info">
          <h2>{profileData?.name}</h2>
          <div className="namep">My Site Inspection</div>
          <p>{profileData?.email}</p>
        </div>
        <button className="change-password" onClick={handleChangePassword}>
          Change Password
        </button>
      </div>
      <form className="profile-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <div className="input-container">
            <input
              type="text"
              id="name"
              value={profileData?.name}
              readOnly={editingField !== "name"}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            {editingField === "name" ? (
              <FaSave className="icon" onClick={handleSaveClick} />
            ) : (
              <MdEdit className="icon" onClick={() => handleEditClick("name")} />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <div className="input-container">
            <input
              type="email"
              id="email"
              value={profileData?.email}
              readOnly={editingField !== "email"}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
            {editingField === "email" ? (
              <FaSave className="icon" onClick={handleSaveClick} />
            ) : (
              <MdEdit className="icon" onClick={() => handleEditClick("email")} />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <div className="input-container">
            <input
              type="tel"
              id="phone"
              value={profileData?.phone}
              readOnly={editingField !== "phone"}
              onChange={(e) => handleInputChange("phone", e.target.value)}
            />
            {editingField === "phone" ? (
              <FaSave className="icon" onClick={handleSaveClick} />
            ) : (
              <MdEdit className="icon" onClick={() => handleEditClick("phone")} />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="company">Company Name</label>
          <div className="input-container">
            <input
              type="text"
              id="company"
              value={profileData?.company || ''}
              placeholder="My Site Inspection"
              readOnly={editingField !== "company"}
              onChange={(e) => handleInputChange("company", e.target.value)}
            />
            {editingField === "company" ? (
              <FaSave className="icon" onClick={handleSaveClick} />
            ) : (
              <MdEdit className="icon" onClick={() => handleEditClick("company")} />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="address">Company Headquarters Address</label>
          <div className="input-container">
            <input
              type="text"
              id="address"
              value={profileData?.address || ''}
              placeholder="Enter Address"
              readOnly={editingField !== "address"}
              onChange={(e) => handleInputChange("address", e.target.value)}
            />
            {editingField === "address" ? (
              <FaSave className="icon" onClick={handleSaveClick} />
            ) : (
              <MdEdit className="icon" onClick={() => handleEditClick("address")} />
            )}
          </div>
        </div>
      </form>
      {showPasswordModal && <ChangePasswordModal onClose={handleCloseModal} />}
    </div>
  );
}

export default Profile;

*/