import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon from './img/exportIcon.png';

const AddRequestForm = ({ onAddRequest }) => {
  const [formData, setFormData] = useState({
    scheduledFor: null,
    company: "",
    site: "",
    mainContact: "",
    name: "",
    emailId: "",
    phoneNumber: "",
    sendEmailNotification: true,
  });

  const toggleEmailNotification = () => {
    setFormData({ ...formData, sendEmailNotification: !formData.sendEmailNotification });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, scheduledFor: date });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddRequest({ ...formData, id: Date.now() });
    setFormData({
      scheduledFor: null,
      company: "",
      site: "",
      mainContact: "",
      name: "",
      emailId: "",
      phoneNumber: "",
      sendEmailNotification: true,
    });
  };

  return (
    <div className="container my-5 ">
      <div className=" row mb-4">
        <div className="col-md-6">
          <h1 className="blueColor fs-32">Add Request</h1>
        </div>
        <div className="col-md-6 text-end">
          <button type="button" className="bg-white blueColor border-blue  btn-1 border-radius-5">
           <span className="imgicon"> <img src={icon}/> </span>
           <span> Export</span>
          </button>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header bg-blue text-white fs-20">Request Information</div>
        <div className="card-body">
          <div className="row blueColor">
            <div className="col-md-4">
              <div className="form-group mb-4">
                <label className="mb-2 d-block fs-20">Scheduled For</label>
                <DatePicker
                  name="scheduledFor"
                  selected={formData.scheduledFor}
                  onChange={handleDateChange}
                  className="form-control w-100"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header bg-blue text-white fs-20">Location</div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row blueColor">
              <div className="col-md-4">
                <div className="form-group mb-4">
                  <label className="mb-2 fs-20 ">Company:</label>
                  <select
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    className="form-control blueColor"
                    required
                  >
                    <option value="" className="blueColor fs-20">Select Company</option>
                    <option value="Ghrix">Ghrix</option>
                    <option value="Ghrix2">Ghrix2</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 fs-20">
                <div className="form-group mb-4">
                  <label className="mb-2">Site:</label>
                  <select
                    name="site"
                    value={formData.site}
                    onChange={handleChange}
                    className="form-control"
                    required
                  >
                    <option value="" className="fs-20">Select Site</option>
                    <option value="685 SW Steedley Dr Lake City FL">685 SW Steedley Dr Lake City FL</option>
                    <option value="685 SW Steedley Dr Lake City FL">685 SW Steedley Dr Lake City FL</option>
                   
                    {/* Add site options here */}
                  </select>
                </div>
              </div>
              <div className="col-md-4 fs-20">
                <div className="form-group mb-4">
                  <label className="mb-2">Select Main Contact:</label>
                  <select
                    name="mainContact"
                    value={formData.mainContact}
                    onChange={handleChange}
                    className="form-control"
                    required
                  >
                    <option value="" className="fs-20">Select Contact</option>
                    <option value="9034933812">9034933812</option>
                    <option value="9034933812">9034933812</option>

                    {/* Add contact options here */}
                  </select>
                </div>
              </div>
            </div>
            <div className="row blueColor">
              <div className="col-md-4">
                <div className="form-group mb-4">
                  <label className="mb-2 fs-20">Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-4">
                  <label className="mb-2 fs-20">Email Id:</label>
                  <input
                    type="email"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-4">
                  <label className="mb-2 fs-20">Phone Number:</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
              </div>
            </div>


            
          
             
           
          </form>
        </div>
      </div>
      <div className="form-group">
              <div className="custom-control custom-switch">
               
                <div className="row">
                  <div className="col">
                  <div className="form-check form-switch fs-20 py-2">
  <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
  <label className="form-check-label  custom-control-label blueColor " for="flexSwitchCheckChecked">   Don't Send Email Notification</label>
</div>
                 
                  </div>
                  <div className="col text-end">
                  <button type="submit" className="btn btn-primary  bg-blue btn-2 fs-20">
                <span className="bi bi-plus fs-20">  Save</span>
              </button>
                  </div>
                </div>
               
              </div>
            </div>            
          
    </div>

    
  );
};

export default AddRequestForm;











// 


// import React, { useState } from 'react';

// const AddInspectionModal = ({ onAddInspection }) => {
//   const [formData, setFormData] = useState({
//     id: '',
//     araId: '',
//     company: '',
//     site: '',
//     approvalDate: '',
//     nextInspectionDate: '',
//     contactNumber: '',
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onAddInspection({ ...formData, id: Date.now() });
//     setFormData({
//       id: '',
//       araId: '',
//       company: '',
//       site: '',
//       approvalDate: '',
//       nextInspectionDate: '',
//       contactNumber: '',
//     });
//   };

//   return (
//     <div>
//       <h2>Add New Inspection</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>ID:</label>
//           <input
//             type="text"
//             name="id"
//             value={formData.id}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>ARA ID:</label>
//           <input
//             type="text"
//             name="araId"
//             value={formData.araId}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Company:</label>
//           <input
//             type="text"
//             name="company"
//             value={formData.company}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Site:</label>
//           <input
//             type="text"
//             name="site"
//             value={formData.site}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Approval Date:</label>
//           <input
//             type="date"
//             name="approvalDate"
//             value={formData.approvalDate}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Next Inspection Date:</label>
//           <input
//             type="date"
//             name="nextInspectionDate"
//             value={formData.nextInspectionDate}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Contact Number:</label>
//           <input
//             type="text"
//             name="contactNumber"
//             value={formData.contactNumber}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <button type="submit">Add Inspection</button>
//       </form>
//     </div>
//   );
// };

// export default AddInspectionModal;
// 
