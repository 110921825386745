import { useRoutes } from "react-router-dom";

import routes from "./Router";

const App = () => {
  const { ThemeRoutes } = routes;
  const routing = useRoutes(ThemeRoutes);
  

  return <div className="dark">
  
    {routing}
    </div>;
};

export default App;
