// AddDesignationForm.js
// import React, { useState, useEffect } from 'react';
// import './AddDesignationForm.css';
// import img from './img/Close.png';

// const AddDesignationForm = ({ onAddDesignation, onClose, editMode = false, designationName = '', setDesignationName }) => {
//   const [name, setName] = useState(designationName);

//   useEffect(() => {
//     if (editMode) {
//       setName(designationName);
//     }
//   }, [editMode, designationName]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onAddDesignation(name);
//   };

//   return (
//     <div className="add-designation-modal">
//       <div className="add-designation-content">
//         <div className="add-designation-header">
//           <h3>{editMode ? 'Edit Designation' : 'Add Designation'}</h3>
//           <span className='Add-designation-close-btn'> <img src={img} alt='' onClick={onClose}/></span>
//         </div>
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//           <span>Designation Name</span>
//             <input
//               type="text"
//               id="designationName"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               placeholder="Enter Designation Name"
//             />
//           </div>
//           <div className="form-actions">
//             <button className="clear-btn" type="button" onClick={() => setName('')}>Clear</button>
         
//             <button type="submit" className="add-btn">{editMode ? 'Save' : 'Add'}</button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AddDesignationForm;

// AddDesignationForm.js
import React, { useState, useEffect } from 'react';
import './AddDesignationForm.css';
import img from './img/Close.png';

const AddDesignationForm = ({ onAddDesignation, onClose, editMode = false, designationName = '', setDesignationName }) => {
  const [name, setName] = useState(designationName);

  useEffect(() => {
    if (editMode) {
      setName(designationName);
    }
  }, [editMode, designationName]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddDesignation(name);
  };

  return (
    <div className="add-designation-modal">
      <div className="add-designation-content">
        <div className="add-designation-header">
       
           <h3 style={{backgroundColor:'white'}}>{editMode ? 'Edit Designation' : 'Add Designation'}</h3>
           <span className='Add-designation-close-btn'> <img src={img} alt='' onClick={onClose}/></span>
         
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="designationName">Designation Name</label>
            <input
              type="text"
              id="designationName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Designation Name"
            />
          </div>
          <div className="form-actions">
          <button className="clear-btn" type="button" onClick={() => setName('')}>Clear</button>
         
            <button type="submit" className="add-btn">{editMode ? 'Save' : 'Add'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDesignationForm;
