import React from 'react';
import editIcon from '../img/editIcon.png';
import deleteIcon from '../img/deleteIcon.png';
import exportIcon from'../img/exportIcon.png';
import { NavLink } from 'react-router-dom'; // Import NavLink

const ViewPage = ({ id, company, address, city, state, zipcode, status, onEdit }) => {
  return (
    <div style={{ margin: '20px' , borderRadius: '8px', backgroundColor: 'white' }}>
      <div style={{  display: 'flex', justifyContent: 'space-between', backgroundColor: 'white',marginBottom:'2em' }}>
        <div style={{ color: '#0050A4', fontSize:'32px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
        Site Details
        </div>

        <div style={{fontSize:'16px', borderRadius:'5px'}}>
          <button style={{marginRight:'20px',color:'white',backgroundColor:'#0050A4',padding:'8px 18px', border:'1px solid #0050A4  '}}>History</button>
          <button style={{color:'#0050A4',backgroundColor:'white',padding:'5px 15px',border:'1px solid #0050A4',fontWeight:'600'}}> <span style={{}}><img  src={exportIcon}/> </span>Export</button>
        </div>
      
      </div>
      {/* Top Row with Add Company Button and Search Bar */}
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'white',marginBottom:'3em' }}>
      <NavLink to="/add-site" style={{ textDecoration: 'none' }}>
          <button style={{ backgroundColor: '#0050A4', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
            + Add Site
          </button>
        </NavLink>
       <input
          type="text"
          placeholder="Search..."
          style={{ padding: '10px', borderRadius: '10px', border: '1px solid #0050A4',  maxWidth: '1000px',width: '70%', float: 'right'  }}
        />
      </div>

      {/* Company Details Section with 20px margin on top */}
      <div style={{ marginTop: '20px', backgroundColor: '#0050A4', padding: '15px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ margin: 0, fontSize: '20px', fontWeight: '400' }}>Site Details</span>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button
            style={{ backgroundColor: '#0050A4', border: 'none', color: 'white', cursor: 'pointer' }}
            onClick={onEdit}
          >
            <img style={{ background: '#E6F1F7', border: '1px solid #E6F1F7', borderRadius: '50%', padding: '8px' }} src={editIcon} alt="Edit" />
          </button>
          <button style={{ backgroundColor: '#0050A4', border: 'none', color: 'white', cursor: 'pointer' }}>
            <img src={deleteIcon} alt="Delete" />
          </button>
        </div>
      </div>

      <div style={{ padding: '20px', backgroundColor: '#e9edf5', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px', color: '#0050A4' }}>
          <div style={{ flex: '0 1 18%' }}>
            <strong>ARA ID</strong><br />
            <span>AVobAuu</span>
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Company</strong><br />
            <span>Ghrix</span>
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Address </strong><br />
            <span>280 Thorpe Street</span>
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Address 2</strong><br />
            <span>280 Thorpe Street</span>
            {/* Placeholder for Street 2 */}
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>State</strong><br />
         <span>South Carolina</span>
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px', color: '#0050A4' }} className='pt-4'>
          <div style={{ flex: '0 1 18%' }}>
            <strong>City</strong><br />
          <span>Summerville</span>
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Zipcode</strong><br />
           <span>1589888</span>
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Recheck Cycle</strong><br />
            {/* Placeholder for Office Type */}
            Head Office
          </div>
          {/* <div style={{ flex: '0 1 18%' }}>
            <strong>Status</strong><br />
            <span style={{ backgroundColor: status === 'Active' ? '#48AB57' : '#48AB57', padding: '5px 10px', color: 'white', borderRadius: '10px' }}>
              <span>Active</span>
            </span>
          </div> */}
          <div style={{ flex: '0 1 18%' }}>
            {/* Blank space for alignment purposes */}
          </div>
        </div>
      </div>



 {/* second table */}





 <div style={{ marginTop: '20px', backgroundColor: '#0050A4', padding: '15px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', color: 'white', display: 'flex', justifyContent: 'space-between' }} className='mt-5'>
        <span style={{ margin: 0, fontSize: '20px', fontWeight: '400' }}>Site Contact</span>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button style={{backgroundColor:'#E6F1F7', color:'#0050A4'}} className='ps-2 pe-2'>+ Add New Contact </button>
          <button
            style={{ backgroundColor: '#0050A4', border: 'none', color: 'white', cursor: 'pointer' }}
            onClick={onEdit}
          >
            <img style={{ background: '#E6F1F7', border: '1px solid #E6F1F7', borderRadius: '50%', padding: '8px' }} src={editIcon} alt="Edit" />
          </button>
          <button style={{ backgroundColor: '#0050A4', border: 'none', color: 'white', cursor: 'pointer' }}>
            <img src={deleteIcon} alt="Delete" />
          </button>
        </div>
      </div>

      <div style={{ padding: '20px', backgroundColor: '#e9edf5', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px', color: '#0050A4' }}>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Name</strong><br />
            <span>Archie Bizmark </span>
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Phone</strong><br />
           <span>8438514377</span>
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>DESIGNATION</strong><br />
            <span>Site Admin</span>
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>EMAIL</strong><br />
           <span>1stchoice@recoveryagents.net</span>
          </div>
         
        </div>
     
      </div>

    </div>
  );
};

export default ViewPage;
