import React, { useState, useEffect } from 'react';
import { Table, Pagination, FormControl, InputGroup } from 'react-bootstrap';
import data from './data.json';

const Request = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filterText, setFilterText] = useState('');

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.filter(item =>
    JSON.stringify(item).toLowerCase().includes(filterText.toLowerCase())
  ).slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = data?.length ? Math.ceil(data.length / itemsPerPage) : 0;

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterText, sortColumn, sortDirection]);

  return (
    <div>
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Filter data..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </InputGroup>
      {data.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              {data[0] && Object.keys(data[0]).map((key, index) => (
                <th key={index} onClick={() => handleSort(key)}>
                  {key}{' '}
                  {sortColumn === key && (
                    <i className={`bi bi-caret-${sortDirection}`}></i>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                {Object.values(item).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data available.</p>
      )}
      <Pagination>
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </div>
  );
};

export default Request;