import React from 'react';
import "./companystyle.css";
import companyicon from './img/companyicon.png';
import exporticon from './img/export icon.png';
import { useState } from 'react';
import MainContactForm from './MainContactForm.js';
import CompanyIconDetails from './CompanyIconDetails.js';
import deleteicon from './img/deleteicon.png';
const AddCompanyForm = ({ onMainContact }) => {
  return (<>
    <div className="form-header">
    <h2>Add Company</h2>
    <button className="export-button">
      <img src={exporticon} alt="Export"/> Export
    </button>
  </div>
  <div className="form-tabs">
  <button className="active">Company Details</button>
  <button onClick={onMainContact}>Main Contact</button>
      </div>
     
    <div className="add-company-form">
   
    <div className="companyDetail">
     <div>   <h3>Company Details </h3></div>
        
       <div className='companyicons' > 
          <img src={deleteicon} alt='deleteicon'/>
        </div>

      </div>
    
    <div className='form'>
         
      <div className="form-row">
          <div className="form-group">
            <label>ARA ID</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Company Name</label>
            <input type="email" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Street</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Street 2</label>
            <input type="email" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>City</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>State</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Zipcode</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Type</label>
            <input type="text" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Lender Name</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Lender Email</label>
            <input type="email" />
          </div>
        </div>
        <button type="submit" className="next-button">Next</button>
        </div>
    </div>
    </>
  );
};

const CompanyDetails = ({ company, onClose }) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showMainContact, setShowMainContact] = useState(false);
  const [showIconDetails, setShowIconDetails] = useState(false);

  const toggleAddForm = () => {
    setShowAddForm(!showAddForm);
    setShowMainContact(false);
  };

  const showMainContactForm = () => {
    setShowMainContact(true);
  };

  const showCompanyDetailsForm = () => {
    setShowMainContact(false);
  };
  const toggleIconDetails = () => {
    setShowIconDetails(!showIconDetails);
    setShowAddForm(false);
    setShowMainContact(false);
  };

 if (showIconDetails) {
    return <CompanyIconDetails onClose={() => setShowIconDetails(false)} />;
  }

  if (showAddForm) {
    if (showMainContact) {
      return <MainContactForm onPrevious={showCompanyDetailsForm} />;
    }
    return <AddCompanyForm onMainContact={showMainContactForm} />;
  }
  

  return (
    <div className ='company-details'>
      <div className='company-details-header'>
        <h2>Company Details</h2>
        {/* <div>
          <button className="history-button">History</button>
          <button className="export-button">
           <img src={exporticon}/> Export</button>
        </div> */}
      </div>
      <div className='search-and-add'>
      
      <button className="add-company-button" onClick={toggleAddForm}>+ Add Company</button>
        <input
          type="text"
          placeholder="Search by state, city, zipcode, name, etc..."
          className="search-input"
        />
      </div>


      <div className="companyDetail">
     <div>   <h3>Company Details </h3></div>
        
       <div className='companyicons' onClick={toggleIconDetails}> 
          <img src={companyicon} alt='companyicon'/>
        </div>

      </div>

      <div className="company-info">
       
        <div className="info-grid">
          <div>
            <label>ARA ID1222</label>
            <p>{company.araId}</p>
          </div>
          <div>
            <label>Name</label>
            <p>{company.company}</p>
          </div>
          <div>
            <label>Street</label>
            <p>{company.street}</p>
          </div>
          <div>
            <label>Street 2</label>
            <p>{company.street2 || 'N/A'}</p>
          </div>
          <div>
            <label>State</label>
            <p>{company.state}</p>
          </div>
          <div>
            <label>City</label>
            <p>{company.city}</p>
          </div>
          <div>
            <label>Zipcode</label>
            <p>{company.zipcode}</p>
          </div>
          <div>
            <label>Office Type</label>
            <p>{company.officeType || 'Head Office'}</p>
          </div>
          <div>
            <label>Status</label>
            <p><span className="status-badge active">{company.Status}</span></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;