import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import exportIcon from './img/exportIcon.png';
import deleteIcon from './img/deleteIcon.png';
import SiteContact from './EditSiteContact.js'; // Import the new component

const EditSite = () => {
  const [formData, setFormData] = useState({
    araId: 'WAVYmqNk',
    company: 'Ghrix',
    address: '685 SW Steedley Dr Lake City FL',
    address2: '',
    city: 'london',
    state: 'UK',
    zipcode: '231001',
    recheckCycle: '52515',
  });

  const [showNextPage, setShowNextPage] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, if needed
    console.log('Form submitted with data:', formData);
  };

  const handleNextClick = () => {
    setShowNextPage(true);
  };

  const handlePreviousClick = () => {
    setShowNextPage(false);
  };

  const styles = {
    container: {
      padding: '20px',
      position: 'relative',
    },
    title: {
      fontSize: '32px',
      color: '#0050A4',
      marginBottom: '20px',
    },
    exportButton: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      padding: '5px 20px',
      backgroundColor: '#fff',
      fontSize: '16px',
      border: '1px solid #0050A4',
      borderRadius: '5px',
      color: '#0050A4',
      cursor: 'pointer',
    },
    companyDetails: {
      backgroundColor: '#E2EDF9',
      padding: '20px',
      marginTop: '0px',
      borderRadius: '5px',
    },
    companyDetailsTitle: {
      backgroundColor: '#0050A4',
      fontSize: '20px',
      color: 'white',
      marginBottom: '15px',
    },
    formGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '20px',
      gridRowGap: '20px',
      marginTop: '0px',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      marginBottom: '5px',
      color: '#0050A4',
    },
    input: {
      padding: '10px',
      border: '1px solid #0050A4',
      borderRadius: '4px',
      color: '#0050A4',
    },
    nextButton: {
      marginTop: '20px',
      padding: '10px 22px',
      backgroundColor: '#0050A4',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    fullcontainer: {
      backgroundColor: '#E2EDF9',
    },
    rowGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '20px',
      gridRowGap: '20px',
    },
    lenderRowGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '20px',
      gridRowGap: '20px',
    },
  };

  if (showNextPage) {
    return <SiteContact onPrevious={handlePreviousClick} />;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Edit</h1>
      <button style={styles.exportButton}><span><img src={exportIcon} className='me-1' alt="Export" /> </span> Export</button>

      <div className='mt-5'>
        <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#0050A4', paddingTop: '10px' }} className='mt-5 mb-0'>
          <div style={styles.companyDetailsTitle} className='ps-3 pt-1'>Site Details</div>
          <div style={{ padding: '0px', marginBottom: '15px', }} className='pe-2'><img src={deleteIcon} alt="Delete" /></div>
        </div>
        <div style={{ padding: '15px', backgroundColor: '#e7f0f8', borderRadius: '4px' }}>
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
              <div style={{ width: '48%' }}>
                <label style={styles.label}>ARA ID</label>
                <input
                  type="text"
                  name="araId"
                  value={formData.araId}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
              <div style={{ width: '48%' }}>
                <label style={styles.label}>Company</label>
                <input
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
            </div>
            <div style={{ display: 'flex', color: '#0050A4', justifyContent: 'space-between', marginBottom: '15px' }}>
              <div style={{ width: '33%' }}>
                <label style={styles.label}>Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
              <div style={{ width: '33%' }}>
                <label style={styles.label}>Address 2</label>
                <input
                  type="text"
                  name="address2"
                  value={formData.address2}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
              <div style={{ width: '30%' }}>
                <label style={styles.label}>State</label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
            </div>
            <div style={{ display: 'flex', color: '#0050A4', justifyContent: 'space-between', marginBottom: '15px' }}>
              <div style={{ width: '24%' }}>
                <label style={styles.label}>City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
              <div style={{ width: '24%' }}>
                <label style={styles.label}>State</label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
              <div style={{ width: '24%' }}>
                <label style={styles.label}>Zipcode</label>
                <input
                  type="text"
                  name="zipcode"
                  value={formData.zipcode}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
              <div style={{ width: '24%' }}>
                <label style={styles.label}>Recheck Cycle</label>
                <input
                  type="text"
                  name="recheckCycle"
                  value={formData.recheckCycle}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
              <button type="button" onClick={handleNextClick} style={styles.nextButton}>Next</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditSite;
