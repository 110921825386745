import React, { useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const DataFormTab = ({ module, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleForm = () => {
    setIsOpen(!isOpen);
  };
  const getAnswerByKey = (answerKey) => {
    const result = answer && answer.find((item) => item.answerKey === answerKey);
    return result ? result.answer : null;
  };
  const styles = {
    formHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#e7f3ff',
      padding: '10px',
      borderRadius: '5px',
      marginBottom: '10px'
    },
    h2: {
      margin: 0,
      fontSize: '24px',
      color: '#0050A4',
      fontWeight: '600',
    },
    toggleButton: {
      backgroundColor: 'transparent',
      border: '1px solid #0050A4',
      //   padding:'4px',
      borderRadius: '8px',
      fontSize: '18px',
      cursor: 'pointer',

    },
    formContent: {
      //   backgroundColor: '#f9f9f9',
      padding: '35px',
      borderRadius: '10px',
      border: '1px solid rgba(0, 80, 164, 0.5)',
      color: 'rgba(0, 80, 164, 0.5)',
    },
    formGroup: {
      // padding:'20px',
      marginBottom: '20px'
    },
    label: {
      display: 'block',
      fontWeight: 'bold',
      marginBottom: '8px',
      fontSize: '16px'
    },

    inputRadioCheckbox: {
      width: '5%',
      marginRight: '8px',

    },
    photoUpload: {
      display: 'flex',
      gap: '20px'
    },
    photoUploadImg: {
      //   width: '100px',
      backgroundColor: 'rgba(191, 207, 223, 0.4)',

      //   height: '100px',
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '5px'
    }
  };

  return (
    <div>
      <div style={styles.formHeader}>
        <span style={styles.h2}>{module.moduleTitle}</span>
        <button onClick={toggleForm} style={styles.toggleButton}>
          {isOpen ? <i class="fa-solid fa-minus"></i> : <i class="fa-solid fa-plus"></i>}
        </button>
      </div>

      {isOpen && (
        <div style={styles.formContent}>
          {module && module.questions.map((question) => (
            <FormGroup key={question.id}>
              <Label style={{ color: "#0050A4", fontSize: "13px" }}>
                {question.id === 1 ? question.questionTitle : `${question.reportId}: ${question.questionTitle}`}
              </Label>

              {question.subQuestions.length === 0 ? (
                <Input
                  style={{
                    border: "1px solid #0050A4",
                    color: "#0050A4",
                  }}
                  className="me-4"
                  type="text"
                  name={`question-${question.id}`}
                  value={getAnswerByKey(question.reportId)}
                />
              ) : (
                question.subQuestions.map((subQuestion) => (
                  <FormGroup key={subQuestion.id}>
                    {subQuestion.optionType === "text" && (
                      <>
                        <Label>
                          <div style={{ color: "#0050A4", fontSize: "20px" }} className="mt-4">
                            {subQuestion.option}
                          </div>
                          <Input
                            style={{
                              border: "1px solid #0050A4",
                              color: "#0050A4",
                              width: "300px",
                            }}
                            className="mt-2"
                            type="text"
                            name={`subQuestion-${subQuestion.id}`}
                            value={getAnswerByKey(question?.reportId)}
                          />
                        </Label>
                      </>
                    )}

                    {subQuestion.optionType === "checkbox" && (
                      <>
                        <Label style={{ display: "flex", marginTop: "10px" }}>
                          <Input
                            type="checkbox"
                            name={`subQuestion-${subQuestion.id}`}
                            checked={getAnswerByKey(question?.reportId)
                              ?.split(",")
                              .includes(subQuestion.option.trim())}
                          />
                          <span style={{ fontSize: "13px", color: "#0050A4" }}>{subQuestion.option}</span>
                        </Label>
                      </>
                    )}

                    {subQuestion.optionType === "radio" && (
                      <>
                        <Label style={{ display: "flex", marginTop: "10px" }}>
                          <Input
                            type="radio"
                            name={`subQuestion-${subQuestion.id}`}
                            value={subQuestion.option}
                            checked={getAnswerByKey(question?.reportId) === subQuestion.option}
                          />
                          <span style={{ fontSize: "13px", color: "#0050A4" }}>{subQuestion.option}</span>
                        </Label>
                      </>
                    )}
                  </FormGroup>
                ))
              )}
            </FormGroup>
          ))}
        </div>
      )}
    </div>
  );
};

export default DataFormTab;
