import React, { useEffect, useState } from "react";
import exportIcon from "./img/exportIcon.png";
import { apiCall } from "../../services/ApiCall";
import { useNavigate } from "react-router-dom";
import config from "../../config/config.json";
import Select from "react-select"
import DatePicker from "react-datepicker";

const AddRequest = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [companyUserData, setCompanyUserData] = useState([]);
  const [siteData, SetSiteData] = useState([]);
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({ companyId: "", siteId: "" });

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [scheduledDate, setScheduledDate] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const validateCompany = (value) => (!value ? "Company is required" : "");
  const validateSite = (value) =>
    !value || !value.trim() ? "Site is required" : "";
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const getCompanyContact = async (companyId) => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyContact`,
      {},
      { companyId },
      "GET"
    );
    if (isSuccess) {
      const users = data?.data?.comapnyUserData || [];
      setCompanyUserData(users);
      if (users.length) {
        setSelectedContact(users[0]?.id);
        setContactDetails({
          name: users[0].name,
          email: users[0].email,
          phone: users[0].phone,
        });
      }
    }
  };

  const getSitesList = async (companyId) => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesList`,
      {},
      { companyId },
      "GET"
    );
    if (isSuccess) {
      SetSiteData(data?.data?.reverse());
      setSelectedSite(data?.data[0]?.id);
    }
  };
  const getCompanyList = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyList`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setCompanyData(data?.data?.reverse());
    }
  };

  const changeCompany = (selectedOption) => {
    getSitesList(selectedOption ? Number(selectedOption.value) : '');
    setSelectedCompany(selectedOption ? Number(selectedOption.value) : '');
    getCompanyContact(selectedOption ? Number(selectedOption.value) : '');
    const error = validateCompany(selectedOption ? Number(selectedOption.value) : '');
    setErrors((prevErrors) => ({ ...prevErrors, companyId: error }));
  };

  const changeSite = (e) => {
    const value = e.target.value;
    setSelectedSite(value);
    const error = validateSite(value);
    setErrors((prevErrors) => ({ ...prevErrors, companyId: error }));
  };

  const changeContact = (e) => {
    const value = e.target.value;
    setSelectedContact(value);
    const user = companyUserData.find(user => user.id === value);

        setContactDetails({
          name: user.name,
          email: user.email,
          phone: user.phone,
        });
  };

  const handleDateChange = (e) => {
    setScheduledDate(e.target.value);
  };

  const handleSave = async () => {
    if (
      !selectedCompany ||
      !selectedSite ||
      !selectedContact ||
      !scheduledDate
    ) {
      return;
    }
    setIsSaving(true);
    const payload = {
      companyId: Number(selectedCompany),
      siteId: Number(selectedSite),
      contactId: Number(selectedContact),
      date: scheduledDate,
    };

    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}addNewRequest`,
      {},
      payload,
      "POST"
    );

    if (isSuccess) {
      navigate("/new-request");
    }
    setIsSaving(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getCompanyList();
    }
  }, []);

  const companyOptions = companyData.map((company) => ({
    value: company.id,
    label: company.name
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '3px', // Increase border-radius
      marginTop: '-0px',
      borderColor: "1px solid navyblue"    // Reduce margin-top
    }),
  };

  return (
    <div style={styles.appContainer}>


      <div className="mb-4">
        <h3 style={{ color: "white", fontSize: "20px", padding: "15px" }}>
          Request Information
        </h3>
        <div style={styles.requestInfo}>
          <label className="mb-2">Due Date</label>
          <DatePicker
            selected={scheduledDate||null}
            onChange={(date) => setScheduledDate(date)}
            minDate={new Date()}
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            style={styles.datePicker}
          />
         
        </div>
      </div>

      <h3 style={styles.locationHeader}>Location</h3>

      <div style={styles.requestBox} className="mb-4">
        <div style={styles.locationBox}>
          <div style={styles.locationRow}>
            <div style={styles.locationColumn}>
              <label>Company</label>

              <Select
                onChange={changeCompany}
                options={companyOptions}

                placeholder="Select a company"
                isSearchable={true}
                styles={customStyles}
              />
            </div>
            <div style={styles.locationColumn}>
              <label>Site</label>
              <select onChange={changeSite} id="site" style={styles.input}>
                <option value="" disabled>
                  Select a site
                </option>
                {siteData &&
                  siteData.length &&
                  siteData.map((site) => (
                    <option key={site.id} value={site.id}>
                      {site.address + ' ' + site.city + ' ' + site.state}
                    </option>
                  ))}
              </select>
            </div>
            <div style={styles.locationColumn}>
              <label>Select Main Contact</label>
              <select onChange={changeContact} id="user" style={styles.input}>
                <option value="" disabled>
                  Select a contact
                </option>
                {companyUserData &&
                  companyUserData.length &&
                  companyUserData.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div style={styles.locationRow} className="mt-4">
            <div style={styles.locationColumn}>
              <label>Name</label>
              <input
                type="text"
                style={styles.input}
                value={contactDetails?.name}
              />
            </div>
            <div style={styles.locationColumn}>
              <label>Email Id</label>
              <input
                type="email"
                style={styles.input}
                value={contactDetails?.email}
              />
            </div>
            <div style={styles.locationColumn}>
              <label>Phone Number</label>
              <input
                type="tel"
                style={styles.input}
                value={contactDetails?.phone}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px",
        }}
      >
        {/* <div style={styles.notificationToggle}>
          <div
            onClick={handleToggle}
            style={{
              ...styles.toggleSwitch,
              backgroundColor: toggle ? "#4caf50" : "#A40031",
            }}
          >
            <div
              style={{
                ...styles.toggleHandle,
                left: toggle ? "27.5px" : "2.5px",
              }}
            ></div>
          </div>
          <label className="ms-2" style={{ color: "#0050A4" }}>
            Don't Send Email Notification
          </label>
        </div> */}
        <div>
          <button
            style={{
              ...styles.saveButton,
              opacity: isSaving ? 0.6 : 1, // Slightly dim the button while saving
              cursor: isSaving ? "not-allowed" : "pointer",
            }}
            onClick={handleSave}
            disabled={isSaving} // Disable the button while saving
          >
            + Save
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  appContainer: {
    fontFamily: "Arial, sans-serif",
    margin: "0 auto",
    padding: "20px",
  },
  header: {
    color: "#0050A4",
    fontSize: "32px",
  },
  requestBox: {
    border: "1px solid #e6e6e6",
    padding: "20px",
    color: "#0050A4",
    backgroundColor: "white",
    borderRadius: "2px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
  },
  requestInfo: {
    padding: "25px",
    color: "#0050A4",
    border: "1px solid #FFFFFF",
    boxSizing: "border-box ",
    marginBottom: "20px",
    borderRadius: "2px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
  },
  datePicker: {
    padding: "8px",
    color: "#0050A4",
    border: "1px solid #0050A4",
    width: "40%",
    boxSizing: "border-box",
  },
  locationBox: {
    marginBottom: "20px",
    color: "#0050A4",
  },
  locationHeader: {
    color: "white",
    fontSize: "20px",
    padding: "15px",
    marginBottom: "5px",
    display: "block",
  },
  locationRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    color: "#0050A4",
  },
  locationColumn: {
    width: "30%",
  },
  input: {
    width: "100%",
    color: "#0050A4",
    padding: "5px",
    boxSizing: "border-box",
    border: "1px solid #0050A4",
    borderRadius: "3px",
  },
  notificationToggle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "20px",
  },
  toggleSwitch: {
    width: "50px",
    height: "25px",
    borderRadius: "25px",
    cursor: "pointer",
    position: "relative",
    transition: "background-color 0.3s",
  },
  toggleHandle: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    top: "2.5px",
    transition: "left 0.3s",
  },
  saveButton: {
    backgroundColor: "#0033a0",
    color: "white",
    border: "none",
    padding: "5px 22px",
    cursor: "pointer",
    borderRadius: "3px",
    fontSize: "20px",
  },
};

export default AddRequest;
