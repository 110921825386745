import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import { useLocation, useNavigate } from "react-router-dom";

const DataFormSaveAnswer = (tabIndex) => {
  const location = useLocation();
  // const requestData = location.state?.requestData;
  const navigate = useNavigate();
  const [answers, setAnswers] = useState([]);
  const [module, setModule] = useState();
  const [requestData, setRequestData] = useState();
  const [changedAnswers, setChangedAnswers] = useState({});
  const [queryParams, setQueryParams] = useState({});
  const [hashParams, setHashParams] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = {};
    for (let [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }
    setQueryParams(paramsObject);
    const hash = location.hash;
    if (hash) {
      setHashParams(hash.substring(1));
      getRequestsList()
    }
  }, [location]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      setModule(tabIndex.modules[Number(tabIndex.tabIndex)]);
      if (location.state?.requestId || queryParams.requestId)
        getRequestDataFormAnswer();
      getRequestsList();

    }
  }, [tabIndex.tabIndex, queryParams]);

  const getRequestDataFormAnswer = async () => {
    const msiId = queryParams.requestId;
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestDataFormAnswer`,
        {},
        { requestId: Number(location.state?.requestId) || Number(msiId) },
        "GET"
      );
      if (isSuccess) {
        setAnswers(
          data.data[tabIndex.modules[Number(tabIndex.tabIndex)]?.moduleTitle]
        );
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const saveAnswerToBackend = async () => {
    const msiId = queryParams.requestId;
    try {
      const { isSuccess } = await apiCall(
        `${config.POST_LOGIN_API_URL}UpdateDateFormAnswer`,
        {},
        {
          requestId: Number(location.state?.requestId) || Number(msiId),
          answers: changedAnswers,
          module: tabIndex.modules[Number(tabIndex.tabIndex)]?.moduleTitle,
        },
        "POST"
      );
      if (isSuccess) {
        setChangedAnswers({})

      }
    } catch (error) {
      console.error("API error while saving answers:", error);
    }
  };

  useEffect(() => {
    if (changedAnswers && Object.keys(changedAnswers).length > 0) {
      saveAnswerToBackend();
    }
  }, [changedAnswers]);

  const getRequestsList = async () => {
    const msiId = queryParams.requestId;

    if (Number(location.state?.requestId) || Number(msiId)) {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestsList`,
        {},
        { requestId: Number(location.state?.requestId) || Number(msiId) },
        "GET"
      );

      if (isSuccess) {
        setRequestData(data?.data[0])
      }
    }
  };

  const getAnswerByKey = (answerKey) => {
    const result =
      answers && answers.find((item) => item.answerKey === answerKey);
    if (result && answerKey === 'D55. CA-4F') {

      if (typeof result.answer === 'string') {
        result.answer = result.answer.split(", ");
      }

      if (result.answer.includes('Shredder onsite. Provide name and address and phone') &&
        !result.answer.includes('3rd party shredder onsite: Name and Address and Phone Number of the company')) {
        result.answer.push('3rd party shredder onsite: Name and Address and Phone Number of the company');
      }

      if (result.answer.includes('Shredder offsite. Provide the company name and address and phone number of the service provider:') &&
        !result.answer.includes('3rd party Shredder offsite: Name and Address and Phone Number of the company')) {
        result.answer.push('3rd party Shredder offsite: Name and Address and Phone Number of the company');
      }

      if (result.answer.includes('Own Shredder. Provide the name and address and phone number of the service provider:') &&
        !result.answer.includes('Company Owned Shredder :')) {
        result.answer.push('Company Owned Shredder :');
      }

      if (result.answer.includes('Document destruction service. Provide the name and address and phone number of the service provider:') &&
        !result.answer.includes('Document destruction service.')) {
        result.answer.push('Document destruction service.');
      }

      if (result.answer.includes('N/A. They do not destroy confidential paper documents.') &&
        !result.answer.includes('We do not destroy confidential paper documents at this location.')) {
        result.answer.push('We do not destroy confidential paper documents at this location.');
      }

      result.answer = result.answer.join(",");

    }


    return result ? result.answer : null;
  };

  const handleChange = (e, questionId, type, option) => {
    console.log( questionId, type, option,"hjsadwdwaSqwqw")
    const { value } = e.target;

    setAnswers((prevAnswers) => {
      if (prevAnswers.length === 0) {
        const newAnswer = {
          answerKey: questionId,
          answer: type === "checkbox" ? option : value,
        };
        setChangedAnswers((prev) => ({
          ...prev,
          [questionId]: newAnswer.answer,
        }));
        return [newAnswer];
      }

      let found = false;

      const updatedAnswers = prevAnswers.map((ans) => {
        if (ans.answerKey === questionId) {
          found = true;
          let updatedAnswer = ans.answer || '';
          if (type === "checkbox") {
            const currentAnswers = updatedAnswer
              ? updatedAnswer.split(",")
              : [];
            updatedAnswer = currentAnswers.includes(option)
              ? currentAnswers.filter((opt) => opt !== option).join(",")
              : [...currentAnswers, option].join(",");
            setChangedAnswers((prev) => ({
              ...prev,
              [questionId]: updatedAnswer,
            }));
            return { ...ans, answer: updatedAnswer };

          } else if (type === "radio" || type === "text") {
            updatedAnswer = value;
            setChangedAnswers((prev) => ({
              ...prev,
              [questionId]: updatedAnswer,
            }));
            return { ...ans, answer: updatedAnswer };
          }
        }
        return ans;
      });

      if (!found) {
        const newAnswer = {
          answerKey: questionId,
          answer: type === "checkbox" ? option : value,
        };
        setChangedAnswers((prev) => ({
          ...prev,
          [questionId]: newAnswer.answer,
        }));
        updatedAnswers.push(newAnswer);
      }

      return updatedAnswers;
    });
  };



  return (
    <Form>
      {module &&
        module.questions.map((question) => (
          <FormGroup key={question.id}>
            <Label style={{ color: "#0050A4", fontSize: "13px" }}>
              {question.id === 1
                ? question.questionTitle
                : `${question.reportId}: ${question.questionTitle}`}
            </Label>

            {/* Conditional for module.moduleId */}
            {module.moduleId === 1 ? (
              <>
                <div>1. Please answer all of the 108 questions! </div>
                <div>2. If any of your answers need further information, a text box will pop up; please put in the appropriate information required. </div>
                <div>Answers will automatically save, but you must click on “COMPLETE” at the end for data form in order for it to be submitted. In want to change the line in database.</div>

                <div>Company Name : {requestData?.companyName}</div>
                <div>Site Address : {requestData?.siteAddress + ' ' + requestData?.siteCity + ' ' + requestData?.siteState}</div>
              </>
            ) : question.subQuestions.length === 0 ? (
              <Input
                style={{
                  border: "1px solid #0050A4",
                  color: "#0050A4",
                }}
                className="me-4"
                type="text"
                name={`question-${question.id}`}
                value={getAnswerByKey(question.reportId)}
                onChange={(e) => handleChange(e, question.reportId, "text")}
              />
            ) : (
              question.subQuestions.map((subQuestion) => (
                <div key={subQuestion.id}>
                  <FormGroup>
                    {/* Handling text input type */}
                    {subQuestion.optionType === "text" && (
                      <>
                        <Label>
                          <div
                            style={{ color: "#0050A4", fontSize: "20px" }}
                            className="mt-4"
                          >
                            {subQuestion.option}
                          </div>
                          <Input
                            style={{
                              border: "1px solid #0050A4",
                              color: "#0050A4",
                              width: "300px",
                            }}
                            className="mt-2"
                            type="text"
                            name={`subQuestion-${subQuestion.id}`}
                            value={getAnswerByKey(question?.reportId)}
                            onChange={(e) =>
                              handleChange(e, question.reportId, "text")
                            }
                          />
                        </Label>
                      </>
                    )}

                    {subQuestion.optionType === "checkbox" && (
                      <>
                        <Label style={{ display: "flex", marginTop: "10px" }}>
                          <Input
                            className="form-checks"
                            type="checkbox"
                            name={`subQuestion-${subQuestion.id}`}
                            checked={typeof getAnswerByKey(question?.reportId) === 'string'
                              ? getAnswerByKey(question?.reportId)
                                ?.split(",")
                                .includes(subQuestion.option.trim())
                              : false
                            }
                            onChange={(e) =>
                              handleChange(
                                e,
                                question.reportId,
                                "checkbox",
                                subQuestion.option
                              )
                            }
                          />
                          <span
                            style={{
                              fontSize: "13px",
                              color: "#0050A4",
                              marginLeft: "10px",
                            }}
                          >
                            {subQuestion.option}
                          </span>
                        </Label>
                        {subQuestion.details &&
                          subQuestion.details.map((detail) => (
                            <FormGroup key={detail.id}>
                              {getAnswerByKey(question?.reportId)
                                ?.split(",")
                                .includes(subQuestion.option.trim()) && (
                                  <>
                                    <Label>
                                      <div
                                        style={{
                                          color: "#0050A4",
                                          fontSize: "14px",
                                        }}
                                        className="ms-4"
                                      >
                                        {detail.option}
                                      </div>
                                      <Input
                                        style={{
                                          border: "1px solid #0050A4",
                                          color: "#0050A4",
                                          width: "300px",
                                        }}
                                        className="mt-2 ms-4"
                                        type="text"
                                        name={`subQuestion-${detail.id}`}
                                        value={getAnswerByKey(detail?.answerKey)}
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            detail?.answerKey,
                                            "text"
                                          )
                                        }
                                      />
                                    </Label>
                                  </>
                                )}
                            </FormGroup>
                          ))}
                      </>
                    )}

                    {/* Handling radio input type */}
                    {subQuestion.optionType === "radio" && (
                      <>
                        <Label style={{ display: "flex", marginTop: "10px" }}>
                          <Input
                            style={
                              getAnswerByKey(question?.reportId) === subQuestion.option
                                ? { backgroundColor: '#0d6efd', borderColor: '#0d6efd' }
                                : {}
                            }
                            type="radio"
                            name={`subQuestion-${subQuestion.id}`}
                            value={subQuestion.option}
                            checked={getAnswerByKey(question?.reportId) === subQuestion.option}
                            onChange={(e) => handleChange(e, question.reportId, "radio")}
                          />

                          <span
                            style={{
                              fontSize: "13px",
                              color: "#0050A4",
                              marginLeft: "10px",
                            }}
                          >
                            {subQuestion.option}
                          </span>
                        </Label>
                        {subQuestion.details &&
                          subQuestion.details.map((detail) => (
                            <FormGroup key={detail.id}>
                              {getAnswerByKey(question?.reportId) ===
                                subQuestion.option && (
                                  <>
                                    <Label>
                                      <div
                                        style={{
                                          color: "#0050A4",
                                          fontSize: "14px",
                                        }}
                                        className="ms-4"
                                      >
                                        {detail.option}
                                      </div>
                                      <Input
                                        style={{
                                          border: "1px solid #0050A4",
                                          color: "#0050A4",
                                          width: "300px",
                                        }}
                                        className="mt-2 ms-4"
                                        type="text"
                                        name={`subQuestion-${detail.id}`}
                                        value={getAnswerByKey(detail?.answerKey)}
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            detail?.answerKey,
                                            "text"
                                          )
                                        }
                                      />
                                    </Label>
                                  </>
                                )}
                            </FormGroup>
                          ))}
                      </>
                    )}
                  </FormGroup>
                </div>
              ))
            )}
          </FormGroup>
        ))}
    </Form>

  );
};

export default DataFormSaveAnswer;
