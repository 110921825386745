import React from "react";
import Modal from "react-modal";
import { BsExclamationTriangle, BsDash } from "react-icons/bs";
import cancelBtnIcon from "./img/cancelInspection.png";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";

function CancelInspectionModal({ isOpen, onRequestClose, onConfirm }) {
  const modalStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
      height:'230px',
      padding: "0px", // Removed padding for full-width header
      backgroundColor: "#eaf2fb", // Light blue background
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
     
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const headerContainerStyle = {
    backgroundColor: "#0050A4",
    padding: "10px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // borderTopLeftRadius: "10px",
    // borderTopRightRadius: "10px",
  };

  const headerTextStyle = {
    color: "white",
    fontSize: "18px",
  };

  const closeIconStyle = {
    color: "black",
    cursor: "pointer",
    // borderRadius:'50%',
    // backgroundColor:'#E6F1F7',
    fontSize: "20px",
  };

  const bodyStyle = {
    padding: "20px",
    paddingRight:'5px',
    // textAlign: "center",
  };

  const headerStyle = {
    color: "#0050A4", // Darker blue color for the header
    marginBottom: "10px",
    fontSize: "24px",
  };

  const paragraphStyle = {
    color: "#666",
    marginBottom: "30px",
    fontSize: "14px",
  };

  const actionsStyle = {
    display: "flex",
    justifyContent:'space-between',
    gap: "20px",
  };

  const buttonStyle = {
    backgroundColor: "#0050A4", // Darker blue button
    color: "white",
    border: "none",
    padding: "10px 22px",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "16px",
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#E6EFFA",
    color: "#0050A4",
    border: "1px solid #0050A4",
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyles}>
      <div>
        <div style={headerContainerStyle}>
          <span style={headerTextStyle}>Reassign</span>
          <span style={closeIconStyle} onClick={onRequestClose}> <img src={cancelBtnIcon} style={{borderRadius:'50%',backgroundColor:'#E6F1F7',padding:'5px' }} /> </span>
         
        </div>
        <div style={bodyStyle}>
         
          <span style={headerStyle}>Do you want to reassign?</span>
        
          <div style={actionsStyle} className="mt-5">
          <button onClick={onRequestClose} style={cancelButtonStyle}>
              Cancel
            </button>
            <button onClick={onRequestClose} style={buttonStyle} className="me-4">
              Yes
            </button>
           
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CancelInspectionModal;
