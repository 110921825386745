// CompanyIconDetails.js
import React from 'react';
import companyicon from './img/companyicon.png';
import './companyIcon.css'

const CompanyIconDetails = ({ onClose }) => {
  return (
    <div className="company-icon-details">
      <div className="icon-header">
        <h3>Edit</h3>
        <button className="export-button">Export</button>
      </div>
      <div className="icon-content">
        <div className="company-details-section">
          <h4>Company Details</h4>
          <img src={companyicon} alt="Company Icon" className="large-icon" />
        </div>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label>ARA ID</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>Company Name</label>
              <input type="text" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Street</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>Street 2</label>
              <input type="text" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>City</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>State</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>Zipcode</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>Type</label>
              <input type="text" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Lender Name</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>Lender Email</label>
              <input type="email" />
            </div>
          </div>
          <div className="form-actions">
            <button type="submit" className="next-button">Next</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyIconDetails;