import React, { useEffect, useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import DataFormSaveAnswer from "./DataFormSaveAnswer";
import Loader from "../../CompanyFolder/CompanyList/Loader";

const NavTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("0");
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [queryParams, setQueryParams] = useState({});
  const [hashParams, setHashParams] = useState('');
  const [requestData, setRequestData] = useState();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = {};
    for (let [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }
    setQueryParams(paramsObject);
    const hash = location.hash;
    if (hash) {
      setHashParams(hash.substring(1));

    }
  }, [location]);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const nextTab = () => {
    const nextIndex = parseInt(activeTab) + 1;
    if (nextIndex < modules.length) {
      setActiveTab(nextIndex.toString());
    }
  };

  const previousTab = () => {
    const prevIndex = parseInt(activeTab) - 1;
    if (prevIndex >= 0) {
      setActiveTab(prevIndex.toString());
    }
  };

  const completeDataform = async () => {
    const msiId = queryParams.requestId;
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}completeDataform`,
        {},
        { requestId: location.state?.requestId || msiId },
        // { requestId: Number(location.state?.requestId)},
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const getRequestUserData = async () => {
    const msiId = queryParams.requestId;
    if (location.state?.requestId || msiId) {

      try {
        const { isSuccess, data } = await apiCall(
          `${config.POST_LOGIN_API_URL}getRequestUserData`,
          {},
          { requestId: location.state?.requestId || msiId },
          "GET"
        );
        if (isSuccess) {
          setRequestData(data.data);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    }

  };

  const getDataFormQuestionList = async () => {
    setLoading(true);

    try {
      const { isSuccess, data } = await apiCall(

        `${config.POST_LOGIN_API_URL}getDataFormQuestionList`,
        {},
        { type: "ALL" },
        "GET"
      );
      setLoading(false);

      if (isSuccess && Array.isArray(data.data)) {
        setModules(data.data);
      } else {
        console.error("API response is not an array:", data);
        setModules([]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setModules([]);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getDataFormQuestionList();
      getRequestUserData();
    }
  }, [navigate]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="form-tab">
      {requestData?.status != '4' && <>
        <span className="d-flex justify-content-end">
          <button onClick={completeDataform} className="btn btn-success next-btn ps-4 pe-4 mb-3">
            Complete
          </button>
        </span>
      </>}

      <Row>
        <Col sm="2">
          <Nav pills vertical>
            {modules.map((module, index) => (
              <NavItem key={module.moduleId}>
                <NavLink
                  className={classnames({ active: activeTab === `${index}` })}
                  onClick={() => toggle(`${index}`)}
                >
                  {module.moduleTitle}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
        <Col sm="10">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <Row>
                <Col sm="12">
                  <h1>{modules[activeTab]?.moduleTitle}</h1>
                  {modules && modules.length && (
                    <DataFormSaveAnswer
                      tabIndex={activeTab}
                      modules={modules}
                    />
                  )}

                  <div className="d-flex justify-content-between mt-4 ">
                    {parseInt(activeTab) > 0 && (
                      <Button
                        className="next-prev-btn px-5 mt-5"
                        onClick={previousTab}
                      >
                        Previous
                      </Button>
                    )}
                    {parseInt(activeTab) < modules.length - 1 && (
                      <Button
                        className="next-prev-btn px-5 mt-5"
                        onClick={nextTab}
                      >
                        Next
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>

    </div>
  );
};

export default NavTabs;
