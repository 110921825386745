import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Container } from "reactstrap";
import { useLocation } from 'react-router-dom';

const FullLayout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hide = queryParams.get('hide') === 'true';  // Convert the string to boolean
  return (
    <main>
      <div className="pageWrapper d-lg-flex">
        {/********Sidebar**********/}
        <aside className="sidebarArea shadow light-gradient" id="sidebarArea">
          {
          !hide && 
          <Sidebar />

          }
        </aside>
        {/********Content Area**********/}

        <div className="contentArea bg-white">
          {/********header**********/}
          <Header />
          {/********Middle Content**********/}
          <Container className="p-4 wrapper" fluid>
            <Outlet />
          </Container>
        </div>
      </div>
    </main>
  );
};

export default FullLayout;
