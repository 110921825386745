import React, { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import "./CompanyDetail.css";
import deleteIcon from "../img/deleteIcon.png";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json"
import exportIcon from "../img/exportIcon.png";
import { FaPlus, FaMinus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


const CompanyDetail = ({ onSwitchToContact, answer, setAnswer }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({
    araId: '',
    name: '',
    street: ''
  });

  const [lenders, setLenders] = useState([{ lenderName: '', lenderEmail: '' }]);

  const validateAraId = (araId) => {
    return araId.trim() === '' ? 'ARA ID is required' : '';
  };

  const validateName = (name) => {
    return name.trim() === '' ? 'Company Name is required' : '';
  };

  const validateStreet = (street) => {
    return street.trim() === '' ? 'Street is required' : '';
  };

  const validateCity = (city) => {
    return city.trim() === '' ? 'City is required' : '';
  };

  const validateState = (state) => {
    return state.trim() === '' ? 'State is required' : '';
  };

  const validateZipcode = (zipcode) => {
    return zipcode.trim() === '' ? 'Zipcode is required' : '';
  };

  const handleValidation = () => {
    const araIdError = validateAraId(answer.araId);
    const nameError = validateName(answer.name);
    const streetError = validateStreet(answer.street);
    const cityError = validateCity(answer.city);
    const stateError = validateState(answer.state);
    const zipcodeError = validateZipcode(answer.zipcode);

    setErrors({
      araId: araIdError,
      name: nameError,
      street: streetError,
      city: cityError,
      state: stateError,
      zipcode: zipcodeError
    });

    return !araIdError && !nameError && !streetError && !cityError && !stateError && !zipcodeError;
  };

  const addNewCompanyAndContact = async (e) => {
    e.preventDefault();
    setSaving(true);
    const updatedAnswer = { ...answer, userData: [] };
    const { isSuccess } = await apiCall(
      `${config.POST_LOGIN_API_URL}addNewCompanyAndContact`,
      {},
      updatedAnswer,
      "POST"
    );
    setSaving(false);
    if (isSuccess) {
      navigate("/company-list");
    }
  };

  const changeName = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      name: e.target.value,
    }));
  };

  const changeAraId = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      araId: e.target.value,
    }));

    if (data.includes(e.target.value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        araId: 'This ARA ID already exists.', // Set the error message
      }));
      setIsDisabled(true); // Disable the button
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        araId: null, // No error if araId is valid
      }));
      setIsDisabled(false); // Enable the button
    }
  };

  const changeStreet2 = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      street2: e.target.value,
    }));
  };

  const changeCity = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      city: e.target.value,
    }));
  };

  const changeState = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      state: e.target.value,
    }));
  };

  const changeZipcode = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      zipcode: e.target.value,
    }));
  };

  const changeOfficeType = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      officeType: e.target.value,
    }));
  };

  const getCompanyList = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyList`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setData([...new Set(data?.data?.map(item => item.araId))]);

    }
  };

  useEffect(() => {
    getCompanyList()
  }, [])

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;
      const getAddressComponent = (types) => {
        const component = addressComponents.find(comp => types.some(type => comp.types.includes(type)));
        return component ? component.long_name : '';
      };

      const street = getAddressComponent(['street_number']) + ' ' + getAddressComponent(['route']);
      const city = getAddressComponent(['locality']);
      const state = getAddressComponent(['administrative_area_level_1']);
      const zipcode = getAddressComponent(['postal_code']);
      setAnswer(prevAnswer => ({
        ...prevAnswer,
        street: street || address,
        city,
        state,
        zipcode
      }));
    } catch (error) {
      console.error("Error fetching address details: ", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      onSwitchToContact();
    }
  };

  const addLender = () => {
    setLenders([...lenders, { lenderName: '', lenderEmail: '' }]);
  };

  const removeLender = (index) => {
    const updatedLenders = lenders.filter((_, i) => i !== index);
    setLenders(updatedLenders);
  };

  const handleLenderChange = (index, field, value) => {
    const updatedLenders = [...lenders];
    updatedLenders[index][field] = value;
    setLenders(updatedLenders);
  };

  return (
    <div className="add-site">
      <div className="header">
        <h2>Add Company</h2>

      </div>

      <div className="tabs" style={{ border: "none", background: "white" }}>
        <button className="tab active">Company Detail</button>
        <button className="tab" onClick={onSwitchToContact}>
          Main Contact
        </button>
      </div>

      <div className="site-detail-h">
        <div className="site-detail-left">Company Detail</div>
        <div className="site-detail-right h-75">
        </div>
      </div>

      <div className="site-detail">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="ara-id">ARA ID</span>
              <input onChange={changeAraId} value={answer.araId} type="text" id="ara-id" />
              {errors.araId && <div className="error">{errors.araId}</div>}
            </div>
            <div className="form-group">
              <span htmlFor="company-name">Company Name</span>
              <input onChange={changeName} value={answer.name} type="text" id="company-name" />
              {errors.name && <div className="error">{errors.name}</div>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <span htmlFor="street">Street</span>
              <PlacesAutocomplete
                value={answer.street}
                onChange={(value) => setAnswer({ ...answer, street: value })}
                onSelect={handleSelect}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <input {...getInputProps({ placeholder: 'Search Street' })} />
                    <div>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                        };
                        return (
                          <div {...getSuggestionItemProps(suggestion, { style })}>
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {errors.street && <div className="error">{errors.street}</div>}
            </div>
            <div className="form-group">
              <span htmlFor="street2">Street 2</span>
              <input onChange={changeStreet2} value={answer.street2} type="text" id="street2" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <span htmlFor="city">City</span>
              <input value={answer.city} onChange={changeCity} type="text" id="city" />
              {errors.city && <div className="error">{errors.city}</div>}
            </div>
            <div className="form-group">
              <span htmlFor="state">State</span>
              <input value={answer.state} onChange={changeState} type="text" id="state" />
              {errors.state && <div className="error">{errors.state}</div>}
            </div>
            <div className="form-group">
              <span htmlFor="zipcode">Zipcode</span>
              <input value={answer.zipcode} onChange={changeZipcode} type="text" id="zipcode" />
              {errors.zipcode && <div className="error">{errors.zipcode}</div>}
            </div>
            <div className="form-group">
              <span htmlFor="office-type">Type</span>
              <select onChange={changeOfficeType} style={{ color: 'rgba(0, 80, 164, 1)' }} className="form-control col-lg-6" id="office-type">
                <option value="Head Office">Head Office</option>
              </select>
            </div>
          </div>

          {lenders.map((lender, index) => (
            <div className="form-row" key={index}>
              <div className="form-group">
                <div htmlFor={`lender-name-${index}`}>Lender Name</div>
                <input
                  type="text"
                  id={`lender-name-${index}`}
                  value={lender.lenderName}
                  onChange={(e) => handleLenderChange(index, 'lenderName', e.target.value)}
                />
              </div>
              <div className="form-group">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div htmlFor={`lender-email-${index}`}>Lender Email</div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <div
                      type="button"
                      className="plus me-2"
                      title="addLender"
                      onClick={addLender}
                    >
                      <FaPlus />
                    </div>
                    {/* Only show FaMinus for newly added rows */}
                    {index !== 0 && (
                      <div
                        type="button"
                        className="minus"
                        title="removeLender"
                        onClick={() => removeLender(index)}
                      >
                        <FaMinus />
                      </div>
                    )}
                  </div>
                </div>
                <input
                  type="email"
                  id={`lender-email-${index}`}
                  value={lender.lenderEmail}
                  onChange={(e) => handleLenderChange(index, 'lenderEmail', e.target.value)}
                />
              </div>
            </div>
          ))}

          <div className="text-end">
            <button onClick={addNewCompanyAndContact} className="next-btn" disabled={isDisabled || saving}>
              {saving ? 'Saving' : 'Save'}
            </button>

            <button style={{ marginLeft: "10px" }} type="submit" className="next-btn" disabled={isDisabled || saving}>
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyDetail;
