// import React, { useState } from 'react';
// import ViewIcon from '../img/viewIcon.png';
// import editIcon from  '../img/editIcon.png';
// import archiveIcon from '../img/archiveIcon.png';
// import { NavLink } from 'react-router-dom';

// const TableRow = ({ number, araId, company, address, city, state, time, recheckCycle, status, onDelete }) => (
//   <tr className={status === 'New Request' ? 'new-request' : ''}>
//     <td>{number}</td>
//     <td>{araId}</td>
//     <td>{company}</td>
//     <td>{address}</td>
//     <td>{state}</td>
//     <td>{time}</td>
//     <td>{recheckCycle}</td>
//     <td>
//       <span className={`status ${status.toLowerCase().replace(' ', '-')}`}>
//         {status}
//       </span>
//     </td>
//     <td>
//       <div className="actions">
//       <NavLink to={`/view-site`}>
//           <img src={ViewIcon} alt='View' className='me-2' style={{ cursor: 'pointer' }} />
//         </NavLink>
//         <img src={editIcon} alt='' className='me-2' />
//         <img src={archiveIcon} alt='' onClick={onDelete} style={{ cursor: 'pointer' }}/>
//       </div>
//     </td>
//   </tr>
// );

// const App = () => {
//   const [rows, setRows] = useState([
//     { number: '01', araId: 'po120plz355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', time: '00:00:00', recheckCycle: '12 months', status: 'New Request' },
//     { number: '02', araId: 'po120plz355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', time: '00:00:00', recheckCycle: '12 months', status: 'In Progress' },
//     { number: '03', araId: 'po120plz355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', time: '00:00:00', recheckCycle: '12 months', status: 'In Progress' },
//     { number: '04', araId: 'po120plz355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', time: '00:00:00', recheckCycle: '12 months', status: 'In Progress' },
//     { number: '05', araId: 'po120plz355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', time: '00:00:00', recheckCycle: '12 months', status: 'In Progress' },
//     // Add more rows as needed
//   ]);

//   const [searchQuery, setSearchQuery] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [entriesPerPage, setEntriesPerPage] = useState(10);

//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

//   const handleSort = (column) => {
//     let direction = 'asc';
//     if (sortConfig.key === column && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key: column, direction });
//   };

//   const handleDeleteRow = (index) => {
//     setRows(prevRows => prevRows.filter((row, i) => i !== index));
//   };

//   const handleEntriesChange = (event) => {
//     setEntriesPerPage(Number(event.target.value));
//     setCurrentPage(1);
//   };

//   const sortedRows = [...rows].sort((a, b) => {
//     if (a[sortConfig.key] < b[sortConfig.key]) {
//       return sortConfig.direction === 'asc' ? -1 : 1;
//     }
//     if (a[sortConfig.key] > b[sortConfig.key]) {
//       return sortConfig.direction === 'asc' ? 1 : -1;
//     }
//     return 0;
//   });

//   const handleSearch = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const filteredRows = sortedRows.filter((row) =>
//     row.araId.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     row.company.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     row.address.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const indexOfLastRow = currentPage * rowsPerPage;
//   const indexOfFirstRow = indexOfLastRow - rowsPerPage;
//   const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);

//   const pageNumbers = [];
//   for (let i = 1; i <= Math.ceil(filteredRows.length / rowsPerPage); i++) {
//     pageNumbers.push(i);
//   }

//   return (
//     <div className="App" style={{ padding: '20px', maxWidth: '100%' }}>
//       <div className="controls row">
//         <div className="show-entries col-lg-2">
//           <label>Show </label>
//           <select value={entriesPerPage} className="form-control" onChange={handleEntriesChange}>
//             <option value={5}>5</option>
//             <option value={10}>10</option>
//             <option value={25}>25</option>
//             <option value={50}>50</option>
//           </select>
//           <label> entries</label>
//         </div>
//         <div className="search-box col-lg-8 text-center m">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Search..."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//           />
//         </div>
//         <div className="col-lg-2 text-end">
//         <NavLink to="/add-site">
//             <button className="add-site-button btn">
//               <span style={{ height: '100px' }}>+</span> Add Site
//             </button>
//           </NavLink>
//         </div>
//       </div>

//       <div className="table-container" style={{ maxWidth: '100%' }}>
//         <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//           <thead>
//             <tr>
//               <th style={{ width: '5%' }} onClick={() => handleSort('number')}>
//                 # {sortConfig.key === 'number' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
//               </th>
//               <th style={{ width: '10%' }} onClick={() => handleSort('araId')}>
//                 ARA ID {sortConfig.key === 'araId' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
//               </th>
//               <th style={{ width: '10%' }} onClick={() => handleSort('company')}>
//                 Company {sortConfig.key === 'company' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
//               </th>
//               <th style={{ width: '15%' }} onClick={() => handleSort('address')}>
//                 Address {sortConfig.key === 'address' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
//               </th>
//               <th style={{ width: '8%' }} onClick={() => handleSort('state')}>
//                 State {sortConfig.key === 'state' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
//               </th>
//               <th style={{ width: '10%' }} onClick={() => handleSort('time')}>
//                 Time {sortConfig.key === 'time' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
//               </th>
//               <th style={{ width: '13%' }} onClick={() => handleSort('recheckCycle')}>
//                 Recheck Cycle {sortConfig.key === 'recheckCycle' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
//               </th>
//               <th style={{ width: '12%' }}>Status</th>
//               <th style={{ width: '10%' }}>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentRows.map((row, index) => (
//               <TableRow key={index} {...row} onDelete={() => handleDeleteRow(index)} />
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default App;


import React, { useState } from 'react';
import ViewIcon from '../img/viewIcon.png';
import editIcon from '../img/editIcon.png';
import archiveIcon from '../img/archiveIcon.png';
import { NavLink } from 'react-router-dom';

const App = () => {
  const [rows, setRows] = useState([
    { number: '01', araId: 'po120plz355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', time: '00:00:00', recheckCycle: '12 months', status: 'New Request' },
    { number: '02', araId: 'po120plz355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', time: '00:00:00', recheckCycle: '12 months', status: 'In Progress' },
        { number: '03', araId: 'po120plz355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', time: '00:00:00', recheckCycle: '12 months', status: 'In Progress' },
        { number: '04', araId: 'po120plz355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', time: '00:00:00', recheckCycle: '12 months', status: 'In Progress' },
        { number: '05', araId: 'po120plz355', company: 'Ghrix', address: '94-990 Pakela St Ste 6-21', city: 'Arizona', state: 'Arizona', time: '00:00:00', recheckCycle: '12 months', status: 'In Progress' },

    
    // Add more rows as needed
  ]);

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [editingIndex, setEditingIndex] = useState(null);
  const [editData, setEditData] = useState({});

  const handleSort = (column) => {
    let direction = 'asc';
    if (sortConfig.key === column && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  };

  const handleDeleteRow = (index) => {
    setRows(prevRows => prevRows.filter((row, i) => i !== index));
  };

  const handleSave = () => {
    setRows(prevRows => prevRows.map((row, index) =>
      index === editingIndex ? { ...row, ...editData } : row
    ));
    setEditingIndex(null);
    setEditData({});
  };

  const handleCancel = () => {
    setEditingIndex(null);
    setEditData({});
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditData(rows[index]);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setEditData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleEntriesChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const sortedRows = [...rows].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredRows = sortedRows.filter((row) =>
    row.araId.toLowerCase().includes(searchQuery.toLowerCase()) ||
    row.company.toLowerCase().includes(searchQuery.toLowerCase()) ||
    row.address.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredRows.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="App" style={{ padding: '20px', maxWidth: '100%' }}>
      <div className="controls row mt-1 ms-3">
        <div className="show-entries col-lg-2">
          <label>Show </label>
          <select value={entriesPerPage} className="form-control" onChange={handleEntriesChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
          <label> entries</label>
        </div>
        <div className="search-box col-lg-8 text-center m">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <div className="col-lg-2 text-end">
          <NavLink to="/add-site">
            <button className="add-site-button btn" style={{color:'white',backgroundColor:'#F7921E', fontSize:'12px',padding:'10px 22px'}}>
              <span style={{ height: '100px' }}>+</span> Add Site
            </button>
          </NavLink>
        </div>
      </div>

      <div className="table-container mt-5" style={{ maxWidth: '100%' ,borderRadius:'10px',border:'1px solid #ccc',fontSize:'14px'}}>
        <table style={{ width: '100%', borderCollapse: 'collapse', }}>
          <thead style={{borderBottom:'1px solid #ccc'}}>
            <tr>
              <th style={{ width: '5%' }} onClick={() => handleSort('number')}>
                # {sortConfig.key === 'number' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th style={{ width: '10%' }} onClick={() => handleSort('araId')}>
                ARA ID {sortConfig.key === 'araId' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th style={{ width: '10%' }} onClick={() => handleSort('company')}>
                Company {sortConfig.key === 'company' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th style={{ width: '15%' }} onClick={() => handleSort('address')}>
                Address {sortConfig.key === 'address' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th style={{ width: '8%' }} onClick={() => handleSort('state')}>
                State {sortConfig.key === 'state' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th style={{ width: '10%' }} onClick={() => handleSort('time')}>
                Time {sortConfig.key === 'time' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th style={{ width: '13%' }} onClick={() => handleSort('recheckCycle')}>
                Recheck Cycle {sortConfig.key === 'recheckCycle' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th style={{ width: '12%' }}>Status</th>
              <th style={{ width: '10%' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((row, index) => (
              <tr key={index} className={row.status === 'New Request' ? 'new-request' : ''} style={{borderBottom:'1px solid #ccc'}}>
                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="number"
                      value={editData.number}
                      onChange={(e) => handleChange(e, index)}
                    />
                  ) : (
                    row.number
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="araId"
                      value={editData.araId}
                      onChange={(e) => handleChange(e, index)}
                    />
                  ) : (
                    row.araId
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="company"
                      value={editData.company}
                      onChange={(e) => handleChange(e, index)}
                    />
                  ) : (
                    row.company
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="address"
                      value={editData.address}
                      onChange={(e) => handleChange(e, index)}
                    />
                  ) : (
                    row.address
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="state"
                      value={editData.state}
                      onChange={(e) => handleChange(e, index)}
                    />
                  ) : (
                    row.state
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="time"
                      value={editData.time}
                      onChange={(e) => handleChange(e, index)}
                    />
                  ) : (
                    row.time
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="recheckCycle"
                      value={editData.recheckCycle}
                      onChange={(e) => handleChange(e, index)}
                    />
                  ) : (
                    row.recheckCycle
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="status"
                      value={editData.status}
                      onChange={(e) => handleChange(e, index)}
                    />
                  ) : (
                    <span className={`status ${row.status.toLowerCase().replace(' ', '-')}`}>
                      {row.status}
                    </span>
                  )}
                </td>
                <td>
                  <div className="actions">
                    {editingIndex === index ? (
                      <>
                        <button onClick={handleSave} className="btn btn-primary"style={{backgroundColor:'#0050A4'}}> Save</button>
                        {/* <button onClick={handleCancel} className="btn btn-secondary">Cancel</button> */}
                      </>
                    ) : (
                      <>
                        <NavLink to={`/view-site`}>
                          <img src={ViewIcon} alt='View' className='me-2' style={{ cursor: 'pointer' }} />
                        </NavLink>
                        <img
                          src={editIcon}
                          alt='Edit'
                          className='me-2'
                          onClick={() => handleEditClick(index)}
                          style={{ cursor: 'pointer' }}
                        />
                        <img
                          src={archiveIcon}
                          alt='Archive'
                          onClick={() => handleDeleteRow(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default App;
