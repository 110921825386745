import React, { useEffect, useState } from "react";
import MainContact from "./MainContact.js";
import { useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import PlacesAutocomplete, {geocodeByAddress,} from "react-places-autocomplete";
import { FaMinus, FaPlus} from "react-icons/fa";

const EditCompany = ({editData, setShowDetails}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [companyUserData, setCompanyUserData] = useState([]);
  const [data,setData]=useState()
  const [isDisabled,setIsDisabled]=useState()
  const [companyData, setCompanyData] = useState({});
  const [currentPage, setCurrentPage] = useState("editCompany");
  const [errors, setErrors] = useState({});
  const [lenders, setLenders] = useState([
    { id: Date.now(), name: "", email: "" },
  ]);

  const getCompanyContact = async () => {
    const match = location.pathname.match(/\/company-list\/edit\/(\d+)/);
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyContact`,
      {},
      { companyId: editData.id },
      "GET"
    );
    if (isSuccess) {
      setCompanyUserData(data?.data?.comapnyUserData);
      setCompanyData(data?.data?.companyData);
    } else {
      console.log("error");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });

    if(name==="araId"){

      if (data.includes(e.target.value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          araId: 'This ARA ID already exists.', // Set the error message
        }));
        setIsDisabled(true); // Disable the button
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          araId: null, // No error if araId is valid
        }));
        setIsDisabled(false); // Enable the button
      }
    }
   
  };

  const validate = () => {
    let formErrors = {};

    if (!companyData.araId) {
      formErrors.araId = "ARA ID is required";
    }

    if (!companyData.street) {
      formErrors.street = "street is required";
    }

    if (!companyData.city) {
      formErrors.city = "city is required";
    }

    if (!companyData.state) {
      formErrors.state = "state is required";
    }
    if (!companyData.name) {
      formErrors.name = "Company Name is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const getCompanyList = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyList`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const uniqueAraIds = [...new Set(data?.data?.map(item => item.araId))];
      setData( [...new Set(data?.data?.map(item => item.araId))]);
    
    }
  };

  useEffect(()=>{
    getCompanyList()
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("Updated company data:", companyData);
    }
  };

  const handleNext = () => {
    if (validate()) {
      setCurrentPage("mainContact");
    }
  };

  const handlePrevious = () => {
    setCurrentPage("editCompany");
  };

  const handleInputChanges = (id, e) => {
    const { name, value } = e.target;
    setLenders(
      lenders.map((lender) =>
        lender.id === id ? { ...lender, [name]: value } : lender
      )
    );
  };

  const addLender = () => {
    setLenders([...lenders, { id: Date.now(), name: "", email: "" }]);
  };

  const removeLender = (id) => {
    setLenders(lenders.filter((lender) => lender.id !== id));
  };
  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;
      const getAddressComponent = (types) => {
        const component = addressComponents.find((comp) =>
          types.some((type) => comp.types.includes(type))
        );
        return component ? component.long_name : "";
      };

      const street =
        getAddressComponent(["street_number"]) +
        " " +
        getAddressComponent(["route"]);
      const city = getAddressComponent(["locality"]);
      const state = getAddressComponent(["administrative_area_level_1"]);
      const zipcode = getAddressComponent(["postal_code"]);

      setCompanyData((prevAnswer) => ({
        ...prevAnswer,
        street: street || address,
        city,
        state,
        zipcode,
      }));
    } catch (error) {
      console.error("Error fetching address details: ", error);
    }
  };

  const handleSave = async () => {
    
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}updateCompanyData`,
      {},
      { companyId: companyData.id, ...companyData,officeType:'Head Office', userData: [] },
      "POST"
    );
    if (isSuccess) {
      setShowDetails(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getCompanyContact();
    }
  }, []);

  if (currentPage === "mainContact") {
    return (
      <MainContact
        onPrevious={handlePrevious}
        companyData={companyData}
        companyUserData={companyUserData}
        setShowDetails = {setShowDetails}
      />
    );
  }

  return (
    <div  className="pt-0 mt-0" style={styles.container}>
  
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#0050A4",
            paddingTop: "10px",
          }}
          className="mt-5 mb-0"
        >
          <div style={styles.companyDetailsTitle} className="ps-3 pt-1">
            Company Details
          </div>
        
        </div>
        <div className="fullcontainer">
          <div style={styles.companyDetails}>
            <div style={styles.formGrid}>
              <div style={styles.formGroup}>
                <label htmlFor="araId" style={styles.label}>
                  ARA ID
                </label>
                <input
                  type="text"
                  id="araId"
                  name="araId"
                  value={companyData?.araId}
                  onChange={handleInputChange}
                  style={styles.input}
                />
                {errors.araId && (
                  <span style={styles.error}>{errors.araId}</span>
                )}
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="name" style={styles.label}>
                  Company Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={companyData?.name}
                  onChange={handleInputChange}
                  style={styles.input}
                />
                {errors.name && <span style={styles.error}>{errors.name}</span>}
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="street" style={styles.label}>
                  Street
                </label>
                <PlacesAutocomplete
                  value={companyData.street}
                  onChange={(value) =>
                    setCompanyData({ ...companyData, street: value })
                  }
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({ placeholder: "Search Street" })}
                        style={styles.input}
                      />
                      <div>
                        {loading ? <div>Loading...</div> : null}
                        {suggestions.map((suggestion, index) => {
                          const style = suggestion.active
                            ? { backgroundColor: "#a8dadc", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              key={index}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                {errors.street && (
                  <span style={styles.error}>{errors.street}</span>
                )}
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="street2" style={styles.label}>
                  Street 2
                </label>
                <input
                  type="text"
                  id="street2"
                  name="street2"
                  value={companyData?.street2}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>
            </div>
            <div style={styles.rowGrid} className="mt-4">
              <div style={styles.formGroup}>
                <label htmlFor="city" style={styles.label}>
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={companyData?.city}
                  onChange={handleInputChange}
                  style={styles.input}
                />
                {errors.city && <span style={styles.error}>{errors.city}</span>}
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="state" style={styles.label}>
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={companyData?.state}
                  onChange={handleInputChange}
                  style={styles.input}
                />
                {errors.state && (
                  <span style={styles.error}>{errors.state}</span>
                )}
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="zipcode" style={styles.label}>
                  Zipcode
                </label>
                <input
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  value={companyData?.zipcode}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>            
            </div>

            <div>
              {lenders.map((lender, index) => (
                <div
                  key={lender.id}
                  className="legend-row mt-4"
                  style={styles.lenderRowGrid}
                >
                  <div style={styles.formGroup}>
                    <label
                      htmlFor={`lenderName-${lender.id}`}
                      style={styles.label}
                    >
                      Lender Name
                    </label>
                    <input
                      type="text"
                      id={`lenderName-${lender.id}`}
                      name="name"
                      value={lender.name}
                      onChange={(e) => handleInputChanges(lender.id, e)}
                      style={styles.input}
                    />
                  </div>
                  <div style={styles.formGroup}>
                    <div
                      style={
                        {
                          //  display:'flex',
                          //  justifyContent:'space-between'
                        }
                      }
                    >
                      {index == 0 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <label
                              htmlFor={`lenderEmail-${lender.id}`}
                              style={styles.label}
                            >
                              Lender Email
                            </label>
                          </div>

                          <div>
                            <button
                              type="button"
                              onClick={addLender}
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                            >
                              <FaPlus color="#0050A4" title="Add Lender" />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <label
                              htmlFor={`lenderEmail-${lender.id}`}
                              style={styles.label}
                            >
                              Lender Email
                            </label>
                          </div>

                          <div
                            style={{
                              width: "4vw",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <button
                                type="button"
                                onClick={addLender}
                                style={{
                                  border: "none",
                                  backgroundColor: "transparent",
                                }}
                              >
                                <FaPlus color="#0050A4" title="Add Lender" />
                              </button>
                            </div>
                            <div>
                              <button
                                type="button"
                                onClick={() => removeLender(lender.id)}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                }}
                              >
                                <FaMinus
                                  color="#0050A4"
                                  title="Delete Lender"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <input
                      type="email"
                      id={`lenderEmail-${lender.id}`}
                      name="email"
                      value={lender.email}
                      onChange={(e) => handleInputChange(lender.id, e)}
                      style={styles.input}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="text-end">
              <button
                type="button"
                onClick={handleSave}
                style={styles.nextButton}
                disabled={isDisabled}
                className="m-2"
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleNext}
                style={styles.nextButton}
                disabled={isDisabled}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCompany;

const styles = {
  container: {
    padding: "20px",
    position: "relative",
  },
  title: {
    fontSize: "32px",
    color: "#0050A4",
    marginBottom: "20px",
  },
  exportButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
    padding: "5px 20px",
    backgroundColor: "#fff",
    fontSize: "16px",
    border: "1px solid #0050A4",
    borderRadius: "5px",
    color: "#0050A4",
    cursor: "pointer",
  },
  companyDetails: {
    backgroundColor: "#E2EDF9",
    padding: "20px",
    marginTop: "0px",
    borderRadius: "5px",
  },
  companyDetailsTitle: {
    backgroundColor: "#0050A4",
    fontSize: "20px",
    color: "white",
    marginBottom: "15px",
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "20px",
    gridRowGap: "20px",
    marginTop: "0px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "5px",
    color: "#0050A4",
  },
  input: {
    padding: "10px",
    border: "1px solid #0050A4",
    borderRadius: "4px",
    color: "#0050A4",
  },
  error: {
    color: "red",
    fontSize: "12px",
  },
  nextButton: {
    marginTop: "20px",
    padding: "10px 22px",
    backgroundColor: "#0050A4",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  fullcontainer: {
    backgroundColor: "#E2EDF9",
  },
  rowGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "20px",
    gridRowGap: "20px",
  },
  lenderRowGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "20px",
    gridRowGap: "20px",
  },
};