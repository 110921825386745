import React, { useEffect, useState } from "react";
import config from "../../../config/config.json";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../../services/ApiCall";

const ViewPage = ({ status, viewData }) => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState({});
  const [companyUserData, setCompanyUserData] = useState([]);

  const getCompanyContact = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyContact`,
      {},
      { companyId: viewData.id },
      "GET"
    );
    if (isSuccess) {
      setCompanyUserData(data?.data?.comapnyUserData);
      setCompanyData(data?.data?.companyData);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getCompanyContact();
    }
  }, []);

  return (
    <div
      style={{ borderRadius: "8px", backgroundColor: "white", width: "100%" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          marginBottom: "2em",
        }}
      ></div>

      <div
        style={{
          marginTop: "20px",
          backgroundColor: "#0050A4",
          padding: "15px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span style={{ margin: 0, fontSize: "20px", fontWeight: "400" }}>
          Company Details
        </span>
      </div>

      <div
        style={{
          padding: "20px",
          backgroundColor: "#e9edf5",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: "15px",
            color: "#0050A4",
          }}
        >
          <div style={{ flex: "0 1 18%" }}>
            <strong>ARA ID</strong>
            <br />
            {companyData?.araId}
          </div>
          <div style={{ flex: "0 1 18%" }}>
            <strong>Name</strong>
            <br />
            {companyData?.name}
          </div>
          <div style={{ flex: "0 1 18%" }}>
            <strong>Street</strong>
            <br />
            {companyData?.street}
          </div>
          <div style={{ flex: "0 1 18%" }}>
            <strong>Street 2</strong>
            <br />
            {companyData?.street2}
          </div>

        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: "15px",
            color: "#0050A4",
          }}
          className="pt-4"
        >
          <div style={{ flex: "0 1 18%" }}>
            <strong>City</strong>
            <br />
            {companyData?.city}
          </div>
          <div style={{ flex: "0 1 18%" }}>
            <strong>State</strong>
            <br />
            {companyData?.state}
          </div>
          <div style={{ flex: "0 1 18%" }}>
            <strong>Zipcode</strong>
            <br />
            {companyData?.zipcode}
          </div>
          {/* <div style={{ flex: "0 1 18%" }}>
            <strong>Office Type</strong>
            <br />
            {companyData?.officeType}
          </div> */}
          <div style={{ flex: "0 1 18%" }}>
            <strong>Status</strong>
            <br />
            <span
              style={{
                color: status === "Active" ? "#48AB57" : "#48AB57",
                padding: "5px 10px",
                borderRadius: "10px",
              }}
            >
              {companyData?.status === 0 ? "Active" : "Inactive"}
              <span> </span>
            </span>
          </div>
          <div style={{ flex: "0 1 18%" }}></div>
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
          backgroundColor: "#0050A4",
          padding: "15px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
        }}
        className="mt-5"
      >
        <span style={{ margin: 0, fontSize: "20px", fontWeight: "400" }}>
          Main Contact
        </span>
      </div>

      <div
        style={{
          padding: "20px 20px 3px 20px",
          backgroundColor: "#e9edf5",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        {companyUserData && companyUserData.length > 0 ? (
          companyUserData.map((user) => (
            <div
              key={user.id}
              style={{
                border: "1px solid #0050A4",
                borderRadius: "5px",
                padding: "15px",
                marginBottom: "15px",
                color: "#0050A4",
                backgroundColor: "#f9f9f9",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: "0 1 24%" }}>
                  <strong>Name</strong>
                  <br />
                  <span>{user.name}</span>
                </div>
                <div style={{ flex: "0 1 24%" }}>
                  <strong>Phone</strong>
                  <br />
                  <span>{user.phone}</span>
                </div>
                <div style={{ flex: "0 1 24%" }}>
                  <strong>Designation</strong>
                  <br />
                  <span>{user.designation}</span>
                </div>
                <div style={{ flex: "0 1 24%" }}>
                  <strong>Email</strong>
                  <br />
                  <span>{user.email}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center", color: "#0050A4" }}>
            No contact added
          </div>
        )}

      </div>
    </div>
  );
};

export default ViewPage;
