import React, { useEffect, useState, useRef } from 'react';
import mysiteInspectionLogo from './img/mysiteInspectionLogo.png';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../services/ApiCall";
import config from "../../config/config.json";
import PDF from './pdf';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { S3 } from 'aws-sdk';
import { Button, Form, Modal } from 'react-bootstrap';

const VieInspectionReportPDF = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [uploadedImages, setUploadedImages] = useState({});
  const [modules, setModules] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [requestData, setRequestData] = useState();
  const [desabled, setDesabled] = useState(false);
  const pdfRef = useRef();
  const [redoShow, setRedoShow] = useState(false);
  const [redoDataChecked, setRedoDataChecked] = useState(false);
  const [redoPictureChecked, setRedoPictureChecked] = useState(false);
  const [dataTextArea, setDataTextArea] = useState('');
  const [pictureTextArea, setPictureTextArea] = useState('');

  const handleClose = () => {
    setRedoShow(false);
    setRedoDataChecked(false);
    setRedoPictureChecked(false);
    setDataTextArea('');
    setPictureTextArea('');
  }
  const handleShow = () => setRedoShow(true);

  const getRequestUserData = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestUserData`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess) {
        setRequestData(data.data);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };
  const syncToZoho = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}syncToZoho`,
        {},
        { requestId: location.state?.requestId },
        "POST"
      );
      if (isSuccess) {
      }

    } catch (error) {
      console.error("Failed to fetch data:", error);

    }
  };

  const approveRequest = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}approveRequest`,
        {},
        { requestId: location.state?.requestId },
        "POST"
      );
      if (isSuccess) {
      }

    } catch (error) {
      console.error("Failed to fetch data:", error);

    }
  };

  const sendToNewRequest = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}sendToNewRequest`,
        {},
        {
          requestId: location.state?.requestId
        },
        "POST"
      );
      if (isSuccess) {
        handleClose();
      }

    } catch (error) {
      console.error("Failed to fetch data:", error);

    }
  };

  const redoRequest = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}redoRequest`,
        {},
        {
          requestId: location.state?.requestId, redoDataChecked, redoPictureChecked, dataTextArea, pictureTextArea
        },
        "POST"
      );
      if (isSuccess) {
        handleClose();
      }

    } catch (error) {
      console.error("Failed to fetch data:", error);

    }
  };

  const getRequestPictures = async () => {
    if (location.state?.requestId) {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POST_LOGIN_API_URL}getRequestPictures`,
          {},
          { requestId: location.state?.requestId },
          "GET"
        );
        if (isSuccess && data.data.length) {
          setUploadedImages(data.data[0]);

        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    }
  };

  const getDataFormQuestionList = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getPDFQuestions`,
        {},
        { type: "ALL" },
        "GET"
      );
      if (isSuccess && Array.isArray(data.data)) {
        setModules(data.data);
      } else {
        console.error("API response is not an array:", data);
        setModules([]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setModules([]);
    }
  };

  const getRequestDataFormAnswer = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestDataFormAnswer`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess) {
        setAnswers(data.data);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const s3 = new S3({
    accessKeyId: 'AKIAR4KASQXIS5GCSYGH',
    secretAccessKey: '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',
    region: 'us-east-1',
  });

  const toDataURL = (url) => {
    if (url) {
      return new Promise((resolve, reject) => {
        const getRequestDataFormAnswer = async () => {
          try {
            const { isSuccess, data } = await apiCall(
              `${config.POST_LOGIN_API_URL}getProxyImageUrl`,
              {},
              { url: encodeURIComponent(url) },
              "GET"
            );

            if (isSuccess && data && data.data) {
              const proxyUrl = `data:image/jpeg;base64,${data.data}`;

              const img = new Image();

              img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/jpeg', 0.6));
              };

              img.onerror = (error) => reject(error);
              img.src = proxyUrl;
            } else {
              reject(new Error('Image data not available from proxy.'));
            }
          } catch (error) {
            console.error("Failed to fetch data:", error);
            reject(error);
          }
        };

        getRequestDataFormAnswer();
      });
    }
  };


  const replaceImagesWithBase64 = async () => {
    const images = [...document.querySelectorAll('img')].slice(1);

    const imagePromises = Array.from(images).map(async (img) => {
      const src = img.src;
      const reportId = img.getAttribute('reportId');
      if (!src.startsWith('data:')) {
        try {
          img.src = await toDataURL(uploadedImages[reportId]);
        } catch (error) {
          console.error('Failed to convert to Base64 for:', src, error);
        }
      }
    });
    await Promise.all(imagePromises);
  };

  const generatePDF = async () => {
    setDesabled(true);
    await replaceImagesWithBase64();

    const input = pdfRef.current;
    const pdf = new jsPDF('p', 'mm', 'a3');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const canvas = await html2canvas(input, {
      scale: 1,
      useCORS: true,
    });

    const imgData = canvas.toDataURL('image/jpeg', 0.7);
    const imgProps = pdf.getImageProperties(imgData);
    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight, undefined, 'FAST');
    heightLeft -= pdfHeight;

    while (heightLeft > 0) {
      position -= pdfHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight, undefined, 'FAST');
      heightLeft -= pdfHeight;
    }

    const pdfBlob = pdf.output('blob');

    const pdfUrl = URL.createObjectURL(pdfBlob);

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${location.state.requestData.companyName}_${location.state.requestData.requestId}.pdf`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(pdfUrl);
    setDesabled(false);

  };

  const uploadToS3 = async (pdfBlob) => {
    try {
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_report.pdf`;
      const formData = new FormData();
      formData.append("file", pdfBlob, fileName);
      formData.append("fileName", fileName);
      formData.append("filePath", "msi/");

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(`${config.AUTH_API_URL}uploadFile`, requestOptions);
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const result = await response.json();
      const url = result.url;
      console.log('PDF uploaded successfully. File URL:', url);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Set the download attribute
      document.body.appendChild(link); // Append the link to the body
      link.click(); // Programmatically trigger the click event to download
      document.body.removeChild(link); // Remove the link after download
    } catch (error) {
      console.error("Error:", error);
    }
  };


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getDataFormQuestionList();
      getRequestUserData();
      getRequestPictures();
      getRequestDataFormAnswer();
    }
  }, [navigate]);

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <div style={styles.headerLeft}>     
          <Link to="/request-form" state={{ requestId: location.state?.requestId }}>
            <button style={styles.headerButton}>Edit Request</button>
          </Link>
          <button style={styles.headerButton} onClick={syncToZoho}>Sync To Zoho</button>
          <button onClick={generatePDF} style={styles.headerButton}>{desabled ? 'Generating' : 'Generate PDF'} </button>
          {requestData?.status == '2' && <>
            <button style={styles.headerButton} onClick={handleShow}>Re Do</button>
            <button style={styles.headerButton} onClick={approveRequest}>Approve</button>
          </>}
          {requestData?.status == '4' && <>
            <button style={styles.headerButton} onClick={handleShow}>Re Do</button>
            <button style={styles.headerButton} onClick={sendToNewRequest}>Send To New Request</button>
          </>}


        </div>
      </header>
      <div ref={pdfRef}>
        <div className='header' style={{ backgroundColor: '#DCEDFF', padding: '30px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>

          <div>
            <img
              src={mysiteInspectionLogo} alt="MySiteInspection.com"
              style={styles.logo}
            />
          </div>
          <div>
            <span style={{ marginRight: '1em' }}>Support@mysiteinspection.com</span>
            <strong style={{ marginRight: '1em' }}>(888)551-3854</strong>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={{ fontSize: "29px", fontWeight: 600 }}>
            Site Inspection Compliance Report
          </div>
        </div>

        <div >
          {modules.map((module, index) => (
            <PDF requestData={requestData} index={index} module={module} answer={answers[module.moduleTitle]} />
          ))}
        </div>

        <Modal show={redoShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Re-Do</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Check
                type="checkbox"
                label="ReDo-Data Form"
                checked={redoDataChecked}
                onChange={() => setRedoDataChecked(!redoDataChecked)}
              />
              {redoDataChecked && (
                <div>
                  <Form.Group controlId="dataInput">
                    <Form.Label>Site Admin Email</Form.Label>
                    <Form.Control
                      type="text"
                      value={requestData?.siteAdminEmail}
                      readOnly
                    />
                    {console.log(requestData)}
                  </Form.Group>
                  <Form.Group controlId="dataTextArea">
                    <Form.Label>Comments For Site Admin</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={dataTextArea}
                      onChange={(e) => setDataTextArea(e.target.value)}
                    />
                  </Form.Group>
                </div>
              )}

              <Form.Check
                type="checkbox"
                label="ReDo-Picture Form"
                className='mt-4'
                checked={redoPictureChecked}
                onChange={() => setRedoPictureChecked(!redoPictureChecked)}
              />
              {redoPictureChecked && (
                <div>
                  <Form.Group controlId="pictureInput">
                    <Form.Label>Site Manager Email</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly
                      value={requestData?.siteManagerEmail}
                    />
                  </Form.Group>
                  <Form.Group controlId="pictureTextArea">
                    <Form.Label>Comments For Site Manager</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={pictureTextArea}
                      onChange={(e) => setPictureTextArea(e.target.value)}
                    />
                  </Form.Group>
                </div>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>

            <Button variant="primary" onClick={redoRequest}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
  logo: {
    height: '40px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1em'
  },
  headerLeft: {
    display: 'flex',
    gap: '10px',
  },
  headerButton: {
    backgroundColor: '#0050A4',
    color: 'white',
    border: 'none',
    marginRight: '2em',
    padding: '10px 15px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  generatePDFButton: {
    backgroundColor: '#0050A4',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
  },
};

export default VieInspectionReportPDF;
