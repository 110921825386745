import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import './CompaniesOverview.css';
import numberofCompanyIcon from './img/numberofCompanyIcon.png';
import numberofLocation from './img/numberofLocationIcon.png';
import { apiCall } from "../../services/ApiCall.js";
import config from "../../config/config.json";
import { useNavigate } from 'react-router-dom';

const CompaniesOverview = () => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState([]);
  const [sitesData, setSitesData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedSitesYear, setSelectedSitesYear] = useState(new Date().getFullYear());
  const [selectedSitesMonth, setSelectedSitesMonth] = useState(new Date().getMonth());

  const getCompanyGraphData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyGraphData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      setCompanyData(data?.data);
    }
  };

  const getSitesGraphData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesGraphData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      setSitesData(data?.data);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getCompanyGraphData();
    getSitesGraphData();
  }, []);

  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      sparkline: { enabled: true },
    },
    stroke: { curve: 'smooth', width: 2 },
    colors: ['#4e79a7'],
    tooltip: { enabled: false },
    grid: { show: false },
    xaxis: { labels: { show: false }, axisBorder: { show: false }, axisTicks: { show: false } },
    yaxis: { labels: { show: false } },
  };

  const filterCompanyData = () => {
    return companyData.filter(company => {
      const createdAt = new Date(company.createdAt); // Adjust according to your date format
      return (
        createdAt.getFullYear() === selectedYear &&
        createdAt.getMonth() === selectedMonth
      );
    });
  };

  const filteredCompanies = filterCompanyData();
  const getMonthlyCompanyData = () => {
    const monthlyData = Array(12).fill(0);
    companyData.forEach(company => {
      const createdAt = new Date(company.createdAt);
      if (createdAt.getFullYear() === selectedYear) {
        const month = createdAt.getMonth();
        monthlyData[month] += 1;
      }
    });
    return monthlyData;
  };

  const companiesChartSeries = [{ data: getMonthlyCompanyData() }];

  const filterSitesData = () => {
    return sitesData.filter(sites => {
      const createdAt = new Date(sites.createdAt);
      return (
        createdAt.getFullYear() === selectedSitesYear &&
        createdAt.getMonth() === selectedSitesMonth
      );
    });
  };

  const filteredsites = filterSitesData();
  const getMonthlySitesData = () => {
    const monthlyData = Array(12).fill(0);
    sitesData.forEach(company => {
      const createdAt = new Date(company.createdAt);
      if (createdAt.getFullYear() === selectedSitesYear) {
        const month = createdAt.getMonth();
        monthlyData[month] += 1;
      }
    });
    return monthlyData;
  };

  const sitesChartSeries = [{ data: getMonthlySitesData() }];


  return (
    <div className="companies-overview">
      <h2 className="overview-title mb-4">Companies & Sites Overview</h2>
      <div className="overview-content">
        <div className="overview-row">

          <div className="overview-card">
            <div className="card-icon"><img src={numberofCompanyIcon} /></div>
            <div className="card-info">
              <span className="card-value">{companyData && companyData.length}</span>
              <span className="card-label">Total Number Of Companies</span>
            </div>
          </div>

          <div className="overview-chart">
            <div className="chart-content">
              <div className="chart-info">
                <span className="chart-title" style={{ fontSize: '16px' }}>Companies Addition Rate</span>
                <div className="chart-value">
                  <span className="value" style={{ fontSize: '24px' }}>{filteredCompanies.length || 0}</span>
                  <div className="sub-value" style={{ fontSize: '14px' }}>Out of {companyData.length || 0}</div>
                </div>
              </div>
              <div className="chart-container" style={{ width: '45%' }}>
                <div className="chart-date">
                <select value={selectedYear} onChange={(e) => setSelectedYear(Number(e.target.value))}>
  {[...Array(5)].map((_, index) => {
    const currentYear = new Date().getFullYear();
    const year = currentYear - index;
    return (
      <option key={year} value={year}>
        {year}
      </option>
    );
  })}
</select>

<select value={selectedMonth} onChange={(e) => setSelectedMonth(Number(e.target.value))}>
  {Array.from(
    { length: selectedYear === new Date().getFullYear() ? new Date().getMonth() + 1 : 12 },
    (_, index) => (
      <option key={index} value={index}>
        {new Date(0, index).toLocaleString('default', { month: 'long' })}
      </option>
    )
  )}
</select>

                </div>
                <Chart options={chartOptions} series={companiesChartSeries} type="line" height={60} />
              </div>
            </div>
          </div>


        </div>
        <div className="overview-row">
          <div className="overview-card">
            <div className="card-icon"><img src={numberofLocation} /> </div>
            <div className="card-info">
              <span className="card-value">{sitesData && sitesData.length}</span>
              <span className="card-label">Total Number Of Sites</span>
            </div>
          </div>


          <div className="overview-chart">
            <div className="chart-content">
              <div className="chart-info">
                <span className="chart-title" style={{ fontSize: '16px' }}>Sites Addition Rate</span>
                <div className="chart-value">
                  <span className="value" style={{ fontSize: '24px' }}>{filteredsites.length || 0}</span>
                  <div className="sub-value" style={{ fontSize: '14px' }}>Out of {sitesData.length || 0}</div>
                </div>
              </div>
              <div className="chart-container" style={{ width: '45%' }}>
                <div className="chart-date">
                <select value={selectedSitesYear} onChange={(e) => setSelectedSitesYear(Number(e.target.value))}>
  {[...Array(5)].map((_, index) => {
    const currentYear = new Date().getFullYear();
    const year = currentYear - index;
    return (
      <option key={year} value={year}>
        {year}
      </option>
    );
  })}
</select>

<select value={selectedSitesMonth} onChange={(e) => setSelectedSitesMonth(Number(e.target.value))}>
  {Array.from(
    { length: selectedSitesYear === new Date().getFullYear() ? new Date().getMonth() + 1 : 12 },
    (_, index) => (
      <option key={index} value={index}>
        {new Date(0, index).toLocaleString('default', { month: 'long' })}
      </option>
    )
  )}
</select>

                </div>
                <Chart options={chartOptions} series={sitesChartSeries} type="line" height={60} />
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>
  );
};

export default CompaniesOverview;
