import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import './CircularChartComponent.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { apiCall } from "../../services/ApiCall.js";
import config from "../../config/config.json";

const CircularChartComponent = () => {
  const navigate = useNavigate();

  const [requestData, setRequestData] = useState([]);
  const currentMonth = new Date().getMonth(); 
  const currentYear = new Date().getFullYear(); 
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [chartSeries, setChartSeries] = useState([0, 0, 0, 0]);

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const getRequestData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getRequestGraphData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      const requestDatas = data?.data.reduce((acc, request) => {
        const { status, id, createdAt, updatedAt } = request;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push({ id, createdAt, updatedAt });
        return acc;
      }, {});
      setRequestData(requestDatas);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getRequestData(); 
  }, []);

  useEffect(() => {
    if (requestData) {
      const newRequest = requestData[0] ? requestData[0].filter(item => {
        const itemDate = new Date(item.updatedAt);
        return itemDate.getMonth() === selectedMonth && itemDate.getFullYear() === currentYear;
      }).length : 0;

      const inRequest = requestData[1] ? requestData[1].filter(item => {
        const itemDate = new Date(item.updatedAt);
        return itemDate.getMonth() === selectedMonth && itemDate.getFullYear() === currentYear;
      }).length : 0;

      const completedRequest = requestData[2] ? requestData[2].filter(item => {
        const itemDate = new Date(item.updatedAt);
        return itemDate.getMonth() === selectedMonth && itemDate.getFullYear() === currentYear;
      }).length : 0;

      const approvedRequest = requestData[4] ? requestData[4].filter(item => {
        const itemDate = new Date(item.updatedAt);
        return itemDate.getMonth() === selectedMonth && itemDate.getFullYear() === currentYear;
      }).length : 0;

      setChartSeries([newRequest, inRequest, completedRequest, approvedRequest]);
    }
  }, [selectedMonth, requestData]); 

  const chartOptions = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '45%',
        },
        track: {
          background: '#f0f4f8',
        },
        dataLabels: {
          show: false,
        },
      },
    },
    colors: ['#5D8DEC', '#3366CC', '#476195', '#8DADEB'],
    labels: ['New Requests', 'In-Progress Requests', 'To-Be-Approved Requests', 'Approved Inspections'],
  };

  const handlePreviousMonth = () => {
    setSelectedMonth(prevMonth => prevMonth === 0 ? 11 : prevMonth - 1);
  };

  const handleNextMonth = () => {
    setSelectedMonth(prevMonth => prevMonth === 11 ? 0 : prevMonth + 1);
  };

  return (
    <div className="circular-chart-container">
      <div className="circular-chart-header">
        <h3 style={{ backgroundColor: 'white', fontWeight: 'bold' }}>Monthly Requests Status</h3>
        <div className="date-selector">
          <span onClick={handlePreviousMonth}>&lt;</span>
          <span>{`${monthNames[selectedMonth]}, ${currentYear}`}</span>
          <span onClick={handleNextMonth}>&gt;</span>
        </div>
      </div>
      <div className="circular-chart-body">
        <Chart options={chartOptions} series={chartSeries} type="radialBar" height={310} />
        <div className="request-list" style={{ textDecoration: 'none' }}>
          <NavLink to="/new-request" className="request-item">
            <span className="dot blue"></span>
            <span>New Requests</span>
            <span className="request-count">{chartSeries[0]}</span>
          </NavLink>
          <NavLink to="/in-progress" className="request-item">
            <span className="dot grey"></span>
            <span>In-Progress Requests</span>
            <span className="request-count">{chartSeries[1]}</span>
          </NavLink>
          <NavLink to="/to-be-approved" className="request-item">
            <span className="dot light-blue"></span>
            <span>To-Be-Approved Requests</span>
            <span className="request-count">{chartSeries[2]}</span>
          </NavLink>
          <NavLink to="/approved-Request" className="request-item">
            <span className="dot white"></span>
            <span>Approved Inspections</span>
            <span className="request-count">{chartSeries[3]}</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default CircularChartComponent;
