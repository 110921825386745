import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './InvoiceDetails';
import './styles.css';
import icon from './img/filtericon.png';
import { apiCall } from "../../services/ApiCall.js";
import config from "../../config/config.json";

const InvoiceTable = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');

  const getAllInvoice = async () => {
    setLoading(true);
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getAllInvoice`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setData(data?.data?.reverse());
    }
    setLoading(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getAllInvoice();
    }
  }, []);
  const filteredData = data.filter(item =>
    Object.values(item).some(val =>
      val.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="container mt-0 ">
        <div className="controls">
          <div className="entries-control">
            Show
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
            >
              <option value={5}>50</option>
              <option value={10}>100</option>
              <option value={25}>250</option>
              <option value={50}>500</option>
            </select>
            entries
          </div>
          <div className="search-control">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <span><img src={icon} alt='icon' /> </span>
        </div>

        <div className='invoiceTableBoxing mt-5' style={{ fontSize: '14px' }}>
          <table className="inspections-table " >


            <thead className="thead">
              <tr>
                <th style={{ width: '25%' }}>Zoho Invoice ID</th>
                <th style={{ width: '25%' }}>Quantity</th>
                <th style={{ width: '25%' }}>Amount</th>
                <th style={{ width: '25%' }}>Invoice Created Date</th>
                <th style={{ width: '10%' }}>Action</th>
              </tr>
            </thead>

            <tbody>

              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.invoiceId}</td>
                  <td>{Number(item.invoiceAmount) / 95}</td>
                  <td>${item.invoiceAmount}</td>
                  <td>{formatDate(item.invoiceRaiseDate)}</td>
                  <td>
                    <Link to={`/invoice/${item.invoiceId}`} className="details-btn">
                      Details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>
        <div className="pagination">
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
          {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, i) => (
            <button key={i} onClick={() => paginate(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
              {i + 1}
            </button>
          ))}
          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}>Next</button>
        </div>
      </div>
    </>
  );
};

export default InvoiceTable;