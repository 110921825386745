
import React from 'react';
import './MainContactForm.css';
import exporticon from './img/exportIcon.png';
import deleteicon from './img/deleteicon.png';

const MainContactForm = ({ onPrevious }) => {
  return (
    <div className="main-contact-form">
      <div className="form-header">
        <h2>Add Company</h2>
        <button className="export-button">
          <img src={exporticon} alt="Export"/> Export
        </button>
      </div>
      <div className="form-tabs">
        <button>Company Details</button>
        <button className="active">Main Contact</button>
      </div>
      <div className="company-contact">
        <div className="contact-header">
          <h3>Company Contact</h3>
          <div>
            <button className="add-button">+ Add</button>
            <img src={deleteicon} />
          </div>
        </div>
       <div className='form'>
       <form>
          
          <div className="form-row">
            <div className="form-group">
              <label>First Name</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input type="text" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Email</label>
              <input type="email" />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input type="tel" />
            </div>
            <div className="form-group">
              <label>Designation</label>
              <select>
                <option>Select Designation</option>
                {/* Add more options here */}
              </select>
            </div>
          </div>
          <div className="form-actions">
            <button type="button" className="previous-button" onClick={onPrevious}>Previous</button>
            <button type="submit" className="save-button">+ Save</button>
          </div>
        </form>
       </div>
      </div>
    </div>
  );
};

export default MainContactForm;