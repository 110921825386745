// AddDesignation.js
import React, { useState } from 'react';
import './AddDesignation.css';
import img from './img/Close.png'

const AddDesignation = ({ onAddDesignation, onClose }) => {
  const [designationName, setDesignationName] = useState('');

  const handleAddClick = () => {
    if (designationName.trim() !== '') {
      onAddDesignation(designationName);
      setDesignationName('');
      onClose(); // Close the form after adding
    }
  };

  const handleClearClick = () => {
    setDesignationName('');
  };

  return (
    <div className="add-designation-page">
      <div className="add-designation-container">
        <div className='Add-Designation-form'>
        <h2>Add Designation</h2>
        <span className='Add-designation-close-btn'> <img src={img} alt='' onClick={onClose}/></span>
        </div>
        <div className="form-group">
          <span>Designation Name</span>
          <input
            type="text"
            value={designationName}
            onChange={(e) => setDesignationName(e.target.value)}
            placeholder="Enter Designation Name"
          />
        </div>
        <div className="form-actions">
          <button className="clear-btn" onClick={handleClearClick}>Clear</button>
          <button className="add-btn" onClick={handleAddClick}>Add</button>
        </div>
        {/* <button className="close-btn" onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
};

export default AddDesignation;
