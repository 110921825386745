import React, { useState } from 'react';
import './UserTable.css';
import img1 from './img/img1.png';
import img2 from './img/img2.png';
import img from './img/Close.png';

const UserTable = () => {
  const [users, setUsers] = useState([
    { id: 1, firstName: 'Sharon', lastName: 'Fernz', designation: 'Managing Director', phoneNumber: '123-456-7890', email: 'sharon.fernz@mysiteinspection.com' },
    { id: 2, firstName: 'Sharon', lastName: 'Fernz', designation: 'Super Admin', phoneNumber: '123-456-7890', email: 'sharon.fernz@mysiteinspection.com' },
    { id: 3, firstName: 'Sharon', lastName: 'Fernz', designation: 'Admin', phoneNumber: '123-456-7890', email: 'sharon.fernz@mysiteinspection.com' },
    { id: 4, firstName: 'Sharon', lastName: 'Fernz', designation: 'HR Department Head', phoneNumber: '123-456-7890', email: 'sharon.fernz@mysiteinspection.com' },
    { id: 5, firstName: 'Sharon', lastName: 'Fernz', designation: 'Managing Director', phoneNumber: '123-456-7890', email: 'sharon.fernz@mysiteinspection.com' },
  ]);


  
  const [showAddUser, setShowAddUser] = useState(false);
  const [newUser, setNewUser] = useState({
    firstName: '',
    lastName: '',
    designation: '',
    phoneNumber: '',
    email: '',
  });

  const [editingUserId, setEditingUserId] = useState(null);

  const handleAddUser = () => {
    setShowAddUser(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSave = () => {
    if (editingUserId !== null) {
      setUsers(users.map(user => (user.id === editingUserId ? { id: editingUserId, ...newUser } : user)));
      setEditingUserId(null);
    } else {
      setUsers([...users, { id: users.length + 1, ...newUser }]);
    }

    setShowAddUser(false);
    setNewUser({
      firstName: '',
      lastName: '',
      designation: '',
      phoneNumber: '',
      email: '',
    });
  };

  const handleCancel = () => {
    setShowAddUser(false);
    setNewUser({
      firstName: '',
      lastName: '',
      designation: '',
      phoneNumber: '',
      email: '',
    });
    setEditingUserId(null);
  };

  const handleEditUser = (user) => {
    setNewUser(user);
    setEditingUserId(user.id);
    setShowAddUser(true);
  };

  const handleDeleteUser = (userId) => {
    setUsers(users.filter(user => user.id !== userId));
  };

  return (
    <div className="user-table-container">
      <button className="add-user-btn" onClick={handleAddUser}>Add User</button>
      {!showAddUser ? (
        <table className="user-table">
          <div className='boxing'>
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Designation</th>
                <th>Phone Number</th>
                <th>Mail Id</th>
                <th style={{textAlign:"center"}}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.designation}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.email}</td>
                  <td style={{textAlign:'right'}}>
                    <img
                      src={img1}
                      alt='edit'
                      onClick={() => handleEditUser(user)}
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    />
                    <img
                      src={img2}
                      alt='delete'
                      onClick={() => handleDeleteUser(user.id)}
                      style={{ cursor: 'pointer' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      ) : (
        <div className="add-user-form">
          <div className='add-user-header'>
          <h2>{editingUserId !== null ? 'Edit User' : 'Add User'}</h2>
          <button className='Add-designation-close-btn' onClick={handleCancel} style={{backgroundColor:'white', border:'none'}} > <img src={img} alt='' /></button>
             </div>
     
          
          <div className="form-row">
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                name="firstName"
                value={newUser.firstName}
                onChange={handleInputChange}
                placeholder="Enter your first name"
              />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input
                type="text"
                name="lastName"
                value={newUser.lastName}
                onChange={handleInputChange}
                placeholder="Enter your last name"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Designation</label>
              <select
                name="designation"
                value={newUser.designation}
                onChange={handleInputChange}
              >
                <option value="">Select Designation</option>
                <option value="Managing Director">Managing Director</option>
                <option value="Super Admin">Super Admin</option>
                <option value="Admin">Admin</option>
                <option value="HR Department Head">HR Department Head</option>
              </select>
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={newUser.phoneNumber}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group full-width">
              <label>Email Id</label>
              <input
                type="email"
                name="email"
                value={newUser.email}
                onChange={handleInputChange}
                placeholder="Enter your email ID"
              />
            </div>
          </div>
          <div className="form-actions">
            <button className="cancel-btn" onClick={handleCancel}>Cancel</button>
            <button className="save-btn" onClick={handleSave}>{editingUserId !== null ? 'Update' : 'Save'}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserTable;
