import React from "react";
import Modal from "react-modal";
import { BsExclamationTriangle, BsDash } from "react-icons/bs";
import cancelBtnIcon from "./img/cancelInspection.png";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";
// import { height } from "@fortawesome/free-solid-svg-icons/fa0";

function CancelInspectionModal({ isOpen, onRequestClose, onConfirm }) {
  const modalStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
      height:'320px',
    
      padding: "0px", // Removed padding for full-width header
      backgroundColor: "#eaf2fb", // Light blue background
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      // borderRadius: "10px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const headerContainerStyle = {
    backgroundColor: "#0050A4",
    padding: "10px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // borderTopLeftRadius: "10px",
    // borderTopRightRadius: "10px",
  };

  const headerTextStyle = {
    color: "white",
    fontSize: "18px",
  };

  const closeIconStyle = {
    color: "black",
    cursor: "pointer",
    // borderRadius:'50%',
    // backgroundColor:'#E6F1F7',
    fontSize: "20px",
  };

  const bodyStyle = {
    padding: "30px",
    textAlign: "center",
  };

  const headerStyle = {
    color: "#0050A4", // Darker blue color for the header
    marginBottom: "10px",
    fontSize: "24px",
  };

  const paragraphStyle = {
    color: "#666",
    marginBottom: "30px",
    fontSize: "14px",
  };

  const actionsStyle = {
    display: "flex",
    justifyContent: "space-around",
    gap: "20px",
  };

  const buttonStyle = {
    backgroundColor: "#0050A4", // Darker blue button
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "16px",
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#ffffff",
    color: "#0050A4",
    border: "1px solid #0050A4",
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyles}>
      <div>
        <div style={headerContainerStyle}>
          <span style={headerTextStyle}>Cancel Inspection</span>
          <span style={closeIconStyle} onClick={onRequestClose}> <img src={cancelBtnIcon} style={{borderRadius:'50%',backgroundColor:'#E6F1F7',padding:'5px' }} /> </span>
          {/* <BsDash style={closeIconStyle} onClick={onRequestClose} /> */}
        </div>
        <div style={bodyStyle}>
          <BsExclamationTriangle
            style={{ width: "50px", height: "70px", color: "#0050A4" }}
          />
          <h2 style={headerStyle}>Are you Sure?</h2>
          <p style={paragraphStyle}>You won’t be able to revert the request</p>
          <div style={actionsStyle}>
            <button onClick={onConfirm} style={buttonStyle} className="">
              Yes, Cancel The Request
            </button>
            <button onClick={onRequestClose} style={cancelButtonStyle}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CancelInspectionModal;
