import React, { useState } from 'react';
import closeIcon from './img/Close.png';
import passwordViewIcon from './img/passwordViewIcon.png';

function ChangePasswordModal({ onClose }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordChange = () => {
    console.log('Password changed!');
    onClose();
  };

  const toggleVisibility = (field) => {
    switch (field) {
      case 'current':
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case 'new':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirm':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContainer}>
        <div style={styles.modalHeader}>
          <h2 style={styles.modalTitle}>Change your password</h2>
          <button style={styles.closeButton}  onClick={onClose}>
          < img src={closeIcon} />
          </button>
        </div>
        <div style={styles.modalContent}>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="current-password">Password</label>
            <div style={styles.passwordInput}>
              <input
                type={showCurrentPassword ? 'text' : 'password'}
                id="current-password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                style={styles.input}
              />
              <button
                style={styles.togglePasswordVisibility}
                onClick={() => toggleVisibility('current')}
              >
                <img
                  src={showCurrentPassword ? passwordViewIcon : passwordViewIcon}
                  alt="Toggle Visibility"
                />
              </button>
            </div>
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="new-password">New Password</label>
            <div style={styles.passwordInput}>
              <input
                type={showNewPassword ? 'text' : 'password'}
                id="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                style={styles.input}
              />
              <button
                style={styles.togglePasswordVisibility}
                onClick={() => toggleVisibility('new')}
              >
                <img
                  src={showNewPassword ? passwordViewIcon : passwordViewIcon}
                  alt="Toggle Visibility"
                />
              </button>
            </div>
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="confirm-password">Confirm Password</label>
            <div style={styles.passwordInput}>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={styles.input}
              />
              <button
                style={styles.togglePasswordVisibility}
                onClick={() => toggleVisibility('confirm')}
              >
                <img
                  src={showConfirmPassword ? passwordViewIcon : passwordViewIcon}
                  alt="Toggle Visibility"
                />
              </button>
            </div>
          </div>
        </div>
        <div style={styles.modalFooter}>
          <button style={styles.setPasswordButton} onClick={handlePasswordChange}>
            Set Password
          </button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
   
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: '#fff',
    padding:'1em',
    width: 'auto',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  modalHeader: {
    padding: '20px',
    backgroundColor: 'white',
    borderBottom: '1px solid #0050A4',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalTitle: {
    margin: 0,
    fontSize: '18px',
    color: '#0050A4',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    color: '#999',
  },
  modalContent: {
    padding: '20px',
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  label: {
    fontSize: '15px',
    color: '#0050A4',
    marginRight: '10px',
    flexBasis: '35%',
  },
  passwordInput: {
    display: 'flex',
    color: '#0050A4',
    alignItems: 'center',
    position: 'relative',
    flexBasis: '65%',
  },
  input: {
    width: '100%',
    marginLeft:'2em',
    padding: '10px',
    paddingRight: '30px',
    color:'#0050A4',
    border: '1px solid #0050A4',
    borderRadius: '4px',
  },
  togglePasswordVisibility: {
    background: 'none',
    border: 'none',
    position: 'absolute',
    right: '10px',
    cursor: 'pointer',
  },
  modalFooter: {
    // padding: '20px',
    paddingRight:'0.5em',
    paddingLeft:'0.5em',
    textAlign: 'center',
    backgroundColor: 'white',

  },
  setPasswordButton: {
    backgroundColor: '#0050A4',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '11px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '15px',
    width: '100%',
  },
};

export default ChangePasswordModal;
