// DesignationTable.js
import React, { useState } from 'react';
import './Designation.css';
import img1 from './img/img1.png';
import img2 from './img/img2.png';
import AddDesignation from './AddDesignation.js';

const DesignationTable = () => {
  const [designations, setDesignations] = useState([
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Super Admin' },
    { id: 3, name: 'Managing Director' },
    { id: 4, name: 'HR Department Head' },
    { id: 5, name: 'Manager - Finance' },
  ]);

  const [isEditing, setIsEditing] = useState(null);
  const [newDesignationName, setNewDesignationName] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);

  const handleEditDesignation = (designation) => {
    setIsEditing(designation.id);
    setNewDesignationName(designation.name);
  };

  const handleSaveEdit = (designationId) => {
    setDesignations(designations.map(designation =>
      designation.id === designationId ? { ...designation, name: newDesignationName } : designation
    ));
    setIsEditing(null);
    setNewDesignationName('');
  };

  const handleDeleteDesignation = (designationId) => {
    setDesignations(designations.filter(designation => designation.id !== designationId));
  };

  const handleAddDesignation = (designationName) => {
    const newDesignation = {
      id: designations.length + 1,
      name: designationName,
    };
    setDesignations([...designations, newDesignation]);
  };

  const handleAddButtonClick = () => {
    setShowAddForm(true);
  };

  const handleCloseAddForm = () => {
    setShowAddForm(false);
  };

  return (
    <>
    <button className="add-designation-btn" onClick={handleAddButtonClick}>
    Add Designation
  </button>
    
    
      {showAddForm && (
        <AddDesignation
          onAddDesignation={handleAddDesignation}
          onClose={handleCloseAddForm}
        />
      )}
      <div className="table-container">
      <div className='boxing'>
      <table>
          <thead >
            <tr style={{borderBottom:'1px solid #ccc'}}>
              <th style={{width:'8%'}}>#</th>
              <th style={{ width: '75%' }}>Designation Name</th>
              <th style={{ width: '10%',textAlign:'center' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {designations.map((designation) => (
              <tr key={designation.id} style={{borderBottom:'1px solid #ccc'}}>
                <td>{designation.id}</td>
                <td >
                  {isEditing === designation.id ? (
                    <input
                      type="text"
                      value={newDesignationName}
                      onChange={(e) => setNewDesignationName(e.target.value)}
                    />
                  ) : (
                    designation.name
                  )}
                </td>
                <td style={{textAlign:'right'}}>
                  {isEditing === designation.id ? (
                    <button onClick={() => handleSaveEdit(designation.id)} style={{backgroundColor:'#0050A4',color:'white',borderRadius:"10px",padding:'8px 15px'}} >Save</button>
                  ) : (
                    <>
                      <img
                        src={img1}
                        alt='edit'
                        onClick={() => handleEditDesignation(designation)}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                      />
                      <img
                        src={img2}
                        alt='delete'
                        onClick={() => handleDeleteDesignation(designation.id)}
                        style={{ cursor: 'pointer' }}
                      />
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    
    </>
  );
};

export default DesignationTable;
