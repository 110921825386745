import React, { useState } from "react";
import Modal from "react-modal";
import "bootstrap/dist/css/bootstrap.css";
import AddInspectionModal from "./AddInspectionModal";
import "./style.css";
import frame from "./img/Frame 1000006023.png";
import img2 from './img/img2.png';
import img3 from './img/img3.png';



const initialData = [
  {
    id: 1,
    araId: "WAYvmqNqk",
    company: "Ghrix",
    street: "Vinemoss ln",
    city: "Cypress",
    state: "Arizona",
    zipcode: "1589888",
    Status: "Active",
  },
  
  {
    id: 2,
    araId: "WAYvmqNqk",
    company: "1st Choice Recovery LLC",
    street: "Vinemoss ln",
    city: "Summerville",
    state: "South Carolina",
    zipcode: "1589888",
   
    Status: "Active",
  },
  {
    id: 3,
    araId: "WAYvmqNqk",
    company: "59 Second Recovery LLC",
    street: "PO Box 3500",
    city: "Cypress",
    state: "Arizona",
    zipcode: "1589888",
  
    Status: "Active",
  },
  {
    id: 4,
    araId: "WAYvmqNqk",
    company: "A & A Towing and Recovery MO",
    street: "Vinemoss ln",
    city: "Cypress",
    state: "Arizona",
    zipcode: "1589888",
   
    Status: "Active",
  },
  {
    id: 5,
    araId: "WAYvmqNqk",
    company: "A Lenders Recovery Service",
    street: "Vinemoss ln",
    city: "Cypress",
    state: "Arizona",
    zipcode: "1589888",
   
    Status: "Active",
  },
  {
    id: 6,
    araId: "WAYvmqNqk",
    company: "A & A Towing and Recovery MO",
    street: "PO Box 3500",
    city: "Cypress",
    state: "Arizona",
    zipcode: "1589888",
   
    Status: "Active",
  },
];

const Inspections = () => {
  const [data, setData] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);


  const filteredData = data.filter((item) =>
    item.company.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedData = filteredData.sort((a, b) => {
    if (sortColumn === null) return 0;
    if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedData.slice(indexOfFirstEntry, indexOfLastEntry);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSortColumn = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleAddInspection = (newInspection) => {
    setData([...data, newInspection]);
    setModalIsOpen(false);
  };

 

  return (
    <div className="inspections-container">
      <div className="header">{/* Header content if any */}</div>
      <div className="controls">
      <div className="show-entries col-lg-2">
          <label>Show </label>
          <select
            value={entriesPerPage}
            
            onChange={(e) => setEntriesPerPage(Number(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
          <label> entries</label>
        </div>
        <span className="searchbox">
          {" "}
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </span>
        <button
          className="add-inspection-button"
          onClick={() => setModalIsOpen(true)}
        >
          + Add Site
        </button>
      </div>
     <div className="bodyCo">
     <div class="table-responsive mt-5">
        <div className="table p-4">
          <table className="inspections-table">
            <thead className="thead">
              <tr>
                <th style={{width: '5%'}} onClick={() => handleSortColumn("id")}>
                  ID{" "}
                  {sortColumn === "id" && (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th style={{width: '10%'}} onClick={() => handleSortColumn("araId")}>
                  ARA ID{" "}
                  {sortColumn === "araId" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th style={{width: '15%'}} onClick={() => handleSortColumn("company")}>
                  Name{" "}
                  {sortColumn === "company" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th style={{width: '17%'}} onClick={() => handleSortColumn("street")}>
                  Street{" "}
                  {sortColumn === "street" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th style={{width: '17%'}} onClick={() => handleSortColumn("street")}>
                  City{" "}
                  {sortColumn === "street" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th style={{width: '17%'}} onClick={() => handleSortColumn("street")}>
                  State{" "}
                  {sortColumn === "street" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th style={{width: '12%'}} onClick={() => handleSortColumn("zipcode")}>
                  Zipcode{" "}
                  {sortColumn === "zipcode" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
              
                <th style={{width: '10%'}}>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.araId}</td>
                  <td>{item.company}</td>
                  <td>{item.street}</td>
                  <td>{item.city}</td>
                  <td>{item.state}</td>
                  <td>{item.zipcode}</td>
              
                
                  <td>
                  <button className="view-more-button" >
                         <img src={frame} alt="Frame" />
                      <img src={img2} alt="Group" />
                      <img src={img3} alt="Vector" />
                      <span className="view">View Site</span>
                     
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {[...Array(Math.ceil(filteredData.length / entriesPerPage)).keys()].map(
          (number) => (
            <button
              key={number + 1}
              onClick={() => handlePageChange(number + 1)}
              className={currentPage === number + 1 ? "active" : ""}
            >
              {number + 1}
            </button>
          )
        )}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredData.length / entriesPerPage)
          }
        >
          Next
        </button>
      </div>
     </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Add Inspection"
      >
        <AddInspectionModal onAddInspection={handleAddInspection} />
      </Modal>
    </div>
  );
};

export default Inspections;
