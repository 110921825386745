import React, { useState } from "react";
import Modal from "react-modal";

function AssignRequestModal({ isOpen, onRequestClose, onSubmit }) {
  const modalStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "530px",
      padding: "0px",
      backgroundColor: "#eaf2fb",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const headerContainerStyle = {
    backgroundColor: "#0050A4",
    padding: "10px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  };

  const headerTextStyle = {
    color: "white",
    fontSize: "18px",
  };

  const closeIconStyle = {
    color: "white",
    cursor: "pointer",
    fontSize: "20px",
  };

  const bodyStyle = {
    color: "#0050A4",
    padding: "20px",
  };

  const formGroupStyle = {
    marginBottom: "20px",
    color: "#0050A4",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    
    color: "#0050A4",
    fontSize: "16px",
    fontWeight: "600",
  };

  const inputRowStyle = {
    display: "flex",
    gap: "10px",
  };

  const inputStyle = {
    width: "100%",
    color: "#0050A4",
    flex: "1",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #0050A4",
  };

  const buttonStyle = {
    backgroundColor: "#0050A4",
    color: "white",
    border: "none",
    padding: "5px 20px",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "14px",
  };

  const addButtonStyle = {
    backgroundColor: "white",
    color: "#0050A4",
    fontWeight:'600',
    border:'1px solid #0050A4',
    padding: "10px 22px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
    marginBottom: "10px",
    marginRight: "10px",
  };

  const [dataForm, setDataForm] = useState({
    firstName: "",
    email: "",
    phone: "",
  });
  const [pictureForm, setPictureForm] = useState({
    firstName: "",
    email: "",
    phone: "",
  });

  const handleInputChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === "dataForm") {
      setDataForm({ ...dataForm, [name]: value });
    } else {
      setPictureForm({ ...pictureForm, [name]: value });
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyles}>
      <div>
        <div style={headerContainerStyle}>
          <span style={headerTextStyle}>Assign Request</span>
          <span style={closeIconStyle} onClick={onRequestClose}>
            &times;
          </span>
        </div>
        <div style={bodyStyle}>
          <div style={formGroupStyle}>
            <label style={labelStyle}>Assign Person For Data Form</label>
            <select style={{ ...inputStyle, marginBottom: "10px" }}>
              <option>Self Assign</option>
              <option>Assign to Others</option>
            </select>
            <div style={inputRowStyle} className="mt-4">
              <div style={{ flex: "1" }}>
                <label style={labelStyle}>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  // placeholder="First Name"
                  style={inputStyle}
                  value={dataForm.firstName}
                  onChange={(e) => handleInputChange(e, "dataForm")}
                />
              </div>
              <div style={{ flex: "1" }}>
                <label style={labelStyle}>Email</label>
                <input
                  type="email"
                  name="email"
                  // placeholder="Email"
                  style={inputStyle}
                  value={dataForm.email}
                  onChange={(e) => handleInputChange(e, "dataForm")}
                />
              </div>
              <div style={{ flex: "1" }}>
                <label style={labelStyle}>Phone</label>
                <input
                  type="text"
                  name="phone"
                  // placeholder="Phone"
                  style={inputStyle}
                  value={dataForm.phone}
                  onChange={(e) => handleInputChange(e, "dataForm")}
                />
              </div>
            </div>
          </div>

          <div style={formGroupStyle} >
            <label style={labelStyle} >Assign Person For Picture Form</label>
            <select style={{ ...inputStyle, marginBottom: "10px" }}>
              <option>Self Assign</option>
              <option>Assign to Others</option>
            </select>
            <div style={inputRowStyle} className="mt-4">
              <div style={{ flex: "1" }}>
                <label style={labelStyle}>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  // placeholder="First Name"
                  style={inputStyle}
                  value={pictureForm.firstName}
                  onChange={(e) => handleInputChange(e, "pictureForm")}
                />
              </div>
              <div style={{ flex: "1" }}>
                <label style={labelStyle}>Email</label>
                <input
                  type="email"
                  name="email"
                  // placeholder="Email"
                  style={inputStyle}
                  value={pictureForm.email}
                  onChange={(e) => handleInputChange(e, "pictureForm")}
                />
              </div>
              <div style={{ flex: "1" }}>
                <label style={labelStyle}>Phone</label>
                <input
                  type="text"
                  name="phone"
                  // placeholder="Phone"
                  style={inputStyle}
                  value={pictureForm.phone}
                  onChange={(e) => handleInputChange(e, "pictureForm")}
                />
              </div>
            </div>
          </div>
          <div style={{display:'flex', justifyContent:'space-between'}}  >
            <button style={addButtonStyle}>Add Contact</button>

            <button
              style={buttonStyle}
              onClick={() => onSubmit(dataForm, pictureForm)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AssignRequestModal;





