// App.jsx
import React, { useState } from "react";
import Profile from "./Profile.js";
import Designation from "./Designation.js";
import Permission from "./Permission.js";
import User from "./User.js";
import "./style.css";

function App() {
  const [activeTab, setActiveTab] = useState("profile");

  const renderContent = () => {
    switch (activeTab) {
      case "profile":
        return <Profile />;
      case "designation":
        return <Designation />;
      case "permission":
        return <Permission />;
      case "user":
        return <User />;
      default:
        return <Profile />;
    }
  };

  return (
    <div className="container">
      <div className="tabs">
        <button
          onClick={() => setActiveTab("profile")}
          className={activeTab === "profile" ? "active" : ""}
        >
          Profile
        </button>
        <button
          onClick={() => setActiveTab("designation")}
          className={activeTab === "designation" ? "active" : ""}
        >
          Designation
        </button>
        <button
          onClick={() => setActiveTab("permission")}
          className={activeTab === "permission" ? "active" : ""}
        >
          Permission
        </button>
        <button
          onClick={() => setActiveTab("user")}
          className={activeTab === "user" ? "active" : ""}
        >
          User
        </button>
      </div>
      {renderContent()}
    </div>
  );
}

export default App;
